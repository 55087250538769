"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserType = void 0;
/**
 * An enumeration.
 */
var UserType;
(function (UserType) {
    UserType["UT_PROCUREMENT_OWNER"] = "ut_procurement_owner";
    UserType["UT_BUSINESS_OWNER"] = "ut_business_owner";
})(UserType = exports.UserType || (exports.UserType = {}));
