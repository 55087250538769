import DimensionBox from '../DimensionBox';
import React, {useContext} from 'react';
import {AppContext} from '../../App';
import {Checkbox, FormControlLabel, Grid} from '@mui/material';
import PropTypes from 'prop-types';
import SelectField from '../field/SelectField';


const CommonSelector = ({disabled, chart, limit, tail, ignoreNull, cumulative}) => {
    const {config} = useContext(AppContext);

    function getChartSelector(param) {
        const value = param?.value;
        return (
            <SelectField
                datacy="chart_selector"
                label={config.i18n.selector.chart}
                value={value || ''}
                disabled={disabled || !param}
                possibleValues={param?.options || []}
                metadata={config.i18n.chart.type}
                onChange={param?.onUpdate}
            />
        );
    }

    function getLimitSelector(param) {
        const value = param?.value;
        return (
            <SelectField
                datacy="top_selector"
                label={config.i18n.selector.top}
                value={value || ''}
                disabled={disabled || !param}
                possibleValues={param?.options || []}
                onChange={param?.onUpdate}
            />
        );
    }

    function getHiddenTailSelector(param) {
        const value = param?.value || false;
        return (
            <FormControlLabel
                control={
                    <Checkbox
                        datacy="hideTail_selector"
                        checked={value}
                        disabled={disabled || !param}
                        onChange={(event) => param.onUpdate(event.target.checked)}
                    />
                }
                label={config.i18n.selector.hide_tail}
            />
        );
    }

    function getIgnoreNullSelector(param) {
        const value = param?.value || false;
        return (
            <FormControlLabel
                control={
                    <Checkbox
                        datacy="ignoreNull_selector"
                        checked={value}
                        disabled={disabled || !param}
                        onChange={(event) => param.onUpdate(event.target.checked)}
                    />
                }
                label={config.i18n.selector.null}
            />
        );
    }

    function getCumulativeSelector(param) {
        const value = param?.value || false;
        return (
            <FormControlLabel
                control={
                    <Checkbox
                        datacy="cumulative_selector"
                        checked={value}
                        disabled={disabled || !param}
                        onChange={(event) => param.onUpdate(event.target.checked)}
                    />
                }
                label={config.i18n.selector.cumulative}
            />
        );
    }

    return (
        <DimensionBox
            title={config.i18n.customization_bar.selectors}
            internalSx={{px: 1, py: 0.5}}
        >
            <Grid container spacing={1} rowSpacing={1.5}>
                <Grid item xs={6}>
                    {getChartSelector(chart)}
                </Grid>
                <Grid item xs={6}>
                    {getLimitSelector(limit)}
                </Grid>
                <Grid item xs={4}>
                    {getHiddenTailSelector(tail)}
                </Grid>
                <Grid item xs={4}>
                    {getIgnoreNullSelector(ignoreNull)}
                </Grid>
                <Grid item xs={4}>
                    {getCumulativeSelector(cumulative)}
                </Grid>
            </Grid>
        </DimensionBox>
    );
};

CommonSelector.propTypes = {
    disabled: PropTypes.bool,
    chart: PropTypes.object,
    limit: PropTypes.object,
    tail: PropTypes.object,
    ignoreNull: PropTypes.object,
    cumulative: PropTypes.object,
};

export default CommonSelector;
