import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useContext} from 'react';

import {AppContext} from '../App';
import {Dimension} from '../client';
import {parseCustomizationParameters} from '../util/parameters';
import HierarchyAutoCompleteBox from './picker/HierarchyAutoCompleteBox';
import DimensionBox from './DimensionBox';
import DimensionAutoCompleteBox from './picker/DimensionAutoCompleteBox';


const DimensionFilters = ({filters, dimensionParameters, customizationParameters, metadata, disabled, onUpdate}) => {
    const {client, config} = useContext(AppContext);

    const apiMap = {
        'CLIENT': client.client.clientSearch,
        'VENDOR': client.vendor.vendorSearch,
        'VENDOR_TYPE': client.vendorType.vendorTypeSearch,
        'VENDOR_SEGMENT': client.vendorSegment.vendorSegmentSearch,
        'BUSINESS_UNIT': client.businessUnit.businessUnitSearch,
        'OWNER': client.owner.ownerSearch,
        'DEPARTMENT': client.department.departmentSearch,
        'PROJECT': client.project.projectSearch,
        'SITE': client.site.siteSearch,
        'COMPANY': client.company.companySearch,
        'COUNTRY': client.country.countrySearch,
        'CATEGORY_L1': client.category.categoryLevel1Search,
        'CATEGORY_L2': client.category.categoryLevel2Search,
        'CATEGORY_L3': client.category.categoryLevel3Search,
        'CATEGORY_L4': client.category.categoryLevel4Search,
        'SPEND_GROUP': client.spendGroup.spendGroupSearch,
        'INTRA_GROUP': client.intraGroup.intraGroupSearch,
        'MATERIAL_GROUP': client.materialGroup.materialGroupSearch,
        'MATERIAL': client.material.materialSearch,
        'DATA_SOURCE': client.dataSource.dataSourceSearch,
    };

    const dispatchDimensionSearch = (dimension, queryString, page = 0, dimsToIgnore = []) => {
        const dims = {...dimensionParameters};
        dimsToIgnore.forEach((toIgnore) => {
            delete dims[toIgnore];
        });

        return apiMap[dimension](queryString, page, void 0, {
            parameters: filters, dimension_parameters: dims,
        });
    };

    const filterMap = {
        'DIMENSION': (param) => {
            const elementsDimOrder = [Dimension.SPEND_GROUP, Dimension.CLIENT, Dimension.CLIENT_COUNTRY, Dimension.VENDOR, Dimension.VENDOR_TYPE,
                Dimension.VENDOR_SEGMENT, Dimension.PROJECT, Dimension.OWNER, Dimension.INTRA_GROUP, Dimension.SITE, Dimension.COMPANY, Dimension.MATERIAL,
                Dimension.MATERIAL_GROUP, Dimension.VENDOR_COUNTRY];

            return elementsDimOrder.map((dimension, index) => {
                if (param && param[dimension]) {
                    const values = dimensionParameters[dimension] || [];
                    return (
                        <DimensionAutoCompleteBox
                            key={dimension + '-box'}
                            disabled={disabled}
                            dimension={dimension}
                            onChange={(dimValues) => {
                                const newDimensionParameters = {...dimensionParameters};
                                let newMetadata = {...metadata};

                                // always clear the old values.
                                delete newDimensionParameters[dimension];
                                if (dimValues?.length) {
                                    newDimensionParameters[dimension] = dimValues.map((dim) => dim.id);

                                    newMetadata = dimValues.reduce((acc, value) => {
                                        return {...acc, [value.id]: value};
                                    }, newMetadata);
                                }

                                onUpdate(newDimensionParameters, newMetadata);
                            }}
                            onQuery={(queryString, page = 0) => dispatchDimensionSearch(dimension, queryString, page)}
                            metadata={metadata}
                            values={values}
                            sx={{m: 0.5, width: 350}}
                        />
                    );
                } else {
                    return null;
                }
            });
        },

        'HIERARCHY': (param) => {
            const elementsDimOrder = [Dimension.ORG_UNIT, Dimension.CATEGORY];

            return elementsDimOrder.map((dimension) => {
                if (param && param[dimension]) {
                    const values = config.hierarchy[dimension].map((dim) => dimensionParameters[dim] || []);

                    return (
                        <DimensionBox
                            title={config.i18n.dimension[dimension]}
                            key={dimension + '-box'}
                            sx={{mr: 1, mb: 1}}
                            internalSx={{p: 0.5}}
                        >
                            <HierarchyAutoCompleteBox
                                disabled={disabled}
                                dimensions={config.hierarchy[dimension]}
                                values={values}
                                metadata={metadata}
                                onChange={(dims, vals, metadata) => {
                                    const newDimensionParameters = {...dimensionParameters};

                                    // need to set all dimension of the hierarchy.
                                    dims.forEach((dim, idx) => {
                                        // always clear the old values.
                                        delete newDimensionParameters[dim];
                                        if (vals[idx] && vals[idx].length) {
                                            newDimensionParameters[dim] = vals[idx];
                                        }
                                    });

                                    onUpdate(newDimensionParameters, metadata);
                                }}
                                onQuery={(dim, queryString, dimsToIgnore, page = 0) =>
                                    dispatchDimensionSearch(dim, queryString, page, dimsToIgnore)}
                                sx={{m: 0.5, width: 350}}
                            />
                        </DimensionBox>
                    );
                } else {
                    return null;
                }
            });
        },
    };

    const elementsByType = parseCustomizationParameters(customizationParameters);

    if (!elementsByType.DIMENSION && !elementsByType.HIERARCHY) {
        return null;
    }

    //
    // TODO this component must be split into two (one for dimensions and another for hierarchy) for responsiveness
    //

    return (
        <Box sx={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center'}}>
            {
                elementsByType.DIMENSION ? (
                    <DimensionBox title={config.i18n.customization_bar.parties} sx={{mr: 1, mb: 1}} internalSx={{p: 0.5}}>
                        {
                            filterMap['DIMENSION'](elementsByType['DIMENSION']) // eslint-disable-line
                        }
                    </DimensionBox>
                ) : null
            }
            {
                filterMap['HIERARCHY'](elementsByType['HIERARCHY']) // eslint-disable-line
            }
        </Box>
    );
};

DimensionFilters.propTypes = {
    filters: PropTypes.object,
    dimensionParameters: PropTypes.object,
    customizationParameters: PropTypes.array,
    metadata: PropTypes.object,
    disabled: PropTypes.bool,
    onUpdate: PropTypes.func,
};

DimensionFilters.defaultProps = {
    filters: {},
    dimensionParameters: {},
    customizationParameters: [],
    metadata: {},
    disabled: false,
};

export default DimensionFilters;
