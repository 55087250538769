import {getAxisH, getAxisTooltip, getBase, getLegend, getYSeries} from './common';

export const getChartOptions = (title, data, config, baseFontSize) => {
    return {
        ...getBase(title, baseFontSize),
        ...getAxisH(data, config, baseFontSize),
        ...getAxisTooltip(data, config, baseFontSize),
        ...getLegend(),
        ...getYSeries(data, 'bar', baseFontSize, config, {
            isBar: true,
        }),
    };
};
