"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Dimension = void 0;
/**
 * An enumeration.
 */
var Dimension;
(function (Dimension) {
    Dimension["DATE"] = "DATE";
    Dimension["DATE_YEAR"] = "DATE_YEAR";
    Dimension["DATE_YEAR_QUARTER"] = "DATE_YEAR_QUARTER";
    Dimension["DATE_YEAR_MONTH"] = "DATE_YEAR_MONTH";
    Dimension["FISCAL_YEAR"] = "FISCAL_YEAR";
    Dimension["ORG_UNIT"] = "ORG_UNIT";
    Dimension["COMPANY"] = "COMPANY";
    Dimension["BUSINESS_UNIT"] = "BUSINESS_UNIT";
    Dimension["DEPARTMENT"] = "DEPARTMENT";
    Dimension["PROJECT"] = "PROJECT";
    Dimension["SITE"] = "SITE";
    Dimension["COUNTRY"] = "COUNTRY";
    Dimension["PRICE"] = "PRICE";
    Dimension["QUANTITY"] = "QUANTITY";
    Dimension["AMOUNT"] = "AMOUNT";
    Dimension["UNIT"] = "UNIT";
    Dimension["CATEGORY"] = "CATEGORY";
    Dimension["CATEGORY_L1"] = "CATEGORY_L1";
    Dimension["CATEGORY_L2"] = "CATEGORY_L2";
    Dimension["CATEGORY_L3"] = "CATEGORY_L3";
    Dimension["CATEGORY_L4"] = "CATEGORY_L4";
    Dimension["CATEGORY_L5"] = "CATEGORY_L5";
    Dimension["SPEND_GROUP"] = "SPEND_GROUP";
    Dimension["DOCUMENT"] = "DOCUMENT";
    Dimension["SALES_BUDGET"] = "SALES_BUDGET";
    Dimension["PURCHASE_BUDGET"] = "PURCHASE_BUDGET";
    Dimension["REQUISITION"] = "REQUISITION";
    Dimension["PURCHASE_ORDER"] = "PURCHASE_ORDER";
    Dimension["INVOICE"] = "INVOICE";
    Dimension["SALES_INVOICE"] = "SALES_INVOICE";
    Dimension["SETTLEMENT"] = "SETTLEMENT";
    Dimension["RECEIPT"] = "RECEIPT";
    Dimension["MATERIAL"] = "MATERIAL";
    Dimension["MATERIAL_GROUP"] = "MATERIAL_GROUP";
    Dimension["CLIENT"] = "CLIENT";
    Dimension["CLIENT_COUNTRY"] = "CLIENT_COUNTRY";
    Dimension["VENDOR"] = "VENDOR";
    Dimension["VENDOR_TYPE"] = "VENDOR_TYPE";
    Dimension["VENDOR_SEGMENT"] = "VENDOR_SEGMENT";
    Dimension["VENDOR_CONTRACT"] = "VENDOR_CONTRACT";
    Dimension["VENDOR_CONTRACT_ITEM"] = "VENDOR_CONTRACT_ITEM";
    Dimension["VENDOR_COUNTRY"] = "VENDOR_COUNTRY";
    Dimension["OWNER"] = "OWNER";
    Dimension["COST_CENTER"] = "COST_CENTER";
    Dimension["DELIVERY_LOCATION"] = "DELIVERY_LOCATION";
    Dimension["DATA_SOURCE"] = "DATA_SOURCE";
    Dimension["ACCOUNTING_TREATMENT"] = "ACCOUNTING_TREATMENT";
    Dimension["DISCRETIONARY_COST"] = "DISCRETIONARY_COST";
    Dimension["FIXED_COST"] = "FIXED_COST";
    Dimension["DIRECT_COST"] = "DIRECT_COST";
    Dimension["DEMAND_FRAGMENTATION"] = "DEMAND_FRAGMENTATION";
    Dimension["SWITCHING_COST"] = "SWITCHING_COST";
    Dimension["SUPPLY_CONTROL"] = "SUPPLY_CONTROL";
    Dimension["INTRA_GROUP"] = "INTRA_GROUP";
    Dimension["PURCHASE_ORDER_TYPE"] = "PURCHASE_ORDER_TYPE";
    Dimension["INVOICE_TYPE"] = "INVOICE_TYPE";
    Dimension["FOREX"] = "FOREX";
    Dimension["FOREX_CONTRACT"] = "FOREX_CONTRACT";
})(Dimension = exports.Dimension || (exports.Dimension = {}));
