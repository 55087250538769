import React, {useContext, useEffect, useState} from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {AppContext} from '../../App';
import AutoCompleteBox from './AutoCompleteBox';
import PropTypes from 'prop-types';


const UserPicker = ({initialUserId, label, type, onUpdate, sx}) => {
    const {client, notify} = useContext(AppContext);
    const [initialUser, setInitialUser] = useState();

    useEffect(() => {
        if (initialUserId) {
            client.user.userGet(initialUserId)
                .then((user) => {
                    setInitialUser(user);
                })
                .catch((error) => {
                    setInitialUser(null);
                    notify.error(error, 'user.not.found', `User not found  ${initialUserId}`);
                });
        }
    }, [initialUserId]);

    return (
        <AutoCompleteBox
            key={initialUser?.ext_id || initialUserId}
            updateOnSelect
            label={label}
            size='small'
            onChange={onUpdate}
            onQuery={(queryString, page = 0) => {
                return client.user.userListByType(type, page);
            }}
            value={initialUser}
            startAdornment={
                <AccountCircleIcon key={`${type}-icon`} sx={{mr: 1}} />
            }
            sx={sx}
        />
    );
};

UserPicker.propTypes = {
    initialUserId: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    onUpdate: PropTypes.func,
    sx: PropTypes.object,
};

export default UserPicker;
