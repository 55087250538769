import {TextField} from '@mui/material';
import PropTypes from 'prop-types';
import {useState} from 'react';
import {useUpdateEffect} from 'react-use';

const ReadOnlyTextField = ({
    value,
    disabled,
    size,
    fullWidth,
    textAlign,
    label,
    disableOutline,
    disableAnimation,
    onClick,
    InputProps,
    sx,
}) => {
    const [animationSx, setAnimationSx] = useState({});

    useUpdateEffect(() => {
        if (value && !disableAnimation) {
            setAnimationSx({
                'animation': `blinkEffect 1.5s 1 ease`,
            });
        }
    }, [value, disableAnimation]);

    return (
        <TextField
            variant={disableOutline ? 'standard' : 'outlined'}
            key={value}
            disabled={disabled}
            fullWidth={fullWidth}
            value={value}
            size={size}
            label={label}
            onClick={onClick}
            InputProps={{
                ...InputProps,
                ...(disableOutline && {disableUnderline: true}),
                inputProps: {
                    style: {textAlign: textAlign},
                },
            }}
            sx={{
                ...sx,
                ...animationSx,
                '@keyframes blinkEffect': {
                    '0%': {
                        backgroundColor: 'white',
                    },
                    '25%': {
                        backgroundColor: 'hsl(64, 100%, 90%)',
                    },
                    '85%': {
                        backgroundColor: 'hsl(64, 100%, 90%)',
                    },
                    '100%': {
                        backgroundColor: 'white',
                    },
                },
            }}
        />
    );
};

ReadOnlyTextField.propTypes = {
    value: PropTypes.any,
    disabled: PropTypes.bool,
    size: PropTypes.string,
    fullWidth: PropTypes.bool,
    textAlign: PropTypes.string,
    label: PropTypes.string,
    disableOutline: PropTypes.bool,
    disableAnimation: PropTypes.bool,
    onClick: PropTypes.func,
    InputProps: PropTypes.object,
    sx: PropTypes.object,
};

ReadOnlyTextField.defaultProps = {
    size: 'small',
    fullWidth: true,
    textAlign: 'center',
    sx: {},
};
export default ReadOnlyTextField;
