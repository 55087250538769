"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProcurementInitiativeSimplicity = void 0;
/**
 * An enumeration.
 */
var ProcurementInitiativeSimplicity;
(function (ProcurementInitiativeSimplicity) {
    ProcurementInitiativeSimplicity["LOW"] = "LOW";
    ProcurementInitiativeSimplicity["MEDIUM"] = "MEDIUM";
    ProcurementInitiativeSimplicity["HIGH"] = "HIGH";
})(ProcurementInitiativeSimplicity = exports.ProcurementInitiativeSimplicity || (exports.ProcurementInitiativeSimplicity = {}));
