"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DimensionService = void 0;
var OpenAPI_1 = require("../core/OpenAPI");
var request_1 = require("../core/request");
var DimensionService = /** @class */ (function () {
    function DimensionService() {
    }
    /**
     * Metadata
     * @param requestBody
     * @returns DimensionModel The given IDs metadata.
     * @throws ApiError
     */
    DimensionService.dimensionMetadata = function (requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/dimension/metadata',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: "Validation Error",
            },
        });
    };
    return DimensionService;
}());
exports.DimensionService = DimensionService;
