import Handlebars from 'handlebars/dist/handlebars';
import {getQuarter, hasValue} from './util';
import {isEmpty} from 'lodash';

export const formatInteger = (value, locale) => new Intl.NumberFormat(locale.language, {}).format(parseInt(value));

export const formatDecimal = (value, locale, numberOfDecimals = getNumberOfDecimalsToShow(value)) =>
    new Intl.NumberFormat(locale.language, {
        minimumFractionDigits: numberOfDecimals,
        maximumFractionDigits: numberOfDecimals,
    }).format(value);

export const formatRatioToPercentage = (value, locale, adornment = true, numberOfDecimals = 0) =>
    formatDecimal(value * 100, locale, numberOfDecimals) + (adornment ? '%' : '');

export const formatIrr = (value, locale, infinityI18n) => {
    // format to Infinity, if value above 1000%.
    if (value > 10) {
        return `${infinityI18n} %`;
    } else if (value < -10) {
        return `-${infinityI18n} %`;
    } else {
        return formatRatioToPercentage(value, locale);
    }
};

export const formatBreakEven = (value, locale, i18n) =>
    hasValue(value) ?
        `${formatInteger(value, locale)} ${i18n.procurement.identify.priority.break_even_unit}` :
        `${i18n.never}`;


export const formatCompact = (value, locale, numberOfDecimals = getNumberOfDecimalsToShow(value)) =>
    new Intl.NumberFormat(locale.language, {
        minimumFractionDigits: numberOfDecimals,
        maximumFractionDigits: numberOfDecimals,
        notation: 'compact',
        compactDisplay: 'short',
    }).format(value);

export const formatCompactInteger = (value, locale, numberOfDecimals = getNumberOfDecimalsToShow(value)) =>
    new Intl.NumberFormat(locale.language, {
        minimumFractionDigits: 0,
        maximumFractionDigits: numberOfDecimals,
        notation: 'compact',
        compactDisplay: 'short',
    }).format(parseInt(value));

export const formatDate = (value, locale) =>
    new Intl.DateTimeFormat(locale.language, {
        dateStyle: 'long',
        timeZone: locale.timezone,
    }).format(new Date(value));

export const formatYear = (value, locale) =>
    new Intl.DateTimeFormat(locale.language, {
        year: 'numeric',
        timeZone: locale.timezone,
    }).format(new Date(value));

export const formatYearMonth = (value, locale) =>
    new Intl.DateTimeFormat(locale.language, {
        year: 'numeric',
        month: 'short',
        timeZone: locale.timezone,
    }).format(new Date(value));

export const formatQuarter = (value) => {
    const year = new Date(value).getFullYear().toString();
    return `${getQuarter(value)}Q ${year}`;
};

export const formatFiscalYear = (value, metadata) => {
    return metadata[value].short_name;
};

export const formatDateTime = (value, locale) =>
    new Intl.DateTimeFormat(locale.language, {
        dateStyle: 'long',
        timeStyle: 'long',
        timeZone: locale.timezone,
    }).format(new Date(value));

export const formatAmount = (value, locale) =>
    new Intl.NumberFormat(locale.language, {
        style: 'currency',
        currency: locale.currency,
    }).format(value);

export const formatCompactAmount = (value, locale, numberOfDecimals = getNumberOfDecimalsToShow(value)) =>
    new Intl.NumberFormat(locale.language, {
        style: 'currency',
        currency: locale.currency,
        minimumFractionDigits: numberOfDecimals,
        maximumFractionDigits: numberOfDecimals,
        notation: 'compact',
        compactDisplay: 'short',
    }).format(value);

export const getCurrencySymbol = (locale) => {
    const format = new Intl.NumberFormat(locale.language, {
        style: 'currency',
        currency: locale.currency,
    }).formatToParts(0);
    return format.find((part) => part.type === 'currency')?.value;
};

export function formatDimension(value, i18n, metadata) {
    if (isEmpty(value)) {
        return i18n.chart.label['__null__'];
    }

    // TODO: Instead of chart.label we should have a general i18n section.
    return metadata[value]?.short_name || metadata[value]?.name || i18n.chart.label[value] || value;
}

const getHandlebars = (locale, i18n, metadata) => {
    const handlebars = Handlebars.create();

    // register formatting helpers
    handlebars.registerHelper('integer', (value) => formatInteger(value, locale));
    handlebars.registerHelper('decimal', (value) => formatDecimal(value, locale));
    handlebars.registerHelper('compact', (value) => formatCompact(value, locale));
    handlebars.registerHelper('compactinteger', (value) => formatCompactInteger(value, locale));
    handlebars.registerHelper('percentage', (value) => {
        if (Math.abs(value) < 0.01) {
            return formatRatioToPercentage(value, locale, true, 2);
        }
        return formatRatioToPercentage(value, locale);
    });
    handlebars.registerHelper('amount', (value) => formatAmount(value, locale));
    handlebars.registerHelper('compactamount', (value) => formatCompactAmount(value, locale));
    handlebars.registerHelper('irr', (value) => formatIrr(value, locale, i18n.infinity));
    handlebars.registerHelper('break_even', (value) => formatBreakEven(value, locale, i18n));
    handlebars.registerHelper('custom_parameter', (value) =>
        hasValue(value) && (i18n.custom_parameters[value] || value));
    handlebars.registerHelper('dim', (value) => formatDimension(value, i18n, metadata));
    handlebars.registerHelper('abs', (value) => Math.abs(value).toString());
    // Handle dates
    handlebars.registerHelper('year', (value) => formatYear(value, locale));
    handlebars.registerHelper('fiscal-year', (value) => formatFiscalYear(value, metadata));
    handlebars.registerHelper('year-quarter', (value) => formatQuarter(value));
    handlebars.registerHelper('year-month', (value) => formatYearMonth(value, locale));
    handlebars.registerHelper('date', (value) => formatDate(value, locale));
    handlebars.registerHelper('datetime', (value) => formatDateTime(value, locale));
    return handlebars;
};


export const formatTemplate = (template, variables, locale, i18n, metadata = {}) => {
    const handlebars = getHandlebars(locale, i18n, metadata);

    // first pass
    const fn1 = handlebars.compile(template);
    const text1 = fn1(variables);

    // second pass
    // the second pass is required because of dynamic dimensions
    const fn2 = handlebars.compile(text1);
    const text2 = fn2(variables);

    return text2;
};


export const formatValue = (value, formatter, locale, i18n) => {
    // HACK: Echarts requires a number, otherwise we can't model a null value.
    // We're using the smallest value for this.
    // On null value, we still want to show a data point, with i18n.chart.label.nan.
    if (value === Number.EPSILON) {
        return i18n.chart.label.nan;
    }

    const template = `{{ ${formatter} var }}`;
    return formatTemplate(template, {var: value}, locale, i18n);
};


export const getCompactFormatter = (values, locale, i18n, isPercentage = false, formatFunction = formatDecimal) => {
    if (isPercentage) {
        return (value) => formatTemplate('{{ percentage var }}', {var: value}, locale, i18n);
    }
    const localeFormatter =
        new Intl.NumberFormat(locale.language, {
            notation: 'compact',
            compactDisplay: 'short',
        });

    let max = 0;
    if (typeof values[0] === 'object') {
        // Stacked data
        // Creating sum for each date, using flat would not maintain this division by date
        max = Math.max.apply(null, values.map((list) => list.reduce((a, b) => a + b, 0)).map(Math.abs));
    } else {
        max = Math.max.apply(null, values.map(Math.abs));
    }

    // Find the right multiplier.
    const multiplier = [1e12, 1e9, 1e6, 1e3, 1]
        .find((data) => max >= data) || 1;

    // Use the right locale symbol for the multiplier.
    const symbol = localeFormatter.formatToParts(max)
        .find((el) => el.type === 'compact')?.value || '';

    const numberOfDecimals = getNumberOfDecimalsToShow(max);

    return (value, index) => {
        if (value !== 0 && index === 0) {
            return '{min|' + formatFunction(value / multiplier, locale, numberOfDecimals) + symbol + '}';
        } else {
            return formatFunction(value / multiplier, locale, numberOfDecimals) + symbol;
        }
    };
};

const getNumberOfDecimalsToShow = (value) => {
    // TODO handle +inf
    if (value >= 1e100) {
        return 0;
    }

    const multiplier = [1e15, 1e12, 1e9, 1e6, 1e3, 1]
        .find((data) => Math.abs(value) >= data) || 1;
    return 3 - Math.floor(Math.abs(value) / multiplier).toString().length;
};
