import {getAxisHV, getAxisLabel, getBase, getPointSeries, getPointTooltip, defaultValueLabelSetting} from './common';

export const getChartOptions = (title, data, config, baseFontSize) => {
    // get max diameter for diameter normalization
    const sizes = data.result.flatMap((data) => Math.abs(data.z));
    const maxSize = Math.max(...sizes);

    const topLargestAbsIds = data.result
        .filter((r) => r.z[0] !== null) // Filter null values in z-axis
        .sort((r1, r2) => Math.abs(r2.z[0]) - Math.abs(r1.z[0])) // Order the ids by largest absolute value
        .slice(0, 5) // Get first 5 values
        .map((r) => r.label); // Only save the id

    return {
        ...getBase(title, baseFontSize),
        ...getAxisHV(data, config, baseFontSize),
        ...getPointTooltip(data, config, baseFontSize),
        series: getPointSeries(data).map((series) =>
            ({
                type: 'scatter',
                data: series,
                symbolSize: function(value) {
                    const z = value[2];
                    const minimumSize = 5;
                    const size = Math.abs(z) / maxSize * baseFontSize * 1.5;
                    return size < minimumSize ? minimumSize : size;
                },
                label:
                defaultValueLabelSetting(
                    'top',
                    baseFontSize,
                    config,
                    (value) => {
                        const id = value.data.name;
                        // Label the ones with top z-axis absolute values
                        if (topLargestAbsIds.includes(id)) {
                            return getAxisLabel(id, data, config);
                        } else {
                            return '';
                        }
                    }),
                clip: false,
            })),
        grid: {
            ...getAxisHV(data, config, baseFontSize).grid,
            top: 1.7 * baseFontSize,
        },
    };
};
