import React, {useContext} from 'react';
import {Button, Typography} from '@mui/material';
import AdvisorContainer from './AdvisorContainer';
import {Link as RouterLink} from 'react-router-dom';
import {AppContext} from '../App';

const ErrorContainer = () => {
    const {config} = useContext(AppContext);

    return (
        <AdvisorContainer>
            <Typography gutterBottom variant='h3'>
                {config.i18n.error_page.title}
            </Typography>
            <Typography gutterBottom variant='h5'>
                {config.i18n.error_page.subtitle}
            </Typography>
            <Button
                variant='contained'
                color='primary'
                component={RouterLink}
                to='/'
            >
                <Typography variant='h6'>
                    {config.i18n.error_page.button}
                </Typography>
            </Button>
        </AdvisorContainer>

    );
};

export default ErrorContainer;
