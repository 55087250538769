import {getAxisTooltip, getAxisV, getYSeriesData} from './common';
import {defaultValueLabelSetting, getBase, getFormattedValue, getLegend} from '../common';
import {getChartOptions as getLineChartOptions} from '../line';

export function getChartOptions(title, data, config, baseFontSize) {
    // If x type is not date, then we return a line chart, instead of timeline.
    if (data.label_type.x !== 'DATE') {
        return getLineChartOptions(title, data, config, baseFontSize);
    }

    const series = getYSeriesData(data).map((series) => ({
        type: 'line',
        data: series,
        label:
            defaultValueLabelSetting(
                'top',
                baseFontSize,
                config,
                (value) => {
                    const yVal = value.data.value[1]; // value[0] is x-value (the timestamp), value[1] the y-value
                    return getFormattedValue(yVal, 'y', data, config);
                }),
    }));

    // Order is preserved: https://stackoverflow.com/questions/5525795/does-javascript-guarantee-object-property-order/38218582#38218582
    // Assumes that metadata at least begins with the series we are interested in and that the order matches the results
    series.forEach((s, i ) => {
        s['name'] = data.series_ids[i];
    });

    return {
        ...getBase(title, baseFontSize),
        ...getAxisV(data, config, baseFontSize),
        ...getAxisTooltip(data, config, baseFontSize),
        ...getLegend(data, config, baseFontSize),
        series: series,
    };
}
