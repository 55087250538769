import {createTheme, CssBaseline, ThemeProvider} from '@mui/material';
import {SecureRoute} from './util/SecureRoute';
import {OktaAuth, toRelativeUrl} from '@okta/okta-auth-js';
import {LoginCallback, Security, useOktaAuth} from '@okta/okta-react';
import {SnackbarProvider, useSnackbar} from 'notistack';
import React, {createContext, useEffect, useState} from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes, useNavigate} from 'react-router-dom';

import {
    AccountingTreatmentService,
    AnalysisService,
    AuthenticationService,
    BusinessUnitService,
    CategoryService,
    ClientService,
    ClientCountryService,
    ConfigurationService,
    CountryService,
    DataSourceService,
    DemandFragmentationService,
    DepartmentService,
    DimensionService,
    DirectCostService,
    DiscretionaryCostService,
    FixedCostService,
    IntraGroupService,
    OpenAPI,
    OwnerService,
    ProcurementService,
    ProjectService,
    QueryService,
    SiteService,
    CompanyService,
    SpendGroupService,
    SupplyControlService,
    SwitchingCostService,
    TryThisService,
    UserService,
    VendorCountryService,
    VendorSegmentService,
    VendorService,
    VendorTypeService,
    MaterialService,
    MaterialGroupService,
} from './client';
import Ask from './sections/Ask';
import Explore from './sections/Explore';
import Login from './sections/Login';

import './App.css';
import Layout from './layout/Layout';
import Procurement from './sections/procurement/Procurement';
import ProjectDetails from './sections/procurement/ProjectDetails';
import Schedule from './sections/procurement/wizard/Schedule';
import Prioritize from './sections/procurement/wizard/prioritize/Prioritize';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import * as dayjsLocalePtPT from 'dayjs/locale/pt';
import {Helmet} from 'react-helmet';
import ErrorContainer from './layout/Error';

export const AppContext = createContext({});

const AppRouter = () => {
    const navigate = useNavigate();
    const [accessToken, setAccessToken] = useState();
    const [user, setUser] = useState();
    const {oktaAuth, authState} = useOktaAuth();
    const [config, setConfig] = useState();
    const {enqueueSnackbar} = useSnackbar();

    const theme = createTheme({
        components: {
            MuiStepIcon: {
                styleOverrides: {
                    'root': {
                        // increase the icon size and make it white.
                        '&.Mui-active': {
                            'transform': 'scale(1.5)',
                        },
                    },
                },
            },
            MuiStepLabel: {
                styleOverrides: {
                    'label': {
                        // increase the font size and make it white.
                        '&.Mui-active': {
                            '.MuiTypography-root': {
                                fontSize: '18pt',
                                marginLeft: '6px',
                            },
                        },
                    },
                },
            },
        },
    });

    const client = {
        auth: AuthenticationService,
        analysis: AnalysisService,
        businessUnit: BusinessUnitService,
        config: ConfigurationService,
        department: DepartmentService,
        dimension: DimensionService,
        project: ProjectService,
        site: SiteService,
        company: CompanyService,
        country: CountryService,
        query: QueryService,
        category: CategoryService,
        spendGroup: SpendGroupService,
        client: ClientService,
        clientCountry: ClientCountryService,
        vendor: VendorService,
        vendorCountry: VendorCountryService,
        vendorType: VendorTypeService,
        vendorSegment: VendorSegmentService,
        procurement: ProcurementService,
        owner: OwnerService,
        user: UserService,
        accountingTreatment: AccountingTreatmentService,
        demandFragmentation: DemandFragmentationService,
        directCost: DirectCostService,
        discretionaryCost: DiscretionaryCostService,
        fixedCost: FixedCostService,
        supplyControl: SupplyControlService,
        switchingCost: SwitchingCostService,
        tryThis: TryThisService,
        intraGroup: IntraGroupService,
        material: MaterialService,
        materialGroup: MaterialGroupService,
        dataSource: DataSourceService,
    };

    // we need to keep these two useEffects, since authState changes every render, but the accessToken doesn't.
    // This avoids multiple profile requests.
    useEffect(() => {
        if (authState && authState.isAuthenticated && authState.accessToken) {
            setAccessToken(authState.accessToken.accessToken);
            OpenAPI.TOKEN = authState.accessToken.accessToken;
        } else {
            setUser(null);
            setAccessToken(null);
            OpenAPI.TOKEN = null;
        }
    }, [authState]);

    useEffect(() => {
        if (accessToken) {
            client.config.configurationGet()
                .then((response) => {
                    setConfig(response);
                })
                .catch((error) => {
                    setConfig(null);
                    console.error('Error getting configuration.', error.body?.detail);
                });

            client.auth.authenticationGetProfile()
                .then((response) => {
                    setUser(response);
                })
                .catch((error) => {
                    setUser(null);
                    console.error('Error getting user profile', error.body?.detail);
                    // FIXME #1840: if error not auth, then sign out.
                });
        }
    }, [accessToken]);


    // global state store
    const store = {
        user: user,
        accessToken: accessToken,
        login: async () => navigate('/login'),
        logout: async () => oktaAuth.signOut(),
        client: client,
        config: config,
        notify: {
            error: (error, i18nSnackbarKey, logMessage) => {
                enqueueSnackbar(config.i18n.error[i18nSnackbarKey] ||
                    config.i18n.error['unexpected.error'], {variant: 'error'});
                console.error(logMessage, error?.body?.detail);
            },
            warn: (i18nSnackbarKey, ...extra) => {
                if (config.i18n.warn[i18nSnackbarKey]) {
                    enqueueSnackbar(config.i18n.warn[i18nSnackbarKey] + ' ' + extra, {variant: 'error'});
                }
            },
        },
    };

    // pick locale according configuration
    // cannot load again the default 'en' locale, so we are defaulting to it
    const dayjsLocale = config?.locale.language === 'pt-PT' ? dayjsLocalePtPT : null;

    return (
        <AppContext.Provider value={store}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={dayjsLocale}>
                <ThemeProvider theme={theme}>
                    <Helmet>
                        <title>{config?.i18n.page.main}</title>
                    </Helmet>
                    <CssBaseline />
                    <Routes>
                        <Route element={<Login />} path="/login" />
                        <Route element={<LoginCallback />} path="/login/callback" />
                        <Route element={<SecureRoute><Layout><Ask /></Layout> </SecureRoute>} path="/ask" />
                        <Route element={<SecureRoute><Layout><Explore /></Layout></SecureRoute>} path="/explore" />
                        <Route element={<SecureRoute><Layout><Procurement /></Layout></SecureRoute>} path="/procurement" />
                        <Route element={<SecureRoute><Layout><ErrorContainer /></Layout></SecureRoute>} path="/error" />
                        <Route
                            element={<SecureRoute><Layout><ProjectDetails operation='create' /></Layout></SecureRoute>}
                            path="/procurement/project/create"
                        />
                        <Route
                            element={<SecureRoute><Layout><ProjectDetails operation='edit' /></Layout></SecureRoute>}
                            path="/procurement/project/:projectId/edit"
                        />
                        <Route
                            element={
                                <SecureRoute>
                                    <Layout>
                                        <Prioritize
                                            steps={['prioritize', 'schedule']}
                                            originPath='/procurement'
                                            basePath='/procurement/project/:projectId'
                                        />
                                    </Layout>
                                </SecureRoute>
                            }
                            path='/procurement/project/:projectId/prioritize'
                        />
                        <Route
                            element={
                                <SecureRoute>
                                    <Layout>
                                        <Schedule
                                            steps={['prioritize', 'schedule']}
                                            originPath='/procurement'
                                            basePath='/procurement/project/:projectId'
                                        />
                                    </Layout>
                                </SecureRoute>
                            }
                            path='/procurement/project/:projectId/schedule'
                        />
                        <Route path="/" element={<Navigate to='/ask' />} />
                    </Routes>
                </ThemeProvider>
            </LocalizationProvider>
        </AppContext.Provider>
    );
};

const AppSecurity = () => {
    const [oktaConfig, setOktaConfig] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        ConfigurationService.configurationGetAuth()
            .then((data) => setOktaConfig(data));
    }, []);

    if (!oktaConfig) {
        return null;
    }

    const customAuthHandler = () => {
        navigate('/login');
    };

    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        navigate(toRelativeUrl(originalUri || '', window.location.origin));
    };

    const oktaAuth = new OktaAuth({
        issuer: oktaConfig['issuer'],
        clientId: oktaConfig['client_id'],
        // offline_access requests the refresh token to refresh the session after the access token is expired.
        // by default the access token is valid for 1 hour.
        scopes: ['openid', 'profile', 'email', 'offline_access'],
        redirectUri: `${window.location.origin}/login/callback`,
    });

    return (
        <Security
            oktaAuth={oktaAuth}
            onAuthRequired={customAuthHandler}
            restoreOriginalUri={restoreOriginalUri}
        >
            <SnackbarProvider maxSnack={3} anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                <AppRouter />
            </SnackbarProvider>
        </Security>
    );
};

const App = () => {
    return (
        <Router>
            <AppSecurity />
        </Router>
    );
};

export default App;
