import {useCallback, useEffect, useState} from 'react';
import {decode, encodeURI} from 'js-base64';
import {isEqual} from 'lodash';
import {useLocation} from 'react-router-dom';


export const useHash = (initialValue) => {
    const location = useLocation();
    const [hash, setHash] = useState(window.location.hash || `#${initialValue}`);

    const hashChangeHandler = useCallback(() => {
        setHash(window.location.hash);
    }, [window.location.hash]);

    // listen for hash changes
    useEffect(() => {
        window.addEventListener('hashchange', hashChangeHandler);
        return () => {
            window.removeEventListener('hashchange', hashChangeHandler);
        };
    }, []);

    const updateHash = useCallback((newHash) => {
        if (newHash !== hash) {
            if (newHash) {
                // update URL hash
                window.location.hash = newHash;
            } else {
                // remove URL hash
                window.history.pushState('', document.title, window.location.pathname + window.location.search);
                setHash(null);
            }
        }
    }, [hash]);

    // removing hash is not being picked-up by 'hashchange', due to browser router.
    // so we force it to empty.
    useEffect(() => {
        if (location.hash === '') {
            setHash('');
        }
    }, [location]);

    // remove hash character
    const cleanedHash = hash && hash.startsWith('#') ? hash.substring(1) : null;

    return [cleanedHash, updateHash];
};


export const useBase64Hash = (initialValue) => {
    const [hash, setHash] = useHash(encodeURI(initialValue));

    const updateHash = useCallback((newHash) => {
        if (newHash) {
            // encode as URL-safe Base64 string
            setHash(encodeURI(newHash));
        } else {
            setHash(null);
        }
    }, []);

    // decode from Base64
    const decodedHash = hash ? decode(hash) : null;

    return [decodedHash, updateHash];
};


export const useUrlStore = (initialValue) => {
    const [hashedObject, setHashedObject] = useBase64Hash(JSON.stringify(initialValue));

    // Initialize in-memory object with the initial value of hashedObject.
    // It can be either the initial value or the hash.
    // Hash takes priority.
    const [object, setObject] = useState(JSON.parse(hashedObject));

    // TODO: Check if we can optimize only running a deep equals on reading or writing, instead of both.

    // Ensure the in-memory object gets update only when the new parsed hashed object is deeply different,
    // to avoid triggering useEffects based on reference changes only.
    // By only updating this object on deep changes, its reference is also only changed in that case.
    useEffect(() => {
        const parsedObject = JSON.parse(hashedObject);
        if (parsedObject) {
            if (!isEqual(object, parsedObject)) {
                setObject(parsedObject);
            }
        } else {
            setObject({});
        }
    }, [hashedObject]);

    const updateObject = useCallback((newObject) => {
        // By only updating this object on deep changes, its reference is also only changed in that case.
        // Avoiding triggering useEffects based on reference changes only.
        if (newObject && !isEqual(newObject, object)) {
            setHashedObject(JSON.stringify(newObject));
        }
    }, [object]);

    return [object, updateObject];
};
