import {Box, Button, TextField, Typography} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {v4 as uuidv4} from 'uuid';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, {useContext, useEffect, useState} from 'react';
import {Link as RouterLink, useNavigate, useParams} from 'react-router-dom';
import {isEmpty} from 'lodash';

import {AppContext} from '../../App';
import Scope from './Scope';
import CircularLoading from '../../components/loading/CircularLoading';
import DimensionBox from '../../components/DimensionBox';
import DatePickerTextField from '../../components/field/DatePickerTextField';
import {LoadingButton} from '@mui/lab';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import {convertToISODate, hasValue} from '../../util/util';
import AdvisorContainer from '../../layout/AdvisorContainer';


const ProjectDetails = ({operation}) => {
    const DEFAULT_SCOPE = [];

    const {client, config, notify} = useContext(AppContext);

    const [name, setName] = useState('');
    const [startDate, setStartDate] = useState(dayjs(config.time.reference.min));
    const [endDate, setEndDate] = useState(dayjs(config.time.reference.max));
    const [scope, setScope] = useState(DEFAULT_SCOPE);
    const [scopeMetadata, setScopeMetadata] = useState({});

    const [shouldValidate, setShouldValidate] = useState(false);

    const [waitingProjectAction, setWaitingProjectAction] = useState(false);

    const [openStart, setOpenStart] = useState(false);
    const [openEnd, setOpenEnd] = useState(false);

    const {projectId} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (projectId) {
            client.procurement.procurementGetProject(projectId)
                .then((project) => {
                    setName(project.name);
                    setStartDate(project.start_date && dayjs(project.start_date));
                    setEndDate(project.end_date && dayjs(project.end_date));
                    setScope(project.scope);
                    setScopeMetadata(project.metadata);
                })
                .catch((error) => {
                    setName('');
                    setStartDate(null);
                    setEndDate(null);
                    setScope(DEFAULT_SCOPE);
                    setScopeMetadata({});
                    notify.error(error, 'procurement.project.projects.fetch', `Error fetching project ${projectId}.`);
                });
        }
    }, [projectId]);

    const isValid = () => {
        if (name && startDate && endDate && scope.length > 0) {
            return scope.every((element, index) => {
                if (!element.name) {
                    notify.warn('procurement.project.scope.filter.name', index + 1);
                    return false;
                } else if (!isEmpty(element.filters.length)) {
                    notify.warn('procurement.project.scope.filter.where', element.name);
                    return false;
                } else {
                    return true;
                }
            });
        }

        if (!name) {
            notify.warn('procurement.project.name');
            return false;
        }

        if (!startDate || !endDate) {
            notify.warn('procurement.project.date');
            return false;
        }

        return true;
    };

    const createProject = () => {
        setShouldValidate(true);
        if (isValid()) {
            const finalScope = scope.map((filter) => ({
                id: filter.id,
                name: filter.name,
                filters: filter.filters,
            }));

            setWaitingProjectAction(true);
            client.procurement.procurementCreateProject(
                {
                    name: name,
                    start_date: convertToISODate(startDate),
                    end_date: convertToISODate(endDate),
                    scope: finalScope,
                })
                .then((project) => {
                    setWaitingProjectAction(false);
                    navigate(`/procurement/project/${project.id}/prioritize`);
                })
                .catch((error) => {
                    setWaitingProjectAction(false);
                    notify.error(error, 'procurement.project.projects.create', 'Error creating project.');
                });
        }
    };

    const editProject = () => {
        setShouldValidate(true);
        if (projectId && isValid()) {
            setWaitingProjectAction(true);
            client.procurement.procurementUpdateProject(projectId,
                {
                    name: name,
                })
                .then(() => {
                    setWaitingProjectAction(false);
                    navigate(`/procurement`);
                })
                .catch((error) => {
                    setWaitingProjectAction(false);
                    notify.error(error, 'procurement.project.projects.update', `Error updating project ${projectId}.`);
                });
        }
    };

    // should only render when creating project, or when the info is available for editing details.
    if (operation === 'edit' && !name) {
        return (
            <AdvisorContainer>
                <CircularLoading height='800px' label={config.i18n.procurement.project.loading} />
            </AdvisorContainer>
        );
    }

    const globalMin = dayjs(config.time.global.min);
    const globalMax = dayjs(config.time.global.max);

    return (
        <AdvisorContainer datacy='project_details'>
            <Typography variant='h4'>
                {operation === 'create' ?
                    config.i18n.procurement.project.create_label :
                    config.i18n.procurement.project.edit_label}
            </Typography>
            <Box
                component='form'
                sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', my: 3, width: 560}}
            >
                <TextField
                    required
                    fullWidth
                    error={shouldValidate ? !name : null}
                    helperText={shouldValidate && !name ? config.i18n.warn.field_empty : ''}
                    label={config.i18n.procurement.project.name_column}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    sx={{my: 1}}
                />
                <DimensionBox
                    title={config.i18n.procurement.project.date_label}
                    sx={{width: '100%'}}
                    internalSx={{p: 0.5, gap: 1, justifyContent: 'center'}}
                >
                    <DatePicker
                        required
                        open={openStart}
                        onOpen={() => setOpenStart(true)}
                        onClose={() => setOpenStart(false)}
                        size="small"
                        views={['year', 'month']}
                        openTo='month'
                        label={config.i18n.procurement.project.start_date_column}
                        value={startDate || null}
                        onChange={setStartDate}
                        disableFuture
                        minDate={globalMin}
                        maxDate={endDate || globalMax || null}
                        slotProps={{textField: {size: 'small'}}}
                        renderInput={(params) =>
                            (
                                <DatePickerTextField
                                    {...params}
                                    onClick={(e) => setOpenStart(true)} // Click anywhere to open datepicker
                                    helperText={shouldValidate && !startDate ? config.i18n.warn.field_empty : ''}
                                    error={shouldValidate ? !startDate : null}
                                    required
                                    fullWidth
                                    disableClear
                                    sx={{m: 0.5, flex: 1}}
                                />
                            )}
                        disabled={operation === 'edit'}
                    />

                    <DatePicker
                        open={openEnd}
                        onOpen={() => setOpenEnd(true)}
                        onClose={() => setOpenEnd(false)}
                        views={['year', 'month']}
                        openTo='month'
                        label={config.i18n.procurement.project.end_date_column}
                        value={endDate || null}
                        onChange={setEndDate}
                        disableFuture
                        minDate={startDate || globalMin || null}
                        maxDate={globalMax}
                        slotProps={{textField: {size: 'small'}}}
                        renderInput={(params) =>
                            (
                                <DatePickerTextField
                                    {...params}
                                    onClick={(e) => setOpenEnd(true)} // Click anywhere to open datepicker
                                    helperText={shouldValidate && !endDate ? config.i18n.warn.field_empty : ''}
                                    error={shouldValidate ? !endDate : null}
                                    required
                                    fullWidth
                                    disableClear
                                    sx={{m: 0.5, flex: 1}}
                                />
                            )}
                        disabled={operation === 'edit'}
                    />
                </DimensionBox>
            </Box>
            {
                scope.length > 0 ?
                    <Scope
                        scope={scope}
                        scopeMetadata={scopeMetadata}
                        disabled={operation === 'edit'}
                        shouldValidate={shouldValidate}
                        onUpdate={(scope, metadata = null) => {
                            setScope(scope);
                            if (hasValue(metadata)) {
                                setScopeMetadata(metadata);
                            }
                        }}
                    /> :
                    operation === 'create' ?
                        <Button
                            variant='contained'
                            color='grey'
                            title={config.i18n.procurement.scope.add_scope_button}
                            onClick={() => {
                                setScope([{id: uuidv4(), name: '', filters: {}}]);
                            }}
                        >
                            {config.i18n.procurement.scope.add_scope_button}
                        </Button> :
                        <Typography>{config.i18n.procurement.scope.no_scope}</Typography>
            }
            <Box sx={{display: 'flex', mt: 3}}>
                <Button
                    variant='contained'
                    color='grey'
                    sx={{mr: 1}}
                    title={config.i18n.button.cancel}
                    component={RouterLink}
                    to='/procurement'
                >
                    {config.i18n.button.cancel}
                </Button>
                <LoadingButton
                    variant='contained'
                    loading={waitingProjectAction}
                    loadingPosition='start'
                    startIcon={operation === 'create' ? <AddIcon /> : <SaveIcon />}
                    title={operation === 'create' ?
                        config.i18n.button.create :
                        config.i18n.button.save}
                    onClick={() => operation === 'create' ? createProject() : editProject()}
                    sx={{mr: 1}}
                >
                    {operation === 'create' ?
                        config.i18n.button.create :
                        config.i18n.button.save}
                </LoadingButton>
            </Box>
        </AdvisorContainer>
    );
};

ProjectDetails.propTypes = {
    operation: PropTypes.string,
};

export default ProjectDetails;
