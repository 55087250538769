import {Box, Button, FormControlLabel, Switch, Typography} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import PropTypes from 'prop-types';
import React, {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useUpdateEffect} from 'react-use';
import {AppContext} from '../../../App';
import ClickableDataGrid from '../../../components/clickableDataGrid';
import CircularLoading from '../../../components/loading/CircularLoading';
import {formatCompactAmount, formatInteger, formatRatioToPercentage, getCompactFormatter} from '../../../util/formatter';
import Wizard from '../../../components/Wizard';
import {validateProject} from './util';
import EditableTextField from '../../../components/field/EditableTextField';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FullscreenLoading from '../../../components/loading/FullscreenLoading';
import DatePickerTextField from '../../../components/field/DatePickerTextField';
import AdvisorContainer from '../../../layout/AdvisorContainer';
import ReadOnlyTextField from '../../../components/field/ReadOnlyTextField';
import {convertToISODate} from '../../../util/util';

dayjs.extend(isSameOrAfter);


const Schedule = ({steps, basePath, originPath}) => {
    const {client, config, notify} = useContext(AppContext);
    const {projectId} = useParams();

    const [projectName, setProjectName] = useState();
    const [projectStatus, setProjectStatus] = useState({});

    const [prioritizedInitiatives, setPrioritizedInitiatives] = useState();
    const [initiativeDates, setInitiativeDates] = useState({});

    const [hasChanges, setHasChanges] = useState(false);
    const [saving, setSaving] = useState(false);
    const [previewing, setPreviewing] = useState(false);

    const [monthlyRunRates, setMonthlyRunRates] = useState([]);
    const [npv, setNpv] = useState(0);

    const [implementationStartDate, setImplementationStartDate] = useState();

    const [open, setOpen] = useState(false);

    // owners
    const [userData, setUserData] = useState();

    const [showDetails, setShowDetails] = useState(false);

    const parseAndSetInitiativeDates = (initiatives, startDate) => {
        const newInitiativeDates =
            initiatives.reduce((previous, current) =>
                ({
                    ...previous,
                    [current.id]: current.start_month,
                }), {});

        setInitiativeDates(newInitiativeDates);
    };

    const sortPrioritizedInitiatives = (project) => {
        return project.initiatives
            .filter((initiative) => initiative.identified && initiative.prioritized)
            .sort((initiative1, initiative2) =>
                initiative1.schedule_index - initiative2.schedule_index);
    };

    const setProject = (project) => {
        setProjectName(project.name);
        setImplementationStartDate(dayjs(project.implementation_start_date));

        const prioritized = sortPrioritizedInitiatives(project);
        setPrioritizedInitiatives(prioritized);
        parseAndSetInitiativeDates(prioritized, project.implementation_start_date);

        const ownerIds = prioritized
            .flatMap((initiative) => [initiative.business_owner, initiative.procurement_owner])
            .filter((ids) => !!ids);

        client.user.userGetUsers(ownerIds)
            .then((users) => {
                setUserData(users);
            })
            .catch((error) => {
                setUserData({});
                notify.error(error, 'user.not.found', `Users not found.`);
            });

        setMonthlyRunRates(project.monthly_run_rates);
        setNpv(project.npv);
    };

    const setNullProject = () => {
        setProjectName(null);
        setImplementationStartDate(null);

        setPrioritizedInitiatives([]);
        parseAndSetInitiativeDates([], null);

        setMonthlyRunRates([]);
        setNpv(0);
    };

    const generatePayloadForSchedule = () => {
        return prioritizedInitiatives.map((initiative) => {
            return {
                id: initiative.id,
                start_month: initiativeDates[initiative.id],
            };
        });
    };

    const isValid = () => {
        const valid = [...prioritizedInitiatives].every((initiative) => {
            return initiative.id in initiativeDates && initiativeDates[initiative.id] >= 0;
        });

        if (!valid) {
            notify.warn('procurement.project.schedule.empty');
        }

        return valid;
    };

    const renderAmountCell = (params) => {
        if (params.value < 0) {
            return <span style={{color: 'red'}}>{params.formattedValue}</span>;
        } else {
            return params.formattedValue;
        }
    };

    useEffect(() => {
        if (projectId) {
            client.procurement.procurementGetProject(projectId, false, true)
                .then((project) => {
                    setProject(project);
                })
                .catch((error) => {
                    setNullProject();
                    notify.error(error, 'procurement.project.projects.fetch', `Error fetching project ${projectId}.`);
                });
        } else {
            setNullProject();
        }
    }, [projectId]);

    useUpdateEffect(() => {
        // prevent this effect from triggering when the project loads
        if (!hasChanges) {
            return;
        }

        if (Object.keys(initiativeDates).length && implementationStartDate && isValid()) {
            setPreviewing(true);

            const payload = generatePayloadForSchedule();
            client.procurement.procurementScheduleInitiatives(projectId, payload, convertToISODate(implementationStartDate), true)
                .then((project) => {
                    const prioritized = sortPrioritizedInitiatives(project);

                    setPrioritizedInitiatives(prioritized);
                    setMonthlyRunRates(project.monthly_run_rates);
                    setNpv(project.npv);
                    setPreviewing(false);
                })
                .catch((error) => {
                    setMonthlyRunRates([]);
                    setNpv(0);
                    setPreviewing(false);

                    notify.error(error, 'procurement.project.schedule.dry_run',
                        `Error dry running project ${projectId} initiatives ${prioritizedInitiatives}.`);
                });
        } else {
            setMonthlyRunRates([]);
            setNpv(0);
        }
    }, [implementationStartDate, initiativeDates, hasChanges]);

    if (!projectName || !prioritizedInitiatives || !userData) {
        return (
            <AdvisorContainer>
                <CircularLoading height='800px' label={config.i18n.procurement.schedule.loading} />
            </AdvisorContainer>
        );
    }

    const monthDate = dayjs(implementationStartDate);
    const monthsColumns = monthlyRunRates.map((_, index) => {
        return {
            field: `m${index}`,
            headerName: monthDate.add(index, 'month').format('MM/YYYY'),
            details: false,
            headerAlign: 'center',
            align: 'center',
            minWidth: 80,
            maxWidth: 80,
            sortable: false,
            renderCell: (params) => {
                // if current month is larger or eq to initiative start_month and lesser than initiative end_month, render it.
                if (index >= params.row.start_month &&
                    index < params.row.start_month + params.row.monthly_run_rates.length) {
                    // render the current month - initiative start_month to target the right month index.
                    const value = params.row.monthly_run_rates[index - params.row.start_month];

                    // we're calculating the max abs value of each initiative for each column.
                    // optimize this if it starts to be a bottleneck.
                    const formattedValue =
                        getCompactFormatter(params.row.monthly_run_rates, config.locale, formatCompactAmount)(value);

                    if (value < 0) {
                        return <span style={{color: 'red'}}>{formattedValue}</span>;
                    } else {
                        return formattedValue;
                    }
                } else {
                    return null;
                }
            },
        };
    });

    const columns = [
        {
            field: 'name',
            headerName: config.i18n.procurement.identify.name_column,
            details: false,
            minWidth: 512,
            sortable: false,
        },
        {
            field: 'business_owner',
            headerName: config.i18n.procurement.owner.business_owner,
            details: true,
            minWidth: 150,
            sortable: false,
            valueGetter: (params) => params.row.id !== 'total' ?
                userData[params.row.business_owner]?.name || params.row.business_owner ||
                config.i18n.procurement.owner.no_owner :
                '',
        },
        {
            field: 'business_owner_allocation',
            headerName: config.i18n.procurement.owner.business_owner_allocation,
            details: true,
            minWidth: 100,
            sortable: false,
            valueFormatter: (value) => value.id !== 'total' ? formatRatioToPercentage(value.value, config.locale) : '',
            valueGetter: (params) => params.row.id !== 'total' ? params.row.business_owner_allocation : '',
        },
        {
            field: 'procurement_owner',
            headerName: config.i18n.procurement.owner.procurement_owner,
            details: true,
            minWidth: 150,
            sortable: false,
            valueGetter: (params) => params.row.id !== 'total' ?
                userData[params.row.procurement_owner]?.name || params.row.procurement_owner ||
                config.i18n.procurement.owner.no_owner :
                '',
        },
        {
            field: 'procurement_owner_allocation',
            headerName: config.i18n.procurement.owner.procurement_owner_allocation,
            details: true,
            minWidth: 100,
            sortable: false,
            valueFormatter: (value) => value.id !== 'total' ? formatRatioToPercentage(value.value, config.locale) : '',
            valueGetter: (params) => params.row.id !== 'total' ? params.row.procurement_owner_allocation : '',
        },
        {
            field: 'cost_to_achieve',
            headerName: config.i18n.procurement.identify.value_proven.cost_to_achieve,
            details: true,
            minWidth: 126,
            sortable: false,
            headerAlign: 'right',
            align: 'right',
            valueFormatter: (value) => value.id !== 'total' ? formatCompactAmount(value.value, config.locale) : '',
            valueGetter: (params) => params.row.id !== 'total' ? params.row.consolidated.cost_to_achieve : '',
            renderCell: renderAmountCell,
        },
        {
            field: 'run_rate',
            headerName: config.i18n.procurement.schedule.run_rate,
            details: true,
            minWidth: 100,
            sortable: false,
            headerAlign: 'right',
            align: 'right',
            valueFormatter: (value) => value.id !== 'total' ? formatCompactAmount(value.value, config.locale) : '',
            valueGetter: (params) => params.row.id !== 'total' ? params.row.run_rate : '',
            renderCell: renderAmountCell,
        },
        {
            field: 'overlap',
            headerName: config.i18n.procurement.schedule.overlap,
            details: true,
            minWidth: 100,
            sortable: false,
            headerAlign: 'right',
            align: 'right',
            valueFormatter: (value) => value.id !== 'total' ? formatRatioToPercentage(value.value, config.locale) : '',
            valueGetter: (params) => params.row.id !== 'total' ? params.row.overlap : '',
        },
        {
            field: 'amended_run_rate',
            headerName: config.i18n.procurement.schedule.amended_run_rate,
            details: false,
            minWidth: 120,
            sortable: false,
            headerAlign: 'right',
            align: 'right',
            valueFormatter: (value) => value.id !== 'total' ? formatCompactAmount(value.value, config.locale) : '',
            valueGetter: (params) => params.row.id !== 'total' ? params.row.amended_run_rate : '',
            renderCell: renderAmountCell,
        },
        {
            field: 'amended_npv',
            headerName: config.i18n.procurement.schedule.amended_npv,
            details: true,
            minWidth: 100,
            sortable: false,
            headerAlign: 'right',
            align: 'right',
            valueFormatter: (value) => value.id !== 'total' ? formatCompactAmount(value.value, config.locale) : '',
            valueGetter: (params) => params.row.id !== 'total' ? params.row.amended_npv : '',
            renderCell: renderAmountCell,
        },
        {
            field: 'first_year_pnl',
            headerName: config.i18n.procurement.schedule.first_year_pnl,
            details: true,
            minWidth: 114,
            sortable: false,
            headerAlign: 'right',
            align: 'right',
            valueFormatter: (value) => value.id !== 'total' ? formatCompactAmount(value.value, config.locale) : '',
            valueGetter: (params) => params.row.id !== 'total' ? params.row.first_year_pnl : '',
            renderCell: renderAmountCell,
        },
        {
            field: 'start_month',
            headerName: config.i18n.procurement.schedule.start_month,
            details: false,
            sortable: false,
            minWidth: 120,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                return params.row.id !== 'total' ?
                    <EditableTextField
                        value={params.row.id in initiativeDates ? initiativeDates[params.row.id] + 1 : null}
                        formatter={(value) => formatInteger(value, config.locale)}
                        type='number'
                        min='1'
                        size='small'
                        disableOutline
                        disableAnimation
                        onChange={(value) => {
                            const newInitiativeDates = {...initiativeDates};
                            newInitiativeDates[params.row.id] = value - 1;

                            setInitiativeDates(newInitiativeDates);
                            setHasChanges(true);
                        }}
                        validator={(value) => value >= 1}
                        onInvalid={() => {
                            notify.warn('procurement.project.initiative.start_month.negative', params.row.name);
                        }}
                    /> :
                    null;
            },
        },
        ...monthsColumns,
    ];

    const columnsToRender = columns.filter((column) => !column.details || showDetails);

    return (
        <AdvisorContainer>
            <Wizard
                steps={steps}
                currentStep='schedule'
                stepValidation={projectStatus}
                title={<Typography variant="h4">{projectName} – Schedule</Typography>}
                hasChanges={hasChanges}
                basePath={basePath}
                originPath={originPath}
                disableStepper
                onSave={() => {
                    if (!isValid()) {
                        return;
                    }

                    setSaving(true);
                    const payload = generatePayloadForSchedule();
                    client.procurement.procurementScheduleInitiatives(projectId, payload, convertToISODate(implementationStartDate))
                        .then((project) => {
                            setProject(project);

                            setHasChanges(false);
                            setSaving(false);

                            validateProject(client, notify, projectId, setProjectStatus);
                        })
                        .catch((error) => {
                            setSaving(false);
                            notify.error(error, 'procurement.project.schedule',
                                `Error updating project ${projectId} initiatives ${prioritizedInitiatives}.`);
                        });
                }}

                onDiscard={() => setHasChanges(false)}
            >
                <FullscreenLoading
                    loading={saving}
                    label={config.i18n.procurement.schedule.saving}
                />
                <FullscreenLoading
                    loading={previewing}
                    label={config.i18n.procurement.schedule.previewing}
                />
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1}}>
                    <Box>
                        <DatePicker
                            required
                            open={open}
                            onOpen={() => setOpen(true)}
                            onClose={() => setOpen(false)}
                            views={['year', 'month']}
                            openTo='month'
                            label={config.i18n.procurement.schedule.implementation_start_date}
                            value={implementationStartDate || null}
                            onChange={(date) => {
                                setImplementationStartDate(date);
                                setHasChanges(true);
                            }}
                            slotProps={{textField: {size: 'small'}}}
                            renderInput={(params) =>
                                (
                                    <DatePickerTextField
                                        {...params}
                                        onClick={() => setOpen(true)} // Click anywhere to open datepicker
                                        helperText={!implementationStartDate ? config.i18n.warn.field_empty : ''}
                                        error={!implementationStartDate}
                                        required
                                        disableClear
                                    />
                                )
                            }
                        />
                        <Button
                            variant='contained'
                            startIcon={<CalendarMonthIcon />}
                            title={config.i18n.procurement.schedule.auto_schedule}
                            sx={{ml: 1}}
                        >
                            {config.i18n.procurement.schedule.auto_schedule}
                        </Button>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={showDetails}
                                    onChange={(event) => setShowDetails(event.target.checked)}
                                />
                            }
                            label={config.i18n.selector.details}
                            sx={{ml: 1}}
                        />
                    </Box>
                    <ReadOnlyTextField
                        fullWidth={false}
                        textAlign="left"
                        value={formatCompactAmount(npv, config.locale)}
                        label={config.i18n.procurement.schedule.project_npv}
                    />
                </Box>
                <ClickableDataGrid
                    rows={[
                        ...prioritizedInitiatives,
                        {
                            id: 'total',
                            monthly_run_rates: monthlyRunRates,
                            start_month: 0,
                        },
                    ]}
                    columns={columnsToRender}
                    disableColumnMenu
                    disableColumnFilter
                    disableColumnSelector
                    disableRowSelectionOnClick
                    isRowSelectable={(params) => params.row.id !== 'total'}
                    hideFooterPagination
                    rowHeight={38}
                />
            </Wizard>
        </AdvisorContainer>
    );
};

Schedule.propTypes = {
    steps: PropTypes.array,
    basePath: PropTypes.string,
    originPath: PropTypes.string,
};

export default Schedule;
