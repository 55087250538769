"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomizationType = void 0;
/**
 * An enumeration.
 */
var CustomizationType;
(function (CustomizationType) {
    CustomizationType["DOCUMENT"] = "DOCUMENT";
    CustomizationType["CHART"] = "CHART";
    CustomizationType["LIMIT"] = "LIMIT";
    CustomizationType["HIDE_TAIL"] = "HIDE_TAIL";
    CustomizationType["NULL"] = "NULL";
    CustomizationType["DIMENSION"] = "DIMENSION";
    CustomizationType["HIERARCHY"] = "HIERARCHY";
    CustomizationType["DATE"] = "DATE";
    CustomizationType["DATE_AGG"] = "DATE_AGG";
    CustomizationType["ANNUALIZE"] = "ANNUALIZE";
    CustomizationType["CUSTOM"] = "CUSTOM";
    CustomizationType["CUMULATIVE"] = "CUMULATIVE";
    CustomizationType["FOREX_DATE"] = "FOREX_DATE";
})(CustomizationType = exports.CustomizationType || (exports.CustomizationType = {}));
