import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';
import {Box} from '@mui/material';

import Header from './Header';
import Menu from './Menu';
import {AppContext} from '../App';
import CircularLoading from '../components/loading/CircularLoading';
import ErrorBoundary from '../util/ErrorBoundary';
import {useNavigate} from 'react-router-dom';

const Layout = ({children}) => {
    const {config, user} = useContext(AppContext);
    const [isDrawerPinned, setIsDrawerPinned] = useState(true);
    const navigate = useNavigate();

    if (!config || !user) {
        return <CircularLoading height='calc(96vh)' />;
    }

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', minWidth: '900px'}}>
            <Header
                isDrawerPinned={isDrawerPinned}
                onPinDrawerClick={() => setIsDrawerPinned(!isDrawerPinned)}
            />
            <Box sx={{display: 'flex'}}>
                <Menu isDrawerPinned={isDrawerPinned} />
                <ErrorBoundary navigate={navigate}>
                    {children}
                </ErrorBoundary>
            </Box>
        </Box>
    );
};

Layout.propTypes = {
    children: PropTypes.any,
};

export default Layout;
