import {
    defaultValueLabelSetting,
    getAxisTooltip,
    getAxisV,
    getBase,
    getFormattedValue,
    getYSeriesData,
    overrideNegativeLabelPosition,
} from './common';

export const getChartOptions = (title, data, config, baseFontSize) => {
    return {
        ...getBase(title, baseFontSize),
        ...getAxisV(data, config, baseFontSize),
        ...getAxisTooltip(data, config, baseFontSize),
        series: getYSeriesData(data).map((series) =>
            ({
                type: 'bar',
                data: overrideNegativeLabelPosition(series, 'bottom'),
                label: defaultValueLabelSetting('top', baseFontSize, config,
                    (value) => getFormattedValue(value.data.value, 'y', data, config)),
            })),
    };
};
