import {Container} from '@mui/material';
import PropTypes from 'prop-types';

const AdvisorContainer = ({children, datacy}) => {
    return (
        <Container
            datacy={datacy}
            maxWidth='xl'
            sx={{flex: 1, pt: 4, pb: 2}}
        >
            {children}
        </Container>
    );
};

AdvisorContainer.propTypes = {
    children: PropTypes.any,
    datacy: PropTypes.string,
};

export default AdvisorContainer;
