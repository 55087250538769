"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TryThisService = void 0;
var OpenAPI_1 = require("../core/OpenAPI");
var request_1 = require("../core/request");
var TryThisService = /** @class */ (function () {
    function TryThisService() {
    }
    /**
     * Get Try This
     * @returns AnalysisTopic The analysis.
     * @throws ApiError
     */
    TryThisService.tryThisGetTryThis = function () {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/try_this',
        });
    };
    return TryThisService;
}());
exports.TryThisService = TryThisService;
