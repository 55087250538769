import {styled} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';

const ClickableDataGrid = styled(DataGrid)({
    '.MuiDataGrid-row': {
        'cursor': 'pointer',
    },
    '.MuiDataGrid-cell:focus-within, .MuiDataGrid-cell:focus': {
        outline: 'none',
    },
    '.MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-columnHeader:focus': {
        outline: 'none',
    },
    '.MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
        '.MuiDataGrid-columnHeaderTitleContainerContent': {
            display: 'none',
        },
    },
    'minHeight': 683,
    'width': '100%',
    'my': 2,
});

export default ClickableDataGrid;
