import React, {useContext, useState} from 'react';
import {AppContext} from '../App';
import PropTypes from 'prop-types';
import ValueField from './field/ValueField';

const CustomParameterSelector = ({definition, values, disabled, onCustomParameterUpdate, sx}) => {
    const {config} = useContext(AppContext);
    const [internalValues, setInternalValues] = useState(values);

    if (definition) {
        return definition.map((p, index) =>
            (
                <ValueField
                    key={p.name + '-custom'}
                    value={internalValues[p.name] != null ? internalValues[p.name] : p.value}
                    // only used when type == select.
                    possibleValues={p.options}
                    label={config.i18n.custom_parameters[p.name] || p.name}
                    type={p.value_type}
                    disabled={disabled}
                    metadata={config.i18n.custom_parameters} // enables translation of select options
                    sx={sx}
                    onChange={(value) => {
                        const newCustomValues = {...internalValues, [p.name]: value};
                        setInternalValues(newCustomValues);
                        onCustomParameterUpdate(newCustomValues);
                    }}
                />
            ));
    } else {
        return null;
    }
};

CustomParameterSelector.propTypes = {
    definition: PropTypes.array,
    values: PropTypes.object,
    disabled: PropTypes.bool,
    onCustomParameterUpdate: PropTypes.func,
    sx: PropTypes.object,
};

export default CustomParameterSelector;
