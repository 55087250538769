import {IconButton, InputAdornment, Paper, Popover, TextField} from '@mui/material';
import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';
import CommentIcon from '@mui/icons-material/Comment';
import {AppContext} from '../App';


const CommentBox = ({disabled, required, error, value, onUpdate}) => {
    const {config} = useContext(AppContext);

    const [anchor, setAnchor] = useState();

    return (
        <>
            <Popover
                open={Boolean(anchor)}
                anchorEl={anchor}
                onClose={() => setAnchor(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Paper sx={{px: 2, py: 1, width: 400}}>
                    <TextField
                        fullWidth
                        multiline
                        rows={10}
                        size='small'
                        disabled={disabled}
                        required={required}
                        error={error}
                        placeholder={config.i18n.procurement.identify.comment_placeholder}
                        value={value}
                        onChange={(e) => onUpdate(e.target?.value)}
                    />
                </Paper>
            </Popover>

            <TextField
                fullWidth
                size='small'
                disabled={disabled}
                required={required}
                error={error}
                placeholder={config.i18n.procurement.identify.comment_placeholder}
                value={value}
                onChange={(e) => onUpdate(e.target?.value)}
                InputProps={{
                    endAdornment:
                        !disabled ?
                            <InputAdornment position='end'>
                                <IconButton onClick={(e) => {
                                    setAnchor(e.currentTarget);
                                }}
                                >
                                    <CommentIcon />
                                </IconButton>
                            </InputAdornment> :
                            null,
                }}
            />
        </>
    );
};

CommentBox.propTypes = {
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    error: PropTypes.bool,
    value: PropTypes.string,
    onUpdate: PropTypes.func,
};

export default CommentBox;
