import React, {useContext} from 'react';
import {Typography} from '@mui/material';

import {AppContext} from '../App';
import AdvisorContainer from '../layout/AdvisorContainer';
import {Helmet} from 'react-helmet';


const Explore = () => {
    const {config} = useContext(AppContext);

    return (
        <>
            <Helmet>
                <title>{config.i18n.page.main} - {config.i18n.page.explore}</title>
            </Helmet>
            <AdvisorContainer>
                <Typography variant="h4">{config.i18n.page.explore}</Typography>
            </AdvisorContainer>
        </>
    );
};

export default Explore;
