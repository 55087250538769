"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CategoryService = void 0;
var OpenAPI_1 = require("../core/OpenAPI");
var request_1 = require("../core/request");
var CategoryService = /** @class */ (function () {
    function CategoryService() {
    }
    /**
     * Level 1 Search
     * @param q
     * @param page
     * @param limit
     * @param requestBody
     * @returns PaginatedResponse_DimensionModel_ The paginated searched category level 1.
     * @throws ApiError
     */
    CategoryService.categoryLevel1Search = function (q, page, limit, requestBody) {
        if (q === void 0) { q = ''; }
        if (limit === void 0) { limit = 20; }
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/category/level-1',
            query: {
                'q': q,
                'page': page,
                'limit': limit,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * Level 2 Search
     * @param q
     * @param page
     * @param limit
     * @param requestBody
     * @returns PaginatedResponse_DimensionModel_ The paginated searched category level 2.
     * @throws ApiError
     */
    CategoryService.categoryLevel2Search = function (q, page, limit, requestBody) {
        if (q === void 0) { q = ''; }
        if (limit === void 0) { limit = 20; }
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/category/level-2',
            query: {
                'q': q,
                'page': page,
                'limit': limit,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * Level 3 Search
     * @param q
     * @param page
     * @param limit
     * @param requestBody
     * @returns PaginatedResponse_DimensionModel_ The paginated searched category level 3.
     * @throws ApiError
     */
    CategoryService.categoryLevel3Search = function (q, page, limit, requestBody) {
        if (q === void 0) { q = ''; }
        if (limit === void 0) { limit = 20; }
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/category/level-3',
            query: {
                'q': q,
                'page': page,
                'limit': limit,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * Level 4 Search
     * @param q
     * @param page
     * @param limit
     * @param requestBody
     * @returns PaginatedResponse_DimensionModel_ The paginated searched category level 4.
     * @throws ApiError
     */
    CategoryService.categoryLevel4Search = function (q, page, limit, requestBody) {
        if (q === void 0) { q = ''; }
        if (limit === void 0) { limit = 20; }
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/category/level-4',
            query: {
                'q': q,
                'page': page,
                'limit': limit,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * Level 5 Search
     * @param q
     * @param page
     * @param limit
     * @param requestBody
     * @returns PaginatedResponse_DimensionModel_ The paginated searched category level 5.
     * @throws ApiError
     */
    CategoryService.categoryLevel5Search = function (q, page, limit, requestBody) {
        if (q === void 0) { q = ''; }
        if (limit === void 0) { limit = 20; }
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/category/level-5',
            query: {
                'q': q,
                'page': page,
                'limit': limit,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: "Validation Error",
            },
        });
    };
    return CategoryService;
}());
exports.CategoryService = CategoryService;
