export const validateProject = (client, notify, projectId, onResultAvailable) => {
    client.procurement.procurementValidateProject(projectId)
        .then((status) => {
            onResultAvailable(status);
        })
        .catch((error) => {
            notify.error(error, 'procurement.project.projects.validation',
                `Error validating project ${projectId}.`);
            onResultAvailable([]);
        });
};

