import {
    defaultValueLabelSetting,
    formatTooltipValue,
    getAxisV,
    getBase,
    getFormattedValue,
    getItemTooltip, getTooltipLabel,
    getYSeriesData,
} from './common';

export function isExtreme(index, series) {
    return index === 0 || index === series.length - 1;
}

export function cutoffYAxis(ySeries) {
    const cumulative = ySeries.map((series) => {
        // know the y axis value of each point on the chart, we recursively add all data values (except the extremes)
        let cumul = 0;
        return series.map((entry, index) => {
            cumul += entry.value;
            return index === series.length -1 ? entry.value : cumul;
        });
    });

    // list with non-extreme chart values, that is, the variance values
    // we use the absolute because we want to know the largest bar (maxDims)
    const ySeriesAbs = ySeries.map((series) => {
        return series.slice(1, -1).map((entry, index) => Math.abs(entry.value));
    });

    let min = Math.min(...cumulative[0]);
    let maxDims = Math.max(...ySeriesAbs[0]);

    const multiplier = [1e12, 1e9, 1e6, 1e3, 1]
        .find((data) => min >= data) || 1;

    min = Math.round(min / multiplier * 100) / 100;
    maxDims = Math.round(maxDims / multiplier * 100) / 100;

    // round value to be a multiple of 10: 10, 20, 30, .. or, if the granularity is smaller, a multiple of 2: 2, 4, 6, 8, 10...
    let minAxis = (Math.round((min - maxDims) / 10) * 10);
    // minAxis constraint : it needs to be smaller than the min data value but greater than 0
    // add two decimal digits because of the thinner granularity
    minAxis = minAxis < min && minAxis > 0 ? minAxis : (Math.round(((min - maxDims) / 2) * 100) * 2) / 100;

    return {
        min: minAxis < 1 ? 0 : minAxis * multiplier,
    };
}

export const getTooltipLabelWithDimension = (id, data, config) => {
    if (data.metadata[id]) {
        if (id === null) {
            return data.metadata[id]?.dimension + ' ' + config.i18n.chart.label['__null__'];
        } else {
            return data.metadata[id]?.dimension + ' ' + data.metadata[id]?.name;
        }
    } else {
        return getTooltipLabel(id, data, config);
    }
};

export const getChartOptions = (title, data, config, baseFontSize) => {
    const ySeriesData = getYSeriesData(data);

    const extremes = ySeriesData.map((series) => {
        return series.map((entry, index) => {
            return {
                id: entry.id,
                name: entry.name,
                value: isExtreme(index, series) ? entry.value : '-',
            };
        });
    });

    const positiveData = ySeriesData.map((series) => {
        return series.map((entry, index) => {
            return {
                id: entry.id,
                name: entry.name,
                // if it is an extreme value, we ignore it as it is a different series
                value: entry.value >= 0 && !isExtreme(index, series) ? entry.value : '-',
            };
        });
    });

    const negativeData = ySeriesData.map((series) => {
        return series.map((entry, index) => {
            return {
                id: entry.id,
                name: entry.name,
                // if it is an extreme value, we ignore it as it is a different series
                value: entry.value < 0 && ! isExtreme(index, series) ? Math.abs(entry.value) : '-',
            };
        });
    });

    // the bottom stack is transparent (visual hack to seem a waterfall)
    const transparentSeries = ySeriesData.map((series) => {
        let counter = 0;
        series = series.map((entry, index) => {
            const item = counter;
            counter += entry.value;
            if (entry.value >= 0) {
                return item;
            } else {
                return item - series[index - 1].value + (series[index - 1].value + entry.value);
            }
        });
        series[series.length - 1] = 0;
        return series;
    });

    // all series have the same size, so we can iterate positiveData and use its index
    const seriesToPlot = positiveData.flatMap((series, index) => [
        {
            name: 'transparent',
            type: 'bar',
            stack: 'waterfall',
            itemStyle: {
                borderColor: 'transparent',
                color: 'transparent',
            },
            data: transparentSeries[index],
        },
        {
            name: 'extremes',
            type: 'bar',
            stack: 'waterfall',
            data: extremes[index],
            label: defaultValueLabelSetting('top', baseFontSize, config,
                (value) => getFormattedValue(value.data.value, 'y', data, config)),
        },
        {
            name: 'values_positive',
            type: 'bar',
            stack: 'waterfall',
            // we are already iterating positiveData
            data: series,
            label: defaultValueLabelSetting('top', baseFontSize, config,
                (value) => getFormattedValue(value.data.value, 'y', data, config)),
        },
        {
            name: 'values_negative',
            type: 'bar',
            stack: 'waterfall',
            data: negativeData[index],
            label: defaultValueLabelSetting('bottom', baseFontSize, config,
                (value) => getFormattedValue(-value.data.value, 'y', data, config)),
        }],
    );

    // random name to stack series
    return {
        ...getBase(title, baseFontSize),
        ...getAxisV(data, config, baseFontSize, 1),
        yAxis: {
            ...getAxisV(data, config, baseFontSize, 1).yAxis,
            ...cutoffYAxis(ySeriesData),
        },

        tooltip: {
            ...getItemTooltip(data, config, baseFontSize).tooltip,
            formatter: (params) => {
                // transparent series does not have tooltip !
                if (params.seriesName !== 'transparent') {
                    const name = formatTooltipValue(data, 'x', getTooltipLabelWithDimension(params.data.id, data, config), config);

                    return `${name} <br/> ${params.marker}${formatTooltipValue(data, 'y', params.value, config)}`;
                }
            },
        },

        series: seriesToPlot,
    };
};
