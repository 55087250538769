import Slide from '../../../../components/Slide';
import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from '../../../../App';
import PropTypes from 'prop-types';
import {getDefaultByType, initializeParameterDefaults} from '../../../../util/parameters';


const RefineMetric = ({externalLoading, analysisId, customParametersValues}) => {
    const {client, notify} = useContext(AppContext);

    const [loading, setLoading] = useState(false);
    const [analysis, setAnalysis] = useState();
    const [analysisContext, setAnalysisContext] = useState();

    useEffect(() => {
        if (analysisId) {
            setLoading(true);

            client.analysis.analysisGet(analysisId)
                .then((analysis) => {
                    setAnalysis(analysis);
                })
                .catch((error) => {
                    setAnalysis(null);
                    setLoading(false);
                    notify.error(error, 'analysis.fetch', 'Error getting analysis.');
                });
        } else {
            setAnalysis(null);
        }
    }, [analysisId]);

    useEffect(() => {
        if (analysis && customParametersValues) {
            setLoading(true);

            client.query.queryRun(analysis.id, {
                parameters: initializeParameterDefaults(analysis, ['CHART', 'DIMENSION', 'HIERARCHY']),
                custom_parameters: customParametersValues,
            })
                .then((data) => {
                    setAnalysisContext({
                        question: analysis.question,
                        chart: getDefaultByType(analysis.customization_parameters, 'CHART'),
                        data: data,
                    });
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    notify.error(error, 'analysis.run',
                        `Error fetching analysis (${analysis.id}, ${analysis.question}) result.`);
                });
        }
    }, [analysis, customParametersValues]);

    return (
        <Slide context={analysisContext} loading={externalLoading || loading} />
    );
};

RefineMetric.propTypes = {
    externalLoading: PropTypes.bool,
    analysisId: PropTypes.string,
    customParametersValues: PropTypes.object,
};

RefineMetric.defaultValues = {
    customParametersValues: {},
};

export default RefineMetric;
