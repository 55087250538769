import React from 'react';

// NOTE: class component is used here since there is no react hook with the same behavior as componentDidCatch, so lint is ignored in this scenario.
// Since this component is used to cover unexpected cases and are not supposed to happen. This approach works
// https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary

// eslint-disable-next-line react/require-optimization
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidCatch(error, info) {
        console.log(error, info.componentStack);
        // eslint-disable-next-line react/destructuring-assignment,react/prop-types
        this.props.navigate('/error');
    }

    render() {
        // eslint-disable-next-line react/prop-types,react/destructuring-assignment
        return this.props.children;
    }
}

export default ErrorBoundary;
