import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useContext} from 'react';
import {union, without} from 'lodash';

import {AppContext} from '../App';
import {getDefaultByType, parseCustomizationParameters} from '../util/parameters';
import DimensionBox from './DimensionBox';
import {convertToISODate, hasValue} from '../util/util';
import CustomParameterSelector from './CustomParameterSelector';
import DimensionSelector from './selector/DimensionSelector';
import DateSelector from './selector/DateSelector';
import CommonSelector from './selector/CommonSelector';
import CurrencySelector from './selector/CurrencySelector';
import SelectField from './field/SelectField';


const CustomizationBar = ({
    customizationParameters,
    analysisParameters,
    analysisDimensions,
    analysisDimParameters,
    analysisCustomParameters,
    analysisChart,
    analysisMetadata,
    onParameterUpdate,
    onChartUpdate,
    onCustomParameterUpdate,
    onDimensionUpdate,
    disabled,
}) => {
    const {config} = useContext(AppContext);

    function getDocumentSelector(documentParam) {
        if (!documentParam) {
            return (
                <SelectField
                    key="document-selector"
                    datacy="document_selector"
                    label={config.i18n.selector.document}
                    disabled
                />
            );
        }

        const param = {
            options: documentParam.options,
            value: analysisParameters['DOCUMENT'],
            onUpdate: (value) => onParameterUpdate('DOCUMENT', value),
        };

        return (
            <SelectField
                key="document-selector"
                datacy="document_selector"
                label={config.i18n.selector.document}
                value={param.value || ''}
                disabled={disabled || !param}
                possibleValues={param?.options || []}
                metadata={config.i18n.document}
                onChange={param?.onUpdate}
            />
        );
    }

    function getCommmonSelector(chartParam, limitParam, hideTailParam, ignoreNullParam, cumulativeParam) {
        if (!chartParam && !limitParam && !hideTailParam && !ignoreNullParam && !cumulativeParam) {
            return null;
        }

        let chart = null;
        if (chartParam) {
            chart = {
                options: chartParam.options,
                value: analysisChart,
                onUpdate: onChartUpdate,
            };
        }

        let limit = null;
        if (limitParam) {
            limit = {
                options: limitParam.options,
                value: analysisParameters['LIMIT'],
                onUpdate: (value) => onParameterUpdate('LIMIT', value),
            };
        }

        let hideTail = null;
        if (hideTailParam) {
            hideTail = {
                value: analysisParameters['HIDE_TAIL'],
                onUpdate: (value) => onParameterUpdate('HIDE_TAIL', value),
            };
        }

        let ignoreNull = null;
        if (ignoreNullParam) {
            ignoreNull = {
                value: analysisParameters['IGNORE_NULL'],
                onUpdate: (value) => onParameterUpdate('IGNORE_NULL', value),
            };
        }

        let cumulative = null;
        if (cumulativeParam) {
            cumulative = {
                value: analysisParameters['CUMULATIVE'],
                onUpdate: (value) => onParameterUpdate('CUMULATIVE', value),
            };
        }

        return (
            <CommonSelector
                key='common-selector'
                disabled={disabled}
                chart={chart}
                limit={limit}
                tail={hideTail}
                ignoreNull={ignoreNull}
                cumulative={cumulative}
            />
        );
    }

    function getDateSelector(dateParam, dateAggParam, annualizeParam) {
        if (!dateParam) {
            return null;
        }

        const date = analysisParameters['DATE'];
        const defaultDate = getDefaultByType(customizationParameters, 'DATE');

        let dateAgg = null;
        if (dateAggParam) {
            const dateAggValue = analysisParameters['DATE_AGG'];
            dateAgg = {
                options: dateAggParam.options,
                value: hasValue(dateAggValue) ? dateAggValue : dateAggParam.default,
                onUpdate: (value) => onParameterUpdate('DATE_AGG', value),
            };
        }

        let annualize = null;
        if (annualizeParam) {
            const annualizeValue = analysisParameters['ANNUALIZE'];
            annualize = {
                value: hasValue(annualizeValue) ? annualizeValue : annualizeParam.default,
                onUpdate: (value) => onParameterUpdate('ANNUALIZE', value),
            };
        }

        return (
            <DateSelector
                key='date-selector'
                disabled={disabled}
                date={date}
                defaultDate={defaultDate}
                dateAgg={dateAgg}
                annualize={annualize}
                onUpdate={(min, max) => onParameterUpdate('DATE', [convertToISODate(min), convertToISODate(max)])}
            />
        );
    }


    function getCurrencySelector(forexDateParam, dateParam) {
        if (!dateParam || !forexDateParam) {
            return null;
        }

        const date = analysisParameters['DATE'];

        let forexDate = null;
        if (forexDateParam) {
            forexDate = analysisParameters['FOREX_DATE'];
        }

        return (
            <CurrencySelector
                key="currency-selector"
                disabled={disabled}
                forexDate={forexDate}
                datePickers={date}
                onUpdate={(value) => onParameterUpdate('FOREX_DATE', value !== null ? convertToISODate(value) : null)}
            />
        );
    }

    const elementsByType = parseCustomizationParameters(customizationParameters);
    const final = [
        getDocumentSelector(elementsByType.DOCUMENT),
        getCommmonSelector(elementsByType.CHART, elementsByType.LIMIT, elementsByType.HIDE_TAIL, elementsByType.IGNORE_NULL, elementsByType.CUMULATIVE),
        getDateSelector(elementsByType.DATE, elementsByType.DATE_AGG, elementsByType.ANNUALIZE),
        getCurrencySelector(elementsByType.FOREX_DATE, elementsByType.DATE),
    ];

    final.push(
        <DimensionSelector
            key='dim-selector'
            disabled={disabled}
            dimensions={analysisDimensions}
            dimensionFilters={analysisDimParameters}
            metadata={analysisMetadata}
            filters={analysisParameters}
            onUpdate={(dimension, dimensionFilters = null, metadata = null) => {
                const newDimensions = union(analysisDimensions, [dimension]);

                onDimensionUpdate(newDimensions, dimensionFilters, metadata);
            }}
            onDelete={(dimension, dimensionKeys) => {
                const newDimensions = without(analysisDimensions, dimension);
                [dimension, ...dimensionKeys].forEach((key) => delete analysisDimParameters[key]);

                onDimensionUpdate(newDimensions, analysisDimParameters);
            }}
        />,
    );

    if (elementsByType['CUSTOM']) {
        final.push(
            <DimensionBox
                datacy='custom_parameters_box'
                title={config.i18n.custom_parameters.title}
                key='user-box'
                internalSx={{p: 0.5}}
            >
                <CustomParameterSelector
                    definition={elementsByType['CUSTOM']}
                    values={analysisCustomParameters}
                    disabled={disabled}
                    onCustomParameterUpdate={onCustomParameterUpdate}
                    sx={{m: 0.5, flex: 1}}
                />
            </DimensionBox>,
        );
    }

    return (
        <Box
            datacy="customization_bar"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
            }}
        >
            {final}
        </Box>
    );
};

CustomizationBar.propTypes = {
    customizationParameters: PropTypes.array,
    analysisParameters: PropTypes.object,
    analysisDimensions: PropTypes.array,
    analysisDimParameters: PropTypes.object,
    analysisCustomParameters: PropTypes.object,
    analysisChart: PropTypes.string,
    analysisMetadata: PropTypes.object,
    onDimensionUpdate: PropTypes.func,
    onParameterUpdate: PropTypes.func,
    onChartUpdate: PropTypes.func,
    onCustomParameterUpdate: PropTypes.func,
    disabled: PropTypes.bool,
};

export default CustomizationBar;
