import PropTypes from 'prop-types';
import React, {useContext} from 'react';


import {AppContext} from '../../../../App';

import {Box, Grid, MenuItem, Select, Typography} from '@mui/material';
import {formatBreakEven, formatCompactAmount, formatIrr} from '../../../../util/formatter';
import ReadOnlyTextField from '../../../../components/field/ReadOnlyTextField.js';


const PriorityTable = ({initiative, setInitiative}) => {
    const {config} = useContext(AppContext);

    if (!initiative) {
        return null;
    }

    return (
        <>
            <Typography variant='h6' align='center' sx={{mb: 3}}>
                {config.i18n.procurement.identify.priority.title}
            </Typography>
            <Grid container spacing={2} alignItems='center' justifyContent='space-evenly' sx={{mb: 1}}>
                <Grid item xs={2.4}>
                    <Typography align='center'>{config.i18n.procurement.identify.npv}</Typography>
                </Grid>
                <Grid item xs={2.4}>
                    <Typography align='center'>{config.i18n.procurement.identify.irr}</Typography>
                </Grid>
                <Grid item xs={2.4}>
                    <Typography align='center'>{config.i18n.procurement.identify.break_even}</Typography>
                </Grid>
                <Grid item xs={2.4}>
                    <Typography align='center'>{config.i18n.procurement.simplicity.label}</Typography>
                </Grid>
                <Grid item xs={2.4}>
                    <Typography align='center'>{config.i18n.procurement.support.label}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2} alignItems='center' justifyContent='space-evenly' sx={{mb: 1}}>
                <Grid item xs={2.4}>
                    <ReadOnlyTextField value={formatCompactAmount(initiative.npv, config.locale)} />
                </Grid>
                <Grid item xs={2.4}>
                    <ReadOnlyTextField value={formatIrr(initiative.irr, config.locale, config.i18n.infinity)} />
                </Grid>
                <Grid item xs={2.4}>
                    <ReadOnlyTextField value={formatBreakEven(initiative.break_even, config.locale, config.i18n)} />
                </Grid>
                <Grid item xs={2.4}>
                    <Select
                        fullWidth
                        size='small'
                        value={initiative?.simplicity}
                        onChange={(e) => {
                            setInitiative({
                                ...initiative,
                                simplicity: e.target.value,
                            });
                        }}
                        startAdornment={
                            // HACK
                            <Box sx={{width: 15}} />
                        }
                        sx={{
                            '.MuiSelect-select': {
                                textAlign: 'center',
                            },
                        }}
                    >
                        {config.procurement.simplicity.map((key) => (
                            <MenuItem key={key} value={key} sx={{justifyContent: 'center'}}>
                                {config.i18n.procurement.simplicity.values[key]}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs={2.4}>
                    <Select
                        fullWidth
                        size='small'
                        value={initiative?.support}
                        onChange={(e) => {
                            setInitiative({
                                ...initiative,
                                support: e.target.value,
                            });
                        }}
                        startAdornment={
                            // HACK
                            <Box sx={{width: 15}} />
                        }
                        sx={{
                            '.MuiSelect-select': {
                                textAlign: 'center',
                            },
                        }}
                    >
                        {config.procurement.support.map((key) => (
                            <MenuItem key={key} value={key} sx={{justifyContent: 'center'}}>
                                {config.i18n.procurement.support.values[key]}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
            </Grid>
        </>
    );
};

PriorityTable.propTypes = {
    initiative: PropTypes.object,
    setInitiative: PropTypes.func,
};

export default PriorityTable;
