import AddIcon from '@mui/icons-material/Add';
import {Button} from '@mui/material';
import React, {useContext, useState} from 'react';
import {AppContext} from '../../App';
import PropTypes from 'prop-types';
import DimensionPicker from './DimensionPicker';

const DimensionSelector = ({dimensionFilters, dimensions, metadata, filters, disabled, onUpdate, onDelete}) => {
    const {config} = useContext(AppContext);

    const [isAddMode, setIsAddMode] = useState(false);

    // render already applied dims.
    const appliedDims = dimensions.map((dimension) =>
        (
            <DimensionPicker
                key={`${dimension}-box`}
                dimension={dimension}
                dimensionFilters={dimensionFilters}
                filters={filters}
                disabled={disabled}
                onUpdate={onUpdate}
                onDelete={onDelete}
                metadata={metadata}
            />
        ));

    return (
        <>
            {appliedDims}

            {isAddMode ? (
                <DimensionPicker
                    dimensionFilters={dimensionFilters}
                    filters={filters}
                    disabled={disabled}
                    onUpdate={(dimension, dimensionFilters, metadata) => {
                        onUpdate(dimension, dimensionFilters, metadata);
                        setIsAddMode(false);
                    }}
                    onDelete={() => setIsAddMode(false)}
                    metadata={metadata}
                />
            ) : null}

            <Button
                datacy='dimension_add_button'
                variant='contained'
                startIcon={<AddIcon />}
                disabled={isAddMode || disabled}
                onClick={() => setIsAddMode(true)}
            >
                {config.i18n.button.add_filter}
            </Button>
        </>
    );
};

DimensionSelector.propTypes = {
    dimensionFilters: PropTypes.object,
    dimensions: PropTypes.array,
    metadata: PropTypes.object,
    filters: PropTypes.object,
    disabled: PropTypes.bool,
    onUpdate: PropTypes.func,
    onDelete: PropTypes.func,
};

export default DimensionSelector;
