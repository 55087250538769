import {defaultValueLabelSetting, getAxisTooltip, getAxisV, getBase, getFormattedValue, getYSeriesData} from './common';

export const getChartOptions = (title, data, config, baseFontSize) => {
    const ySeriesData = getYSeriesData(data);
    const ySeriesDataWithTotals = ySeriesData.map((series) => [
        ...series.map((entry, index) => {
            return {
                id: entry.id,
                name: entry.name,
                positive: entry.value >= 0,
                value: Math.abs(entry.value),
            };
        }),
        {
            id: '__total__',
            name: '__total__',
            value: series.reduce((sum, entry) => sum + entry.value, 0),
            positive: series.reduce((sum, entry) => sum + entry.value, 0) >= 0,
        },
    ]);

    // the bottom stack is transparent (visual hack to seem a waterfall)
    const transparentSeries = ySeriesData.map((series) => {
        let counter = 0;
        return series.map((entry) => {
            const item = counter;
            counter += entry.value;
            if (entry.value >= 0) {
                return item;
            } else {
                // if the value is negative, the bar starts at the top of the previous bar and grows to the bottom (is "upside down")
                // because of this, we add the value of the entry (which is negative)
                // so we are shortening the transparent bar to give "space" for the negative bar
                return item + entry.value;
            }
        });
    });

    // add total to the end of the dataset
    const dataWithTotal = {
        ...data,
        result: [
            ...data.result,
            {
                id: '__total__',
                label: '__total__',
                x: '__total__',
                y: ySeriesDataWithTotals.map((series) => series[series.length - 1].value),
            },
        ],
    };

    const seriesToPlot = ySeriesDataWithTotals.flatMap((series, index) => [
        {
            name: 'transparent',
            type: 'bar',
            stack: 'waterfall',
            itemStyle: {
                borderColor: 'transparent',
                color: 'transparent',
            },
            data: transparentSeries[index],
        },
        {
            name: 'values',
            type: 'bar',
            stack: 'waterfall',
            data: series,
            label: defaultValueLabelSetting('top', baseFontSize, config,
                (value) => getFormattedValue(value.data.positive ? value.data.value : -value.data.value, 'y', data, config)),
        }],
    );
    // random name to stack series
    return {
        ...getBase(title, baseFontSize),
        ...getAxisV(dataWithTotal, config, baseFontSize, 1),
        ...getAxisTooltip(dataWithTotal, config, baseFontSize),

        series: seriesToPlot,
    };
};
