import {FormControl, InputLabel, MenuItem, Select} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const SelectField = ({size, value, label, disabled, possibleValues, metadata, onChange, sx, datacy}) => {
    return (
        <FormControl sx={sx} size={size} fullWidth>
            <InputLabel id="value-select-label" disabled={disabled}>{label}</InputLabel>
            <Select
                datacy={datacy}
                labelId="value-select-label"
                id="value-select"
                label={label}
                value={value}
                disabled={disabled}
                onChange={(event) => onChange(event.target.value)}
                MenuProps={{disableScrollLock: true}}
            >
                {
                    possibleValues?.map((option) =>
                        (
                            <MenuItem
                                datacy={'select-option-' + option}
                                key={option}
                                value={option}
                                disabled={disabled}
                            >
                                {metadata[option] || option}
                            </MenuItem>
                        ))
                }
            </Select>
        </FormControl>
    );
};

SelectField.propTypes = {
    value: PropTypes.any,
    size: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    possibleValues: PropTypes.array,
    metadata: PropTypes.object,
    onChange: PropTypes.func,
    sx: PropTypes.object,
    datacy: PropTypes.string,
};

SelectField.defaultProps = {
    size: 'small',
    metadata: {},
    onChange: () => {
    },
    sx: {},
    datacy: 'selector',
};

export default SelectField;
