import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Box, CircularProgress, Fab, Fade, Paper, Typography} from '@mui/material';
import Markdown from 'markdown-to-jsx';
import {cleanStringForSlide, downloadSlide} from '../util/slide_util';
import {hasValue} from '../util/util';
import {AppContext} from '../App';
import AspectRatioBox from '../util/AspectRatioBox';
import {formatTemplate} from '../util/formatter';
import Chart from './Chart';
import ComponentLoading from './loading/ComponentLoading';
import {decode} from 'js-base64';
import {upperFirst} from 'lodash';
import {useNavigate} from 'react-router-dom';
import DownloadIcon from '@mui/icons-material/Download';

const Slide = ({
    context,
    loading,
    disableDrillDown,
    disableDrillAcross,
    onAnalysisLinkClick,
    onDrillDown,
    onChartContextMenu,
}) => {
    const {config, notify} = useContext(AppContext);
    const [slideRef, setSlideRef] = useState();
    const [baseFontSize, setBaseFontSize] = useState();
    const navigate = useNavigate();
    const [isHovering, setIsHovering] = useState(false);
    const [loadingDownload, setLoadingDownload] = useState();
    const ref = useRef();

    const issueDownloadSlide = (title, subtitle, insights, annotations) => {
        setLoadingDownload(true);
        const image64 = ref.current.exportImage();
        downloadSlide(title, subtitle, insights, annotations, image64, question,
            () => setLoadingDownload(false),
            () => {
                setLoadingDownload(false);
                notify.error('Download failed');
            });
    };

    // re-compute slide font size when window is resized
    useEffect(() => {
        if (slideRef) {
            const onResize = () => {
                if (slideRef) {
                    const width = slideRef.clientWidth;
                    setBaseFontSize(width / 35);
                }
            };

            onResize();

            const observer = new ResizeObserver(onResize);

            observer.observe(slideRef);

            return () => {
                observer.disconnect();
            };
        }
    }, [slideRef]);

    // https://tkdodo.eu/blog/avoiding-use-effect-with-callback-refs
    const slideRefCallback = useCallback(setSlideRef, []);

    // let's show a placeholder slide, when there is no context.
    if (!context) {
        return (
            <AspectRatioBox ratio={16 / 9}>
                <Paper
                    elevation={3}
                    sx={{display: 'flex', flexDirection: 'column', fontSize: baseFontSize}}
                    ref={slideRefCallback}
                >
                    <ComponentLoading loading={loading} label={config.i18n.slide.loading} />
                </Paper>
            </AspectRatioBox>
        );
    }

    const {question, chart, data} = context;

    //  group insights by overridable and the not overridable & apply variables to insights templates
    const insights = data.insights.overridable.concat(data.insights.not_overridable).map((insight) => {
        return formatTemplate(
            insight,
            data.variables,
            config.locale,
            config.i18n,
            data.metadata);
    }).map((i) => upperFirst(i.trimStart()));

    // title is always the first insight, or the question if no insights
    let title = upperFirst(formatTemplate(
        data.insights.title,
        data.variables,
        config.locale,
        config.i18n,
        data.metadata).trimStart());

    const subtitle = data.variables.dimensions && formatTemplate(
        data.variables.dimensions,
        data.variables,
        config.locale,
        config.i18n,
        data.metadata);

    const formatedQuestion = formatTemplate(
        question,
        data.variables,
        config.locale,
        config.i18n,
        data.metadata);


    if (hasValue(subtitle)) {
        title += '...';
    }

    // apply variables to footer template
    const footers = data.footers?.map((footer) => formatTemplate(
        footer,
        data.variables,
        config.locale,
        config.i18n,
        data.metadata));
    //
    // the main fontSize is set in pixels (at the StyledPaper component)
    // all other JSX paddings, margins and fontSize must be set in "em" to be relative to the parent
    // the chartOptions are set as a multiplier of the main font size (which is equivalent to using "em")
    //
    return (
        <AspectRatioBox ratio={16 / 9}>
            <Paper
                datacy="slide"
                onMouseOver={() => setIsHovering(true)}
                onMouseOut={() => setIsHovering(false)}
                elevation={3}
                sx={{display: 'flex', flexDirection: 'column', fontSize: baseFontSize}}
                ref={slideRefCallback}
            >
                <ComponentLoading loading={loading} label={config.i18n.slide.loading} />
                {baseFontSize && data.result?.length > 0 ?
                    (
                        <>
                            <Box sx={{flex: 1, display: 'flex', flexDirection: 'column', pt: '0.75em', px: '1.5em'}}>
                                {
                                    title ? (
                                        <Typography variant="h5" align="left" sx={{fontSize: '1em'}}>
                                            <Markdown datacy='slide_title'>{title}</Markdown>
                                        </Typography>
                                    ) : null
                                }
                                <Fade in={loadingDownload || isHovering}>
                                    <Fab
                                        aria-label="download"
                                        onClick={() => issueDownloadSlide(
                                            cleanStringForSlide(title),
                                            cleanStringForSlide(subtitle),
                                            insights.map((i) => cleanStringForSlide(i)),
                                            footers.filter((f) => f.length > 0).map((f) =>
                                                cleanStringForSlide(f)))
                                        }
                                        sx={{
                                            width: 2 * baseFontSize,
                                            height: 2 * baseFontSize,
                                            minWidth: 0,
                                            minHeight: 0,
                                            position: 'absolute',
                                            right: 1.5 * baseFontSize,
                                            top: 0.75 * baseFontSize}}
                                    >
                                        {loadingDownload ?
                                            <CircularProgress size={baseFontSize} /> :
                                            <DownloadIcon sx={{fontSize: baseFontSize}} />}
                                    </Fab>
                                </Fade>
                                {
                                    subtitle ? (
                                        <Typography variant="subtitle1" align="left" sx={{fontSize: '0.5em', color: '#666666'}}>
                                            <Markdown datacy='slide_subtitle'>{subtitle}</Markdown>
                                        </Typography>
                                    ) : null
                                }


                                <Box sx={{flex: 1, display: 'flex', flexDirection: 'row', mt: '1.25em', mb: '0.5em'}}>
                                    <Box
                                        datacy='chart'
                                        sx={{flex: 2}}
                                    >
                                        <Chart
                                            type={chart}
                                            title={formatedQuestion}
                                            baseFontSize={baseFontSize}
                                            data={data}
                                            ref={ref}
                                            disableDrillDown={disableDrillDown}
                                            disableDrillAcross={disableDrillAcross}
                                            onDrillDown={onDrillDown}
                                            onContextMenu={onChartContextMenu}
                                        />
                                    </Box>
                                    <Box
                                        datacy='insights_box'
                                        sx={{
                                            flex: 1,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            pl: '1em',
                                        }}
                                    >
                                        {insights && insights.length > 0 ? (
                                            insights.map((insight, index) => (
                                                <Box
                                                    key={`insight_${index}`}
                                                    sx={{
                                                        my: '0.5em',
                                                        ml: '1em',
                                                        fontSize: '0.5em',
                                                    }}
                                                >
                                                    <Markdown
                                                        options={{
                                                            overrides: {
                                                                a: {
                                                                    props: {
                                                                        onClick: (ev) => {
                                                                            ev.preventDefault();

                                                                            const hash = ev.target.hash;
                                                                            if (hash) {
                                                                                // remove # char.
                                                                                const analysis =
                                                                                    JSON.parse(decode(hash.slice(1)));
                                                                                onAnalysisLinkClick(analysis.analysis.id);
                                                                            } else {
                                                                                const baseUrl = window.location.origin;
                                                                                const newRoute =
                                                                                    ev.target.href.replace(baseUrl, '');

                                                                                navigate(newRoute);
                                                                            }
                                                                        },
                                                                    },
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        {insight}
                                                    </Markdown>
                                                </Box>
                                            ))
                                        ) : (
                                            <Box
                                                sx={{
                                                    my: '0.5em',
                                                    pl: '1em',
                                                    fontSize: '0.5em',
                                                    fontStyle: 'italic',
                                                }}
                                            >
                                                {config.i18n.slide.no_insights}
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                            {
                                footers ? (
                                    <Typography
                                        datacy='footer'
                                        variant="caption"
                                        sx={{
                                            textAlign: 'left',
                                            py: '1em',
                                            px: '4.25em',
                                            fontSize: '0.32em',
                                            lineHeight: '0.5em',
                                            letterSpacing: '0',
                                        }}
                                    >
                                        <Markdown>{footers.join('\n')}</Markdown>
                                    </Typography>
                                ) : null
                            }
                        </>
                    ) : (
                        <Box
                            sx={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                width: '100%',
                                fontSize: '0.65em',
                                fontStyle: 'italic',
                                textAlign: 'center',
                                color: '#666666',
                                backgroundColor: '#f6f6f6',
                            }}
                        >
                            {config.i18n.slide.no_data}
                        </Box>
                    )
                }
            </Paper>
        </AspectRatioBox>
    );
};

Slide.propTypes = {
    context: PropTypes.object,
    loading: PropTypes.bool,
    disableDrillDown: PropTypes.bool,
    disableDrillAcross: PropTypes.bool,
    onAnalysisLinkClick: PropTypes.func,
    onDrillDown: PropTypes.func,
    onChartContextMenu: PropTypes.func,
};

Slide.defaultProps = {
    onAnalysisLinkClick: (link) => {
    },
    onDrillDown: (dimension, value) => {
    },
    onChartContextMenu: (params) => {
    },
};

export default Slide;
