import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, Typography} from '@mui/material';
import {GridActionsCellItem} from '@mui/x-data-grid';
import React, {useContext, useEffect, useState} from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import {AppContext} from '../../App';
import ClickableDataGrid from '../../components/clickableDataGrid';
import {formatDateTime} from '../../util/formatter';
import CircularLoading from '../../components/loading/CircularLoading';
import AdvisorContainer from '../../layout/AdvisorContainer';
import {Helmet} from 'react-helmet';

const Procurement = () => {
    const {client, accessToken, config, notify} = useContext(AppContext);
    const navigate = useNavigate();
    const [projects, setProjects] = useState();
    const [toDelete, setToDelete] = useState();
    const columns = [
        {
            field: 'name',
            headerName: config.i18n.procurement.project.name_column,
            flex: 4,
            sortable: false,
        },
        {
            field: 'created_at',
            headerName: config.i18n.procurement.project.created_at_column,
            flex: 2,
            sortable: false,
            valueFormatter: (params) =>
                formatDateTime(params.value, config.locale?.language, config.locale?.timezone),
        },
        {
            field: 'created_by_name',
            headerName: config.i18n.procurement.project.created_by_column,
            flex: 2,
            sortable: false,
        },
        {
            field: 'actions',
            flex: 0,
            sortable: false,
            type: 'actions',
            getActions: (params) => [
                <Tooltip key='edit' title={config.i18n.button.edit}>
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label={config.i18n.button.edit}
                        color='info'
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        component={RouterLink}
                        to={`/procurement/project/${params.id}/edit`}
                    />
                </Tooltip>,
                <Tooltip key='delete' title={config.i18n.button.delete}>
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label={config.i18n.button.delete}
                        color='error'
                        onClick={(e) => {
                            e.stopPropagation();
                            setToDelete(params.row);
                        }}
                    />
                </Tooltip>,
            ],
        },
    ];

    useEffect(() => {
        if (accessToken) {
            client.procurement.procurementListProjects()
                .then((projects) => {
                    setProjects(projects.response);
                })
                .catch((error) => {
                    setProjects([]);
                    notify.error(error, 'procurement.project.projects.fetch', 'Error fetching projects.');
                });
        } else {
            setProjects([]);
        }
    }, [accessToken]);

    if (!projects) {
        return (
            <AdvisorContainer>
                <CircularLoading height='800px' label={config.i18n.procurement.project.loading} />
            </AdvisorContainer>
        );
    }

    return (
        <>
            <Helmet>
                <title>{config.i18n.page.main} - {config.i18n.page.procurement}</title>
            </Helmet>
            <AdvisorContainer>
                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2}}>
                    <Typography variant="h4">{config.i18n.procurement.project.title}</Typography>
                    <Button
                        variant='contained'
                        startIcon={<AddIcon />}
                        component={RouterLink}
                        to='/procurement/project/create'
                        title={config.i18n.button.create}
                    >
                        {config.i18n.button.create}
                    </Button>
                </Box>
                <Box sx={{height: 400, width: '100%', my: 2}}>
                    {
                        toDelete ?
                            <Dialog
                                maxWidth='xs'
                                open={!!toDelete}
                            >
                                <DialogTitle>{config.i18n.dialog.delete_project_title}</DialogTitle>
                                <DialogContent dividers>
                                    {config.i18n.dialog.delete_project + ' ' + toDelete.name + '?'}
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => setToDelete(null)}>
                                        {config.i18n.button.cancel}
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            client.procurement.procurementDeleteProject(toDelete.id)
                                                .then((project) => {
                                                    setProjects(projects.filter((el) => el.id !== project.id));
                                                    setToDelete(null);
                                                })
                                                .catch((error) => {
                                                    notify.error(error, 'procurement.project.projects.delete',
                                                        'Error deleting projects.');
                                                    setToDelete(null);
                                                });
                                        }}
                                    >

                                        {config.i18n.button.delete}
                                    </Button>
                                </DialogActions>
                            </Dialog> :
                            null
                    }
                    <ClickableDataGrid
                        rows={projects}
                        columns={columns}
                        rowHeight={38}
                        disableRowSelectionOnClick
                        disableColumnMenu
                        disableColumnFilter
                        disableColumnSelector
                        initialState={{
                            pagination: {
                                paginationModel: {pageSize: 25, page: 0},
                            },
                        }}
                        onRowClick={(params) => navigate(`/procurement/project/${params.id}/prioritize`)}
                    />
                </Box>
            </AdvisorContainer>
        </>
    );
};

export default Procurement;
