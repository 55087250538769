import dayjs from 'dayjs';
import DimensionBox from '../DimensionBox';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import DatePickerTextField from '../field/DatePickerTextField';
import React, {useContext} from 'react';
import {AppContext} from '../../App';
import {Checkbox, FormControlLabel, Grid} from '@mui/material';
import PropTypes from 'prop-types';


const CurrencySelector = ({disabled, forexDate, datePickers, onUpdate}) => {
    const {config} = useContext(AppContext);

    const minDate = dayjs(datePickers[0]);
    const maxDate = dayjs(datePickers[1]);
    const date = forexDate ? dayjs(forexDate) : minDate;

    return (
        <DimensionBox
            title={config.i18n.customization_bar.currency}
            internalSx={{px: 1, py: 0.5, flexWrap: 'nowrap'}}
        >
            <Grid container spacing={1} rowSpacing={2}>
                <Grid item xs={6}>
                    <DatePicker
                        datacy="ForexDate_selector"
                        label={config.i18n.dimension.FOREX_DATE}
                        disabled={disabled || !forexDate}
                        disableFuture
                        minDate={minDate}
                        maxDate={maxDate}
                        value={date}
                        onChange={(val) => onUpdate(val)}
                        onAccept={(val) => onUpdate(val)}
                        slotProps={{textField: {size: 'small'}}}
                        renderInput={(params) =>
                            (
                                <DatePickerTextField
                                    {...params}
                                    disableClear
                                    fullWidth
                                />
                            )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                datacy="ConstantForex_selector"
                                disabled={disabled}
                                checked={!!forexDate}
                                onChange={(event) => {
                                    if (event.target.checked) {
                                        onUpdate(date);
                                    } else {
                                        onUpdate(null);
                                    }
                                }}
                            />
                        }
                        label={config.i18n.selector.constant_forex}
                    />
                </Grid>
            </Grid>
        </DimensionBox>
    );
};

CurrencySelector.propTypes = {
    disabled: PropTypes.bool,
    forexDate: PropTypes.object,
    datePickers: PropTypes.array,
    onUpdate: PropTypes.func,
    onUncheck: PropTypes.func,
};

export default CurrencySelector;
