import {Box, TextField} from '@mui/material';
import PropTypes from 'prop-types';
import ClearIconButton from '../button/ClearIconButton';
import React from 'react';

const DatePickerTextField = ({
    fullWidth,
    disableClear,
    disabled,
    onClear,
    onClick,
    InputProps,
    inputProps,
    sx,
    ...props
}) => {
    return (
        <TextField
            size='small'
            disabled={disabled}
            inputProps={inputProps}
            fullWidth={fullWidth}
            InputProps={{
                ...InputProps,
                endAdornment: (
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        {!disabled && !disableClear && inputProps?.value ? <ClearIconButton onClick={onClear} sx={{mr: -2}} /> : null}
                        {InputProps?.endAdornment}
                    </Box>
                ),
            }}
            sx={{
                '& .MuiInputBase-input': {
                    caretColor: 'transparent',
                    cursor: disabled ? 'auto' : 'pointer',
                },
                ...sx,
            }}
            onKeyDown={(e) => e.preventDefault()}
            onClick={(e) => !disabled && onClick(e)}
            {...props}
        />
    );
};

DatePickerTextField.propTypes = {
    fullWidth: PropTypes.bool,
    disableClear: PropTypes.bool,
    disabled: PropTypes.bool,
    onClear: PropTypes.func,
    onClick: PropTypes.func,
    InputProps: PropTypes.object,
    inputProps: PropTypes.object,
    sx: PropTypes.object,
    props: PropTypes.object,
};

DatePickerTextField.defaultProps = {
    onClick: (e) => {
    },
};

export default DatePickerTextField;
