"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VendorTypeService = exports.VendorSegmentService = exports.VendorCountryService = exports.VendorService = exports.UserService = exports.TryThisService = exports.SwitchingCostService = exports.SupplyControlService = exports.SpendGroupService = exports.SiteService = exports.QueryService = exports.ProjectService = exports.ProcurementService = exports.OwnerService = exports.MaterialGroupService = exports.MaterialService = exports.IntraGroupService = exports.HealthCheckService = exports.FixedCostService = exports.DiscretionaryCostService = exports.DirectCostService = exports.DimensionService = exports.DepartmentService = exports.DemandFragmentationService = exports.DataSourceService = exports.CountryService = exports.ConfigurationService = exports.CompanyService = exports.ClientCountryService = exports.ClientService = exports.CategoryService = exports.BusinessUnitService = exports.AuthenticationService = exports.AnalysisService = exports.AccountingTreatmentService = exports.UserType = exports.ProcurementProjectStep = exports.ProcurementInitiativeTopic = exports.ProcurementInitiativeSupport = exports.ProcurementInitiativeSlope = exports.ProcurementInitiativeSimplicity = exports.ProcurementInitiativeLever = exports.Dimension = exports.CustomParameterType = exports.CustomizationType = exports.AnalysisLabelTypes = exports.OpenAPI = exports.CancelError = exports.CancelablePromise = exports.ApiError = void 0;
exports.VersionService = void 0;
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
var ApiError_1 = require("./core/ApiError");
Object.defineProperty(exports, "ApiError", { enumerable: true, get: function () { return ApiError_1.ApiError; } });
var CancelablePromise_1 = require("./core/CancelablePromise");
Object.defineProperty(exports, "CancelablePromise", { enumerable: true, get: function () { return CancelablePromise_1.CancelablePromise; } });
Object.defineProperty(exports, "CancelError", { enumerable: true, get: function () { return CancelablePromise_1.CancelError; } });
var OpenAPI_1 = require("./core/OpenAPI");
Object.defineProperty(exports, "OpenAPI", { enumerable: true, get: function () { return OpenAPI_1.OpenAPI; } });
var AnalysisLabelTypes_1 = require("./models/AnalysisLabelTypes");
Object.defineProperty(exports, "AnalysisLabelTypes", { enumerable: true, get: function () { return AnalysisLabelTypes_1.AnalysisLabelTypes; } });
var CustomizationType_1 = require("./models/CustomizationType");
Object.defineProperty(exports, "CustomizationType", { enumerable: true, get: function () { return CustomizationType_1.CustomizationType; } });
var CustomParameterType_1 = require("./models/CustomParameterType");
Object.defineProperty(exports, "CustomParameterType", { enumerable: true, get: function () { return CustomParameterType_1.CustomParameterType; } });
var Dimension_1 = require("./models/Dimension");
Object.defineProperty(exports, "Dimension", { enumerable: true, get: function () { return Dimension_1.Dimension; } });
var ProcurementInitiativeLever_1 = require("./models/ProcurementInitiativeLever");
Object.defineProperty(exports, "ProcurementInitiativeLever", { enumerable: true, get: function () { return ProcurementInitiativeLever_1.ProcurementInitiativeLever; } });
var ProcurementInitiativeSimplicity_1 = require("./models/ProcurementInitiativeSimplicity");
Object.defineProperty(exports, "ProcurementInitiativeSimplicity", { enumerable: true, get: function () { return ProcurementInitiativeSimplicity_1.ProcurementInitiativeSimplicity; } });
var ProcurementInitiativeSlope_1 = require("./models/ProcurementInitiativeSlope");
Object.defineProperty(exports, "ProcurementInitiativeSlope", { enumerable: true, get: function () { return ProcurementInitiativeSlope_1.ProcurementInitiativeSlope; } });
var ProcurementInitiativeSupport_1 = require("./models/ProcurementInitiativeSupport");
Object.defineProperty(exports, "ProcurementInitiativeSupport", { enumerable: true, get: function () { return ProcurementInitiativeSupport_1.ProcurementInitiativeSupport; } });
var ProcurementInitiativeTopic_1 = require("./models/ProcurementInitiativeTopic");
Object.defineProperty(exports, "ProcurementInitiativeTopic", { enumerable: true, get: function () { return ProcurementInitiativeTopic_1.ProcurementInitiativeTopic; } });
var ProcurementProjectStep_1 = require("./models/ProcurementProjectStep");
Object.defineProperty(exports, "ProcurementProjectStep", { enumerable: true, get: function () { return ProcurementProjectStep_1.ProcurementProjectStep; } });
var UserType_1 = require("./models/UserType");
Object.defineProperty(exports, "UserType", { enumerable: true, get: function () { return UserType_1.UserType; } });
var AccountingTreatmentService_1 = require("./services/AccountingTreatmentService");
Object.defineProperty(exports, "AccountingTreatmentService", { enumerable: true, get: function () { return AccountingTreatmentService_1.AccountingTreatmentService; } });
var AnalysisService_1 = require("./services/AnalysisService");
Object.defineProperty(exports, "AnalysisService", { enumerable: true, get: function () { return AnalysisService_1.AnalysisService; } });
var AuthenticationService_1 = require("./services/AuthenticationService");
Object.defineProperty(exports, "AuthenticationService", { enumerable: true, get: function () { return AuthenticationService_1.AuthenticationService; } });
var BusinessUnitService_1 = require("./services/BusinessUnitService");
Object.defineProperty(exports, "BusinessUnitService", { enumerable: true, get: function () { return BusinessUnitService_1.BusinessUnitService; } });
var CategoryService_1 = require("./services/CategoryService");
Object.defineProperty(exports, "CategoryService", { enumerable: true, get: function () { return CategoryService_1.CategoryService; } });
var ClientService_1 = require("./services/ClientService");
Object.defineProperty(exports, "ClientService", { enumerable: true, get: function () { return ClientService_1.ClientService; } });
var ClientCountryService_1 = require("./services/ClientCountryService");
Object.defineProperty(exports, "ClientCountryService", { enumerable: true, get: function () { return ClientCountryService_1.ClientCountryService; } });
var CompanyService_1 = require("./services/CompanyService");
Object.defineProperty(exports, "CompanyService", { enumerable: true, get: function () { return CompanyService_1.CompanyService; } });
var ConfigurationService_1 = require("./services/ConfigurationService");
Object.defineProperty(exports, "ConfigurationService", { enumerable: true, get: function () { return ConfigurationService_1.ConfigurationService; } });
var CountryService_1 = require("./services/CountryService");
Object.defineProperty(exports, "CountryService", { enumerable: true, get: function () { return CountryService_1.CountryService; } });
var DataSourceService_1 = require("./services/DataSourceService");
Object.defineProperty(exports, "DataSourceService", { enumerable: true, get: function () { return DataSourceService_1.DataSourceService; } });
var DemandFragmentationService_1 = require("./services/DemandFragmentationService");
Object.defineProperty(exports, "DemandFragmentationService", { enumerable: true, get: function () { return DemandFragmentationService_1.DemandFragmentationService; } });
var DepartmentService_1 = require("./services/DepartmentService");
Object.defineProperty(exports, "DepartmentService", { enumerable: true, get: function () { return DepartmentService_1.DepartmentService; } });
var DimensionService_1 = require("./services/DimensionService");
Object.defineProperty(exports, "DimensionService", { enumerable: true, get: function () { return DimensionService_1.DimensionService; } });
var DirectCostService_1 = require("./services/DirectCostService");
Object.defineProperty(exports, "DirectCostService", { enumerable: true, get: function () { return DirectCostService_1.DirectCostService; } });
var DiscretionaryCostService_1 = require("./services/DiscretionaryCostService");
Object.defineProperty(exports, "DiscretionaryCostService", { enumerable: true, get: function () { return DiscretionaryCostService_1.DiscretionaryCostService; } });
var FixedCostService_1 = require("./services/FixedCostService");
Object.defineProperty(exports, "FixedCostService", { enumerable: true, get: function () { return FixedCostService_1.FixedCostService; } });
var HealthCheckService_1 = require("./services/HealthCheckService");
Object.defineProperty(exports, "HealthCheckService", { enumerable: true, get: function () { return HealthCheckService_1.HealthCheckService; } });
var IntraGroupService_1 = require("./services/IntraGroupService");
Object.defineProperty(exports, "IntraGroupService", { enumerable: true, get: function () { return IntraGroupService_1.IntraGroupService; } });
var MaterialService_1 = require("./services/MaterialService");
Object.defineProperty(exports, "MaterialService", { enumerable: true, get: function () { return MaterialService_1.MaterialService; } });
var MaterialGroupService_1 = require("./services/MaterialGroupService");
Object.defineProperty(exports, "MaterialGroupService", { enumerable: true, get: function () { return MaterialGroupService_1.MaterialGroupService; } });
var OwnerService_1 = require("./services/OwnerService");
Object.defineProperty(exports, "OwnerService", { enumerable: true, get: function () { return OwnerService_1.OwnerService; } });
var ProcurementService_1 = require("./services/ProcurementService");
Object.defineProperty(exports, "ProcurementService", { enumerable: true, get: function () { return ProcurementService_1.ProcurementService; } });
var ProjectService_1 = require("./services/ProjectService");
Object.defineProperty(exports, "ProjectService", { enumerable: true, get: function () { return ProjectService_1.ProjectService; } });
var QueryService_1 = require("./services/QueryService");
Object.defineProperty(exports, "QueryService", { enumerable: true, get: function () { return QueryService_1.QueryService; } });
var SiteService_1 = require("./services/SiteService");
Object.defineProperty(exports, "SiteService", { enumerable: true, get: function () { return SiteService_1.SiteService; } });
var SpendGroupService_1 = require("./services/SpendGroupService");
Object.defineProperty(exports, "SpendGroupService", { enumerable: true, get: function () { return SpendGroupService_1.SpendGroupService; } });
var SupplyControlService_1 = require("./services/SupplyControlService");
Object.defineProperty(exports, "SupplyControlService", { enumerable: true, get: function () { return SupplyControlService_1.SupplyControlService; } });
var SwitchingCostService_1 = require("./services/SwitchingCostService");
Object.defineProperty(exports, "SwitchingCostService", { enumerable: true, get: function () { return SwitchingCostService_1.SwitchingCostService; } });
var TryThisService_1 = require("./services/TryThisService");
Object.defineProperty(exports, "TryThisService", { enumerable: true, get: function () { return TryThisService_1.TryThisService; } });
var UserService_1 = require("./services/UserService");
Object.defineProperty(exports, "UserService", { enumerable: true, get: function () { return UserService_1.UserService; } });
var VendorService_1 = require("./services/VendorService");
Object.defineProperty(exports, "VendorService", { enumerable: true, get: function () { return VendorService_1.VendorService; } });
var VendorCountryService_1 = require("./services/VendorCountryService");
Object.defineProperty(exports, "VendorCountryService", { enumerable: true, get: function () { return VendorCountryService_1.VendorCountryService; } });
var VendorSegmentService_1 = require("./services/VendorSegmentService");
Object.defineProperty(exports, "VendorSegmentService", { enumerable: true, get: function () { return VendorSegmentService_1.VendorSegmentService; } });
var VendorTypeService_1 = require("./services/VendorTypeService");
Object.defineProperty(exports, "VendorTypeService", { enumerable: true, get: function () { return VendorTypeService_1.VendorTypeService; } });
var VersionService_1 = require("./services/VersionService");
Object.defineProperty(exports, "VersionService", { enumerable: true, get: function () { return VersionService_1.VersionService; } });
