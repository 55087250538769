import React, {useContext, useEffect, useState} from 'react';
import {Autocomplete, debounce, TextField} from '@mui/material';
import {isEqual} from 'lodash';
import PropTypes from 'prop-types';

import {AppContext} from '../../App';
import CircularLoading from '../loading/CircularLoading';

const AutoCompleteBox = ({
    fullWidth,
    disabled,
    label,
    multiple,
    disableCloseOnSelect,
    value,
    size,
    startAdornment,
    metadata,
    onChange,
    onQuery,
    updateOnSelect,
    sx,
}) => {
    const {notify} = useContext(AppContext);
    const [loading, setLoading] = useState(false);
    const [shouldFetchMorePages, setShouldFetchMorePages] = useState(true);
    const [query, setQuery] = useState();
    const [options, setOptions] = useState();
    const [page, setPage] = useState(0);
    const [internalValue, setInternalValue] = useState(value);
    const PAGE_LIMIT = 20; // Set by API DEFAULT Parameter (see services and limit)

    const issueQuery = (query) => {
        setLoading(true);
        setShouldFetchMorePages(true);
        setPage(0);
        onQuery(query)
            .then((data) => {
                setOptions(data.response);
                setLoading(false);
            })
            .catch((error) => {
                setOptions(null);
                setLoading(false);
                notify.error(error, 'autocomplete.fetch', 'Error querying.');
            });
    };

    const issueQueryDebounced = debounce(issueQuery, 700);

    useEffect(() => {
        if (value) {
            setInternalValue(value);
        }
    }, [value]);

    // this works because AutoComplete component is not controlled
    // (it does not uses the query value as input)

    const fetchNewPage = () => {
        if (shouldFetchMorePages) {
            onQuery(query, page + 1)
                .then((data) => {
                    setLoading(false);
                    if (data.response.length < PAGE_LIMIT) {
                        setShouldFetchMorePages(false);
                    }
                    // only change state if there's more items.
                    setOptions([...options, ...data.response]);
                    setPage(page + 1);
                })
                .catch((error) => {
                    setLoading(false);
                    notify.error(error, 'autocomplete.fetch', 'Error querying.');
                });
        }
    };

    return (
        <Autocomplete
            datacy={`${label}_autocomplete`}
            openOnFocus
            multiple={multiple}
            disableCloseOnSelect={disableCloseOnSelect}
            disabled={disabled}
            options={options || []}
            getOptionLabel={(option) =>
                metadata[option]?.short_name || option.short_name || option.name || option.id || option}
            loading={loading}
            renderOption={(props, option) => {
                return (
                    <li {...props} key={option.id}>
                        {metadata[option]?.short_name || option.short_name || option.name || option.id || option}
                    </li>
                );
            }}
            // FIXME 1028: We're mixing option objects with IDs, with metadata. This results in a large number of bugs.
            // FIXME 1028: This is a very bad code smell, because the Component API isn't well defined.
            isOptionEqualToValue={(option, v) => option.id === (v?.id || v)}
            filterOptions={(x) => x}
            renderInput={(params) => {
                const startAdornments = [startAdornment];
                // Chips in multiple selects are set as startAdornment.
                // Since we allow the customization of startAdornment for icons, we need to join the icon with the chips.
                if (params.InputProps.startAdornment) {
                    startAdornments.push(...params.InputProps.startAdornment);
                }

                return (
                    <TextField
                        {...params}
                        label={label}
                        size={size}
                        InputLabelProps={{shrink: true}}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: startAdornments,
                            endAdornment: (
                                <>
                                    {loading ? <CircularLoading size={25} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                );
            }}
            onInputChange={(ev, v) => {
                // only issue a query if the user changes manually the input.
                if (ev?.type === 'change') {
                    issueQueryDebounced(v);
                    setQuery(v);
                }
            }}
            // issue a query when opening the text box.
            onOpen={(ev) => issueQuery()}
            onChange={(ev, value, reason) => {
                // make sure we propagate clear event values.
                if (reason === 'clear' || updateOnSelect) {
                    onChange(value);
                }
                setInternalValue(value);
            }}
            onClose={() => {
                if (!isEqual(value, internalValue)) {
                    onChange(internalValue);
                }
            }}
            // fetches new pages when using the scroll.
            ListboxProps={{
                onScroll: (event) => {
                    const listboxNode = event.currentTarget;
                    // if we're reaching the last item, fetch more.
                    if (listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight - 100) {
                        fetchNewPage();
                    }
                },
            }}
            // fetches new pages when using the keyboard.
            onHighlightChange={(event, option, reason) => {
                if (options?.length) {
                    const optionsToTriggerFetch = options.slice(-5).map((el) => el.id);
                    if (optionsToTriggerFetch.includes(option?.id)) {
                        fetchNewPage();
                    }
                }
            }}
            value={internalValue}
            limitTags={2}
            ChipProps={{
                size: 'small',
                onDelete: null,
                sx: {
                    '.MuiChip-label': {
                        maxWidth: 90,
                    },
                },
            }}
            fullWidth={fullWidth}
            sx={sx}
        />
    );
};

AutoCompleteBox.propTypes = {
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    multiple: PropTypes.bool,
    disableCloseOnSelect: PropTypes.bool,
    value: PropTypes.any,
    size: PropTypes.string,
    startAdornment: PropTypes.any,
    metadata: PropTypes.object,
    onChange: PropTypes.func,
    onQuery: PropTypes.func,
    sx: PropTypes.object,
    updateOnSelect: PropTypes.bool,
};

AutoCompleteBox.defaultProps = {
    fullWidth: false,
    disabled: false,
    multiple: false,
    disableCloseOnSelect: false,
    metadata: {},
    size: 'small',
    updateOnSelect: false,
};

export default AutoCompleteBox;
