import {getAxisHV, getBase, getPointSeries, getPointTooltip} from './common';

export const getChartOptions = (title, data, config, baseFontSize) => {
    return {
        ...getBase(title, baseFontSize),
        ...getAxisHV(data, config, baseFontSize),
        ...getPointTooltip(data, config, baseFontSize),
        series: getPointSeries(data).map((series) =>
            ({
                type: 'scatter',
                data: series,
                symbolSize: baseFontSize / 3.0,
                clip: false,
            })),
    };
};
