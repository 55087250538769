import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import OktaSignIn from '@okta/okta-signin-widget';
import {useOktaAuth} from '@okta/okta-react';

const OktaWidget = ({onSuccess, onError}) => {
    const widgetRef = useRef();
    const {oktaAuth} = useOktaAuth();

    const widgetConfig = {
        issuer: oktaAuth.options.issuer,
        clientId: oktaAuth.options.clientId,
        scopes: oktaAuth.options.scopes,
        redirectUri: `${window.location.origin}/login/callback`,
        useInteractionCodeFlow: true,
        logo: 'logo512.png',
    };

    useEffect(() => {
        if (!widgetRef.current) {
            return false;
        }

        const widget = new OktaSignIn(widgetConfig);

        widget.showSignInToGetTokens({
            el: widgetRef.current,
        }).then(onSuccess).catch(onError);

        return () => widget.remove();
    }, [onSuccess, onError]);

    return (
        <div ref={widgetRef} />
    );
};

OktaWidget.propTypes = {
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
};

export default OktaWidget;
