import React, {useContext} from 'react';
import SelectField from './SelectField';
import EditableTextField from './EditableTextField';
import {formatCompactAmount, formatRatioToPercentage} from '../../util/formatter';
import {AppContext} from '../../App';
import {hasValue} from '../../util/util';
import PropTypes from 'prop-types';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import DatePickerTextField from './DatePickerTextField';
import dayjs from 'dayjs';

const ValueField = ({type, onChange, ...params}) => {
    const {config} = useContext(AppContext);

    switch (type) {
        case 'SELECT':
            return <SelectField onChange={onChange} {...params} />;
        case 'PERCENTAGE':
            return (
                <EditableTextField
                    type='number'
                    formatter={(value) => formatRatioToPercentage(value, config.locale)}
                    initialEditFormatter={(value) => formatRatioToPercentage(value, config.locale, false)}
                    onChange={(value) => onChange(hasValue(value) ? value / 100 : null)}
                    {...params}
                />
            );
        case 'AMOUNT':
            return (
                <EditableTextField
                    type='number'
                    formatter={(value) => formatCompactAmount(value, config.locale)}
                    onChange={onChange}
                    {...params}
                />
            );
        case 'NUMBER':
            return <EditableTextField type='number' onChange={onChange} {...params} />;
        case 'DATE':
            if (params.value) {
                params.value = dayjs(params.value);
            }

            return (
                <DatePicker
                    {...params}
                    onChange={onChange}
                    autoComplete='off'
                    slotProps={{textField: {size: 'small'}}}
                    renderInput={(params) =>
                        (
                            <DatePickerTextField
                                {...params}
                                disableClear
                                sx={{m: 0.5}}
                            />
                        )}
                />
            );
        default:
            return <EditableTextField type='text' onChange={onChange} {...params} />;
    }
};

ValueField.propTypes = {
    type: PropTypes.string,
    onChange: PropTypes.func,
    params: PropTypes.object,
};

export default ValueField;
