"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProcurementProjectStep = void 0;
/**
 * An enumeration.
 */
var ProcurementProjectStep;
(function (ProcurementProjectStep) {
    ProcurementProjectStep["START"] = "START";
    ProcurementProjectStep["PRIORITIZE"] = "PRIORITIZE";
    ProcurementProjectStep["SCHEDULE"] = "SCHEDULE";
})(ProcurementProjectStep = exports.ProcurementProjectStep || (exports.ProcurementProjectStep = {}));
