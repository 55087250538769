import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import {Box, ClickAwayListener, IconButton, InputAdornment, TextField} from '@mui/material';
import PropTypes from 'prop-types';
import {useEffect, useRef, useState} from 'react';
import ReadOnlyTextField from './ReadOnlyTextField';
import {hasValue} from '../../util/util';

const EditableTextField = ({
    value,
    adornment,
    type,
    size,
    label,
    min,
    max,
    step,
    disableAnimation,
    disableOutline,
    onChange,
    onInvalid,
    formatter,
    initialEditFormatter,
    validator,
    sx,
}) => {
    const [edit, setEdit] = useState(false);
    const [invalid, setInvalid] = useState(false);
    const [currentValue, setCurrentValue] = useState(initialEditFormatter(value));
    const textInput = useRef(null);

    // set focus on edition.
    useEffect(() => {
        if (edit) {
            textInput.current.focus();
        }
    }, [edit]);

    return (
        <>
            {
                edit ?
                    <ClickAwayListener onClickAway={() => setEdit(false)}>
                        <TextField
                            fullWidth
                            variant={disableOutline ? 'standard' : 'outlined'}
                            size={size}
                            inputRef={textInput}
                            value={currentValue}
                            type={type}
                            label={label}
                            error={invalid}
                            InputProps={{
                                ...(disableOutline && {disableUnderline: true}),
                                inputProps: {
                                    style: {textAlign: 'center'},
                                    min: min,
                                    max: max,
                                    step: step,
                                },
                                startAdornment: <InputAdornment position='start'>{adornment}</InputAdornment>,
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <IconButton
                                            edge='end'
                                            color='primary'
                                            size='small'
                                            onClick={() => {
                                                if (validator(currentValue)) {
                                                    onChange(currentValue);
                                                    setInvalid(false);
                                                    setEdit(false);
                                                } else {
                                                    setInvalid(true);
                                                    onInvalid(currentValue);
                                                }
                                            }}
                                        >
                                            <CheckIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(e) => setCurrentValue(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    if (validator(currentValue)) {
                                        onChange(currentValue);
                                        setInvalid(false);
                                        setEdit(false);
                                    } else {
                                        setInvalid(true);
                                        onInvalid(currentValue);
                                    }

                                    e.preventDefault();
                                } else if (e.key === 'Escape') {
                                    setEdit(false);
                                    e.preventDefault();
                                }
                            }}
                            sx={sx}
                        />
                    </ClickAwayListener> :

                    <ReadOnlyTextField
                        value={hasValue(value) ? formatter(value) : ''}
                        size={size}
                        label={label}
                        disableOutline={disableOutline}
                        disableAnimation={disableAnimation}
                        onClick={() => setEdit(true)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <IconButton
                                        edge='end'
                                        color='primary'
                                        size='small'
                                        onClick={() => setEdit(true)}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <Box sx={{width: 30}} />
                                </InputAdornment>
                            ),
                        }}
                        sx={{
                            ...sx,
                            '.MuiOutlinedInput-input': {
                                'cursor': 'pointer',
                            },
                        }}
                    />
            }
        </>
    );
};

EditableTextField.propTypes = {
    value: PropTypes.any,
    adornment: PropTypes.string,
    type: PropTypes.string,
    size: PropTypes.string,
    label: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    disableAnimation: PropTypes.bool,
    disableOutline: PropTypes.bool,
    onChange: PropTypes.func,
    onInvalid: PropTypes.func,
    formatter: PropTypes.func,
    initialEditFormatter: PropTypes.func,
    validator: PropTypes.func,
    sx: PropTypes.object,
};

EditableTextField.defaultProps = {
    size: 'small',
    onChange: () => {},
    onInvalid: () => {},
    formatter: (v) => v,
    initialEditFormatter: (v) => v,
    validator: (v) => true,
    sx: {},
};

export default EditableTextField;
