import {getAxisTooltip, getAxisV, getYSeriesData} from './common';
import {defaultValueLabelSetting, getBase, getFormattedValue} from '../common';
import {getChartOptions as getLineChartOptions} from '../line';

export function getChartOptions(title, data, config, baseFontSize) {
    // If x type is not date, then we return a line chart, instead of timeline.
    if (data.label_type.x !== 'DATE') {
        return getLineChartOptions(title, data, config, baseFontSize);
    }

    return {
        ...getBase(title, baseFontSize),
        ...getAxisV(data, config, baseFontSize),
        ...getAxisTooltip(data, config, baseFontSize),
        series: getYSeriesData(data).map((series) => ({
            type: 'line',
            data: series,
            label:
                defaultValueLabelSetting(
                    'top',
                    baseFontSize,
                    config,
                    (value) => {
                        const yVal = value.data.value[1]; // value[0] is x-value (the timestamp), value[1] the y-value
                        return getFormattedValue(yVal, 'y', data, config);
                    }),
        })),
    };
}
