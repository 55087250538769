import PropTypes from 'prop-types';
import React, {useContext} from 'react';

import {AppContext} from '../../App';
import AutoCompleteBox from './AutoCompleteBox';

const DimensionAutoCompleteBox = ({dimension, onChange, onQuery, values, disabled, fullWidth, metadata, sx}) => {
    const {config} = useContext(AppContext);

    return (
        <AutoCompleteBox
            multiple
            disableCloseOnSelect
            disabled={disabled}
            fullWidth={fullWidth}
            label={config.i18n.dimension[dimension]}
            onChange={onChange}
            onQuery={onQuery}
            value={values}
            metadata={metadata}
            sx={sx}
        />
    );
};

DimensionAutoCompleteBox.propTypes = {
    dimension: PropTypes.string,
    onChange: PropTypes.func,
    onQuery: PropTypes.func,
    values: PropTypes.any,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    metadata: PropTypes.object,
    sx: PropTypes.object,
};

export default DimensionAutoCompleteBox;
