"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CountryService = void 0;
var OpenAPI_1 = require("../core/OpenAPI");
var request_1 = require("../core/request");
var CountryService = /** @class */ (function () {
    function CountryService() {
    }
    /**
     * Search
     * @param q
     * @param page
     * @param limit
     * @param requestBody
     * @returns PaginatedResponse_DimensionModel_ The paginated searched countries.
     * @throws ApiError
     */
    CountryService.countrySearch = function (q, page, limit, requestBody) {
        if (q === void 0) { q = ''; }
        if (limit === void 0) { limit = 20; }
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/country',
            query: {
                'q': q,
                'page': page,
                'limit': limit,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: "Validation Error",
            },
        });
    };
    return CountryService;
}());
exports.CountryService = CountryService;
