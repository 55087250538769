import {IconButton, Tooltip} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import {useContext} from 'react';
import {AppContext} from '../../App';
import PropTypes from 'prop-types';

const ClearIconButton = ({size, ...params}) => {
    const {config} = useContext(AppContext);

    return (
        <Tooltip title={config.i18n.button.clear}>
            <IconButton
                size={size}
                {...params}
            >
                <ClearIcon />
            </IconButton>
        </Tooltip>
    );
};

ClearIconButton.propTypes = {
    size: PropTypes.string,
    props: PropTypes.object,
};

ClearIconButton.defaultProps = {
    size: 'small',
};

export default ClearIconButton;
