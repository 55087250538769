import {grey} from '@mui/material/colors';
import {Box, Typography} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';


const DimensionBox = ({title, children, sx, internalSx, datacy}) => {
    return (
        <Box
            datacy={datacy}
            sx={{
                display: 'flex', flexDirection: 'column', flexWrap: 'wrap',
                borderRadius: 1, border: `1px solid ${grey[400]}`,
                ...sx,
            }}
        >

            <Typography variant='caption' align='center' sx={{mt: 1, mb: 0.5, color: 'text.secondary'}}>
                {title}
            </Typography>

            <Box sx={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', mb: 1, ...internalSx}}>
                {children}
            </Box>
        </Box>
    );
};

DimensionBox.propTypes = {
    title: PropTypes.string,
    children: PropTypes.any,
    sx: PropTypes.object,
    internalSx: PropTypes.object,
    datacy: PropTypes.string,
};

export default DimensionBox;
