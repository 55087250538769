import {formatCompact, formatCompactInteger, formatTemplate, formatValue, getCompactFormatter} from '../../util/formatter';

export const defaultFontColor = '#6e7079';

const getXData = (data, config) => {
    return data.result.map((el) => {
        const value = el.label;
        // if there is a template, apply it
        if (data.metadata[value]?.template) {
            return formatTemplate(data.metadata[value]?.template, {}, config.locale, config.i18n);
        }

        // lookup short name, name, i18n key or default to the value itself
        return getAxisLabel(value, data, config);
    });
};

export const getBase = (title, baseFontSize) => {
    return {
        title: {
            text: title || '',
            left: 'center',
            textStyle: {
                fontSize: 0.5 * baseFontSize,
                fontWeight: 'normal',
            },
        },
        textStyle: {
            fontFamily: 'Roboto',
            color: defaultFontColor,
        },
    };
};

export const getAxisV = (data, config, baseFontSize, extraColumns) => {
    return {
        xAxis: {
            data: getXData(data, config),
            name: data.labels?.x || '',
            nameLocation: 'middle',
            nameGap: 1.5 * baseFontSize,
            nameTextStyle: {
                fontSize: 0.5 * baseFontSize,
            },
            type: 'category',
            axisLabel: {
                interval: 0, // TODO dynamic interval
                rotate: 0,
                width: baseFontSize * (15 / (data.result.length + (extraColumns || 0))),
                height: 2 * 0.35 * baseFontSize,
                margin: 0.25 * baseFontSize,
                overflow: 'breakAll',
                lineOverflow: 'truncate', // TODO this currently requires an echarts patch
                fontSize: defaultLabelFontSize(baseFontSize),
                lineHeight: 0.35 * baseFontSize,
            },
        },
        yAxis: {
            name: data.labels?.y || '',
            nameLocation: 'middle',
            nameGap: 2 * baseFontSize,
            nameTextStyle: {
                fontSize: 0.5 * baseFontSize,
            },
            type: 'value',
            axisLabel: {
                fontSize: defaultLabelFontSize(baseFontSize),
                formatter: getCompactFormatter(
                    data.result.map((el) => el.y),
                    config.locale,
                    config.i18n,
                    data.label_type['y'] === 'PERCENTAGE',
                    formatCompact),
                rich: {
                    min: {
                        color: '#dc004e',
                        borderWidth: 1,
                        borderColor: '#dc004e',
                        borderRadius: 5,
                        borderType: 'dotted',
                        padding: 5,
                        fontSize: 0.38 * baseFontSize,
                    },
                },
            },
        },
        grid: {
            top: 1.5 * baseFontSize,
            bottom: 2.0 * baseFontSize,
            left: 2.6 * baseFontSize,
            right: 1 * baseFontSize,
        },
    };
};

export const getAxisH = (data, config, baseFontSize) => {
    return {
        xAxis: {
            name: data.labels?.y || '',
            nameLocation: 'middle',
            nameGap: 1.2 * baseFontSize,
            nameTextStyle: {
                fontSize: 0.5 * baseFontSize,
            },
            type: 'value',
            axisLabel: {
                fontSize: defaultLabelFontSize(baseFontSize),
                formatter: getCompactFormatter(
                    data.result.map((el) => el.y),
                    config.locale,
                    config.i18n,
                    data.label_type['y'] === 'PERCENTAGE',
                    formatCompact),
            },
        },
        yAxis: {
            data: getXData(data, config),
            name: data.labels?.x || '',
            nameLocation: 'middle',
            nameGap: 3.2 * baseFontSize,
            nameTextStyle: {
                fontSize: 0.5 * baseFontSize,
            },
            type: 'category',
            inverse: true,
            axisLabel: {
                interval: 0, // TODO dynamic interval
                width: 2.5 * baseFontSize,
                overflow: 'truncate',
                ellipsis: '',
                fontSize: defaultLabelFontSize(baseFontSize),
            },
        },
        grid: {
            top: 1.5 * baseFontSize,
            bottom: 1.7 * baseFontSize,
            left: 3.9 * baseFontSize,
            right: 1.5 * baseFontSize,
        },
    };
};

export const getAxisHV = (data, config, baseFontSize, extraColumns) => {
    return {
        xAxis: {
            data: data.result.map((el) => el.x),
            name: data.labels?.x || '',
            nameLocation: 'middle',
            nameGap: 1.2 * baseFontSize,
            nameTextStyle: {
                fontSize: 0.5 * baseFontSize,
            },
            type: 'value',
            axisLabel: {
                fontSize: defaultLabelFontSize(baseFontSize),
                formatter: getCompactFormatter(
                    data.result.map((el) => el.x),
                    config.locale,
                    config.i18n,
                    data.label_type['x'] === 'PERCENTAGE',
                    formatCompact),
            },
        },
        yAxis: {
            name: data.labels?.y || '',
            nameLocation: 'middle',
            nameGap: 2 * baseFontSize,
            nameTextStyle: {
                fontSize: 0.5 * baseFontSize,
            },
            type: 'value',
            axisLabel: {
                fontSize: defaultLabelFontSize(baseFontSize),
                formatter: getCompactFormatter(
                    data.result.map((el) => el.y),
                    config.locale,
                    config.i18n,
                    data.label_type['y'] === 'PERCENTAGE',
                    formatCompact),
            },
        },
        grid: {
            top: 1.5 * baseFontSize,
            bottom: 2.0 * baseFontSize,
            left: 2.6 * baseFontSize,
            right: 1 * baseFontSize,
        },
    };
};

export const getTooltip = (data, config, baseFontSize, extraOptions) => {
    return {
        tooltip: {
            confine: true,
            textStyle: {
                fontSize: 0.4 * baseFontSize,
            },
            ...extraOptions,
        },
    };
};

export const getBaseTooltip = (data, config, baseFontSize, extraOptions) => {
    return {
        ...getTooltip(data, config, baseFontSize, {
            valueFormatter: getFormatter(data, 'y', config, false), // not compacted
            ...extraOptions,
        }),
    };
};

export const getAxisTooltip = (data, config, baseFontSize, extraOptions = {}) => {
    return {
        ...getBaseTooltip(data, config, baseFontSize, {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
            },
            formatter: (params) => {
                if (params.length === 2 && params[0].seriesName === 'transparent') {
                    // Waterfall with a hidden series' tooltip
                    const name = formatTooltipValue(data, 'x', getTooltipLabel(params[1].data.id, data, config), config);
                    const value = params[1].data.positive ? params[1].value : -params[1].value;

                    return `${name} <br/> ${params[1].marker}${formatTooltipValue(data, 'y', value, config)}`;
                } else {
                    const title = formatTooltipValue(data, 'x', getTooltipLabel(params[0].data.id, data, config), config);

                    return getAxisTooltipFormatter(data, config, title, (value) => value.value)(params);
                }
            },
            ...extraOptions,
        }),
    };
};

export function getAxisTooltipFormatter(data, config, title, valueFetcher) {
    return (params) => {
        let returnVal = title;
        if (params.length === 1) {
            // Single series' tooltip
            returnVal += ` <br/> ${params[0].marker}`;

            // if there isn't value, format it with the special label of EPSILON - NA.
            // this is useful on line charts, with null values.
            let value = valueFetcher(params[0]);
            if (value === null) {
                value = Number.EPSILON;
            }

            returnVal += formatTooltipValue(data, 'y', value, config);
        } else {
            params.forEach((values, index) => {
                const dimIndex = params.indexOf(values);
                const id = data.series_ids[index];
                const name = getTooltipLabel(id, data, config);
                if (data.result[values.dataIndex].y[dimIndex] != null) {
                    returnVal += `<br/> ${values.marker} ${name} : `;
                    returnVal += formatTooltipValue(data, 'y', valueFetcher(values), config);
                }
            });
        }
        return returnVal;
    };
}


export const getItemTooltip = (data, config, baseFontSize) => {
    return {
        ...getBaseTooltip(data, config, baseFontSize, {
            trigger: 'item',
        }),
    };
};

export const getPointTooltip = (data, config, baseFontSize) => {
    const formatSeries = (value, label, axis) => {
        if (value !== null && value !== undefined) {
            const formattedValue = formatTooltipValue(data, axis, value, config);
            if (label) {
                return `${label}: ${formattedValue}`;
            }
        }

        return null;
    };

    return {
        ...getTooltip(data, config, baseFontSize, {
            trigger: 'item',
            formatter: (params, ticket, callback) => {
                const label = params.data.id;
                const x = params.value[0];
                const y = params.value[1];
                const z = params.value[2];

                // lookup name, short name, i18n key or default to the value itself
                const labelText = getTooltipLabel(label, data, config);

                const tooltipParts = [
                    labelText && `<b>${labelText}</b>`,
                    formatSeries(x, data.labels?.x, 'x'),
                    formatSeries(y, data.labels?.y, 'y'),
                    formatSeries(z, data.labels?.z, 'z'),
                ];

                return tooltipParts.filter((part) => part).join('</br>');
            },
        }),
    };
};

export const getPieChartData = (data, config) => {
    const ySeries = [...Array(data.result[0].y.length)].map(() => []);
    // [[...y1], [...y2], ...]
    data.result.forEach((result) => {
        for (let i = 0; i < result.y.length; i++) {
            ySeries[i].push({
                id: result.label,
                name: getAxisLabel(result.label, data, config),
                // HACK: Echarts requires a number, otherwise we can't model a null value.
                // We're using the smallest value for this.
                // On null value, we still want to show a data point, with a special label.
                value: result.y[i] !== null ? result.y[i] : Number.EPSILON,
            });
        }
    });

    return ySeries;
};

export const getYSeriesData = (data, idFormatter = (id, index) => id) => {
    // [] * number of series.
    const ySeries = [...Array(data.result[0].y.length)].map(() => []);
    // [[...y1], [...y2], ...]
    data.result.forEach((result, index) => {
        for (let i = 0; i < result.y.length; i++) {
            ySeries[i].push({
                id: idFormatter(result.label, index),
                name: idFormatter(result.label, index),
                // HACK: Echarts requires a number, otherwise we can't model a null value.
                // We're using the smallest value for this.
                // On null value, we still want to show a data point, with a special label.
                value: result.y[i] !== null ? result.y[i] : Number.EPSILON,
            });
        }
    });

    return ySeries;
};

export const getYLineSeriesData = (data) => {
    // [] * number of series.
    const ySeries = [...Array(data.result[0].y.length)].map(() => []);
    // [[...y1], [...y2], ...]
    data.result.forEach((result) => {
        for (let i = 0; i < result.y.length; i++) {
            ySeries[i].push({
                id: result.label,
                name: result.label,
                // In line, is Ok to pass null, so it can connectNulls.
                value: result.y[i],
            });
        }
    });

    return ySeries;
};

export const getPointSeries = (data) => {
    // [] * number of series.
    const ySeries = [...Array(data.result[0].y.length)].map(() => []);
    // [[...(y,z, label)], ...]
    data.result.forEach((result) => {
        for (let i = 0; i < result.y.length; i++) {
            ySeries[i].push({
                id: result.label,
                name: result.label,
                // HACK: Echarts requires a number, otherwise we can't model a null value.
                // We're using the smallest value for this.
                // On null value, we still want to show a data point, with a special label.
                value: [
                    result.x,
                    result.y[i] !== null ? result.y[i] : Number.EPSILON,
                    result.z[i] !== null ? result.z[i] : Number.EPSILON,
                ],
            });
        }
    });
    return ySeries;
};

export const getYSeries = (data, type, baseFontSize, config, extraOptions = {}) => {
    let posLabel = 'top';
    if ('isBar' in extraOptions) {
        // different placement in label for bar charts
        posLabel = 'right';
    }

    const ySeriesData = getYSeriesData(data);
    return {
        series: ySeriesData.map((series, index) => {
            return ({
                name: index < data?.series_ids.length ? data.series_ids[index] : null,
                type: type,
                stack: 'y',
                data: series,
                label: index < ySeriesData.length - 1 ? '' : defaultValueLabelSetting(posLabel, baseFontSize, config,
                    (value) => {
                        const id = value.data.id;
                        const sum = data.result.find((r) => r.label === id).y.reduce((a, b) => a + b, 0);
                        return formatCompactInteger(sum, config.locale);
                    }),
                ...extraOptions,
            });
        }),
    };
};

export const getLegend = (data, config, baseFontSize) =>
    ({
        width: '64%',
        legend: {
            selectedMode: false,
            orient: 'vertical',
            top: 'middle',
            left: '78%', // relative to container width. 78% is just enough next to the slide.
            icon: 'roundRect',
            itemWidth: 0.4 * baseFontSize,
            itemHeight: 0.4 * baseFontSize,
            textStyle: {
                fontSize: 0.38 * baseFontSize,
                color: defaultFontColor,
                overflow: 'truncate',
            },
            formatter: function(id) {
                return getAxisLabel(id, data, config);
            },
        },
    });

export const getAxisLabel = (id, data, config) => {
    if (id === null) {
        return config.i18n.chart.label['__null__'];
    }

    return data?.metadata[id]?.short_name ||
        data?.metadata[id]?.name ||
        config.i18n.chart.label[id] ||
        config.i18n.document[id] ||
        id;
};

export const getTooltipLabel = (id, data, config) => {
    if (id === null) {
        return config.i18n.chart.label['__null__'];
    }

    return data.metadata[id]?.name ||
        data.metadata[id]?.short_name ||
        config.i18n.chart.label[id] ||
        config.i18n.document[id] ||
        id;
};


export function defaultValueLabelSetting(
    position,
    baseFontSize,
    config,
    formatter,
) {
    return {
        show: true,
        position: position,
        fontSize: 0.3 * baseFontSize,
        formatter: formatter,
    };
}

// TODO delete function and refactor
export function formatTooltipValue(data, axis, value, config) {
    return getFormatter(data, axis, config, false)(value);
}

export function getFormatterFromLabelType(labelType, compact = false) {
    switch (labelType) {
        case 'INTEGER':
            return compact ? 'compactinteger' : 'integer';
        case 'DECIMAL':
            return compact ? 'compact' : 'decimal';
        case 'AMOUNT':
            return compact ? 'compactamount' : 'amount';
        case 'DATE':
            return 'date';
        case 'PERCENTAGE':
            return 'percentage';
        default:
            return null;
    }
}

export function getFormattedValue(val, label, data, config, compact=true) {
    return getFormatter(data, label, config, compact)(val);
}

export function getFormatter(data, label, config, compact = true) {
    const formatterType = getFormatterFromLabelType(data.label_type[label], compact);

    if (formatterType) {
        return (value) => formatValue(value, formatterType, config.locale, config.i18n);
    } else {
        return (value) => value;
    }
}

// Used in stacked waterfall, generally set automatically by echarts
export function getSeriesColor(index) {
    // Default colors of apache echarts
    const colors = ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'];
    return colors[index%colors.length];
}

export const overrideNegativeLabelPosition = (series, position) => {
    return series.map((entry) => {
        if (entry?.value < 0) {
            return {
                ...entry,
                label: {
                    position: position,
                },
            };
        } else {
            return entry;
        }
    });
};

export const defaultLabelFontSize = (baseFontSize) => {
    return 0.35 * baseFontSize;
};
