import {defaultValueLabelSetting, getAxisTooltip, getAxisV, getBase, getSeriesColor, getYSeriesData} from './common';
import {formatCompactInteger} from '../../util/formatter';

export const getChartOptions = (title, data, config, baseFontSize) => {
    const ySeriesData = getYSeriesData(data);
    const ySeriesDataWithTotals = ySeriesData.map((series) => [...series,
        {
            name: '__total__',
            value: series.reduce((sum, entry) => sum + entry.value, 0),
        }]);

    // the bottom stack is transparent (visual hack to seem a waterfall)
    let counter = 0;
    const transparentSeries = [[]];
    ySeriesData[0].forEach((entry, index) => {
        const item = counter;
        for (let i = 0; i < ySeriesData.length; i++) {
            counter += ySeriesData[i][index].value;
        }
        transparentSeries[0].push(item);
    });

    // add total to the end of the dataset
    const dataWithTotal = {
        ...data,
        result: [
            ...data.result,
            {label: '__total__', x: '__total__', y: ySeriesDataWithTotals.map((series) => series[series.length - 1].value)},
        ],
    };

    const seriesToPlot = ySeriesDataWithTotals.flatMap((series, index) => [
        {
            name: 'transparent',
            type: 'bar',
            stack: 'waterfall',
            itemStyle: {
                borderColor: 'transparent',
                color: 'transparent',
            },
            data: transparentSeries[index],
        }]);

    const totalById = {};
    ySeriesDataWithTotals.forEach((series) => {
        series.forEach((el) => {
            const label = el.name;
            totalById[label] = (totalById[label] || 0) + el.value;
        });
    });

    ySeriesDataWithTotals.forEach((series, index) => seriesToPlot.push({
        name: 'values',
        type: 'bar',
        stack: 'waterfall',
        data: series,
        itemStyle: {
            color: getSeriesColor(index),
        },
        label: index < ySeriesDataWithTotals.length - 1 ? '' : defaultValueLabelSetting('top', baseFontSize, config,
            (value) => formatCompactInteger(totalById[value.name], config.locale)),
    }));

    return {
        ...getBase(title, baseFontSize),
        ...getAxisV(dataWithTotal, config, baseFontSize, 1),
        ...getAxisTooltip(dataWithTotal, config, baseFontSize),

        series: seriesToPlot,
    };
};

