import React, {useContext, useEffect, useState} from 'react';
import {useLocation} from 'react-router';
import {Box, Collapse, Drawer, List, ListItemButton, ListItemIcon, ListItemText, Toolbar} from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Widgets from '@mui/icons-material/Widgets';
import PropTypes from 'prop-types';

import {AppContext} from '../App';
import {Link} from 'react-router-dom';

const drawerWidth = 220;

const Menu = ({isDrawerPinned}) => {
    const {config} = useContext(AppContext);
    const location = useLocation();
    const [isMouseOver, setIsMouseOver] = useState(false);
    const [openOptimize, setOpenOptimize] = useState(true);

    useEffect(() => {
        if (isDrawerPinned) {
            setIsMouseOver(false);
        }
    }, [isDrawerPinned]);

    return (
        <>
            <Box
                onMouseEnter={() => setIsMouseOver(true)}
                sx={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    bottom: 0,
                    width: 60,
                }}
            />

            <Drawer
                datacy='menu'
                variant={isDrawerPinned ? 'persistent' : 'temporary'}
                elevation={1}
                onMouseLeave={() => setIsMouseOver(false)}
                hideBackdrop
                sx={{
                    'width': isDrawerPinned || isMouseOver ? drawerWidth : 0,
                    'flexShrink': 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                open={isDrawerPinned || isMouseOver}
                transitionDuration={300}
            >
                <Toolbar />
                <Box sx={{overflow: 'auto'}} datacy='side_menu'>
                    <List>
                        <ListItemButton
                            datacy='side_menu_ask_me'
                            key="menu-ask"
                            component={Link}
                            to='/ask'
                            selected={location.pathname === '/ask'}
                        >
                            <ListItemIcon sx={{minWidth: '38px'}}>
                                <QuestionAnswerIcon />
                            </ListItemIcon>
                            <ListItemText primary={config.i18n.page.ask_me} />
                        </ListItemButton>
                        <ListItemButton
                            key="menu-explore"
                            component={Link}
                            to='/explore'
                            selected={location.pathname === '/explore'}
                        >
                            <ListItemIcon sx={{minWidth: '38px'}}>
                                <Widgets />
                            </ListItemIcon>
                            <ListItemText primary={config.i18n.page.explore} />
                        </ListItemButton>
                        <ListItemButton
                            key='menu-optimize'
                            onClick={() => setOpenOptimize(!openOptimize)}
                        >
                            <ListItemIcon sx={{minWidth: '38px'}}>
                                <TuneIcon />
                            </ListItemIcon>
                            <ListItemText primary={config.i18n.page.optimize} />
                            {openOptimize ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </ListItemButton>
                        <Collapse in={openOptimize} timeout='auto' unmountOnExit>
                            <List component='div' disablePadding>
                                <ListItemButton
                                    component={Link}
                                    to='/procurement'
                                    selected={location.pathname === '/procurement'}
                                    sx={{pl: 4}}
                                >
                                    <ListItemIcon sx={{minWidth: '38px'}}>
                                        <ShoppingCartIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={config.i18n.page.procurement} />
                                </ListItemButton>
                            </List>
                        </Collapse>
                    </List>
                </Box>
            </Drawer>
        </>
    );
};

Menu.propTypes = {
    isDrawerPinned: PropTypes.bool,
};

export default Menu;
