import React, {forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useRef} from 'react';
import PropTypes from 'prop-types';

import * as echarts from 'echarts/core';
import ReactEcharts from 'echarts-for-react';

import {AppContext} from '../App';

import {getChartOptions as getAreaChartOptions} from './chart/area';
import {getChartOptions as getBarChartOptions} from './chart/bar';
import {getChartOptions as getColumnChartOptions} from './chart/column';
import {getChartOptions as getLineChartOptions} from './chart/line';
import {getChartOptions as getTimelineCompareChartOptions} from './chart/timeline/compare';
import {getChartOptions as getPieChartOptions} from './chart/pie';
import {getChartOptions as getWaterfallChartOptions} from './chart/waterfall';
import {getChartOptions as getBubbleChartOptions} from './chart/bubble';
import {getChartOptions as getScatterChartOptions} from './chart/scatter';
import {getChartOptions as getHistogramChartOptions} from './chart/histogram';
import {getChartOptions as getStackedColumnChartOptions} from './chart/stackedColumn';
import {getChartOptions as getStackedAreaChartOptions} from './chart/stackedArea';
import {getChartOptions as getStackedBarChartOptions} from './chart/stackedBar';
import {getChartOptions as getStackedWaterfallChartOptions} from './chart/stackedWaterfall';
import {getChartOptions as getTimelineChartOptions} from './chart/timeline/timeline';
import {getChartOptions as getStackedTimelineChartOptions} from './chart/timeline/stackedTimeline';
import {getChartOptions as getVarianceChartOptions} from './chart/variance';

const Chart = forwardRef( function Chart(props, ref) {
    const {config} = useContext(AppContext);
    const {type, title, baseFontSize, data, disableDrillDown, disableDrillAcross, onDrillDown, onContextMenu} = props;
    const echartsRef = useRef();

    useImperativeHandle(ref, () => ({
        exportImage() {
            const instance = echartsRef.current.getEchartsInstance();
            const targetWidth = 4000;
            const ratio = targetWidth / instance.getWidth();
            const image = instance.getDataURL({pixelRatio: ratio});
            return image.slice('data:image/png;base64,'.length);
        },
    }));

    useEffect(() => {
        if (echartsRef && echartsRef.current) {
            echartsRef.current.resize();
        }
    }, [baseFontSize, data, echartsRef]);

    if (!config || !data) {
        return null;
    }

    let chartOptionsFn;

    switch (type) {
        case 'AREA':
            chartOptionsFn = getAreaChartOptions;
            break;
        case 'BAR':
            chartOptionsFn = getBarChartOptions;
            break;
        case 'COLUMN':
            chartOptionsFn = getColumnChartOptions;
            break;
        case 'LINE':
            chartOptionsFn = getLineChartOptions;
            break;
        case 'PIE':
            chartOptionsFn = getPieChartOptions;
            break;
        case 'WATERFALL':
            chartOptionsFn = getWaterfallChartOptions;
            break;
        case 'SCATTER':
            chartOptionsFn = getScatterChartOptions;
            break;
        case 'BUBBLE':
            chartOptionsFn = getBubbleChartOptions;
            break;
        case 'HISTOGRAM':
            chartOptionsFn = getHistogramChartOptions;
            break;
        case 'STACKED_COLUMN':
            chartOptionsFn = getStackedColumnChartOptions;
            break;
        case 'STACKED_AREA':
            chartOptionsFn = getStackedAreaChartOptions;
            break;
        case 'STACKED_BAR':
            chartOptionsFn = getStackedBarChartOptions;
            break;
        case 'STACKED_WATERFALL':
            chartOptionsFn = getStackedWaterfallChartOptions;
            break;
        case 'STACKED_TIMELINE':
            chartOptionsFn = getStackedTimelineChartOptions;
            break;
        case 'TIMELINE':
            chartOptionsFn = getTimelineChartOptions;
            break;
        case 'TIMELINE_COMPARE':
            chartOptionsFn = getTimelineCompareChartOptions;
            break;
        case 'VARIANCE':
            chartOptionsFn = getVarianceChartOptions;
            break;
        default:
            // stop rendering
            return null;
    }

    const chartOptions = chartOptionsFn(title, data, config, baseFontSize);
    chartOptions.series = chartOptions.series.map((series) =>
        ({
            cursor: disableDrillDown || disableDrillAcross ? 'default' : 'pointer',
            ...series,
        }));


    if (!config.visual['animations']) { // Animations are toggled true by default
        chartOptions.animation = false;
    }
    chartOptions.animation = !!config.visual['animations'];

    // drill callbacks.
    // useCallback avoids having a new function declaration, since it's memoized on the first declaration.
    // since ReactEcharts runs a deep equal, we need to make sure these functions have the same reference.
    const onClick = useCallback((params) => {
        // avoid triggering drill-down, if disabled or if clicked on tail, empty or total.
        if (disableDrillDown || !params.data.id || params.data.id === '__tail__' || params.data.id === '__total__') {
            return;
        }

        onDrillDown(params.data.id, params.data.value);
    }, [disableDrillDown, onDrillDown]);

    const onRightClick = useCallback((params) => {
        params.event.stop();

        // avoid triggering drill-across, if disabled or if clicked on tail, empty or total.
        if (disableDrillAcross || !params.data.id || params.data.id === '__tail__' || params.data.id === '__total__') {
            return;
        }

        onContextMenu(params.data.id, params.event.event.clientX, params.event.event.clientY);
    }, [disableDrillAcross, onContextMenu]);

    // each chart type is rendered as an independent component (by setting the key)
    // this prevents issues with changing chart types and old configs being applied
    return (
        <ReactEcharts
            key={type}
            echarts={echarts}
            option={chartOptions}
            style={{height: '100%'}}
            onEvents={{
                'click': onClick,
                'contextmenu': onRightClick,
            }}
            ref={echartsRef}
            notMerge
        />
    );
});

Chart.propTypes = {
    type: PropTypes.string,
    title: PropTypes.string,
    baseFontSize: PropTypes.number,
    data: PropTypes.object,
    disableDrillDown: PropTypes.bool,
    disableDrillAcross: PropTypes.bool,
    onDrillDown: PropTypes.func,
    onContextMenu: PropTypes.func,
};

export default Chart;
