import {Autocomplete, Box, TextField} from '@mui/material';
import React, {useContext} from 'react';

import DimensionAutoCompleteBox from '../picker/DimensionAutoCompleteBox';
import {AppContext} from '../../App';
import PropTypes from 'prop-types';
import HierarchyAutoCompleteBox from '../picker/HierarchyAutoCompleteBox';
import DeleteIconButton from '../button/DeleteIconButton';
import DimensionBox from '../DimensionBox';

const DimensionPicker = ({dimension, dimensionFilters, metadata, filters, disabled, onUpdate, onDelete}) => {
    const {client, config} = useContext(AppContext);

    const apiMap = {
        'CLIENT': client.client.clientSearch,
        'CLIENT_COUNTRY': client.clientCountry.clientCountrySearch,
        'VENDOR': client.vendor.vendorSearch,
        'VENDOR_COUNTRY': client.vendorCountry.vendorCountrySearch,
        'VENDOR_TYPE': client.vendorType.vendorTypeSearch,
        'VENDOR_SEGMENT': client.vendorSegment.vendorSegmentSearch,
        'BUSINESS_UNIT': client.businessUnit.businessUnitSearch,
        'OWNER': client.owner.ownerSearch,
        'DEPARTMENT': client.department.departmentSearch,
        'PROJECT': client.project.projectSearch,
        'SITE': client.site.siteSearch,
        'COMPANY': client.company.companySearch,
        'COUNTRY': client.country.countrySearch,
        'CATEGORY_L1': client.category.categoryLevel1Search,
        'CATEGORY_L2': client.category.categoryLevel2Search,
        'CATEGORY_L3': client.category.categoryLevel3Search,
        'CATEGORY_L4': client.category.categoryLevel4Search,
        'CATEGORY_L5': client.category.categoryLevel5Search,
        'SPEND_GROUP': client.spendGroup.spendGroupSearch,
        'ACCOUNTING_TREATMENT': client.accountingTreatment.accountingTreatmentSearch,
        'DISCRETIONARY_COST': client.discretionaryCost.discretionaryCostSearch,
        'FIXED_COST': client.fixedCost.fixedCostSearch,
        'DIRECT_COST': client.directCost.directCostSearch,
        'DEMAND_FRAGMENTATION': client.demandFragmentation.demandFragmentationSearch,
        'SWITCHING_COST': client.switchingCost.switchingCostSearch,
        'SUPPLY_CONTROL': client.supplyControl.supplyControlSearch,
        'INTRA_GROUP': client.intraGroup.intraGroupSearch,
        'MATERIAL_GROUP': client.materialGroup.materialGroupSearch,
        'MATERIAL': client.material.materialSearch,
        'DATA_SOURCE': client.dataSource.dataSourceSearch,
    };

    function dispatchDimensionSearch(dimension, queryString, page = 0, dimsToIgnore = []) {
        const dims = {...dimensionFilters};
        dimsToIgnore.forEach((toIgnore) => {
            delete dims[toIgnore];
        });

        return apiMap[dimension](queryString, page, void 0, {
            parameters: filters, dimension_parameters: dims,
        });
    }

    function getAvailableOptions() {
        // the available dim options are dims
        // i) not already filtered.
        // ii) available to search in the API map.
        // iii) don't belong to CATEGORY.
        // iv) or hierarchical.
        // TODO #1318: Review this behavior when a dimension of a hierarchy and the hierarchy are picked at the same time.
        return config.dimension.filter((dim) =>
            (!(dim in dimensionFilters) &&
                dim in apiMap &&
                !config.hierarchy.CATEGORY.flat().includes(dim)) ||
            // include hierarchical dimensions.
            dim in config.hierarchy)
            .sort((dim1, dim2) => {
                const i18n1 = config.i18n.dimension[dim1] || dim1;
                const i18n2 = config.i18n.dimension[dim2] || dim2;

                return i18n1.localeCompare(i18n2);
            });
    }

    function getComponentToRender() {
        // if dimension is set and belongs to a hierarchy, render a HierarchyAutoCompleteBox.
        // else if dimension is set and doesn't belong to a hierarchy, render a DimensionAutoCompleteBox.
        // else render a box to pick the dimension.
        if (dimension && dimension in config.hierarchy) {
            const values = config.hierarchy[dimension].map((dim) => dimensionFilters[dim] || []);

            return (
                <DimensionBox
                    title={config.i18n.dimension[dimension]}
                    internalSx={{px: 1, py: 0.5, gap: 1, flexDirection: 'column'}}
                >
                    <HierarchyAutoCompleteBox
                        disabled={disabled}
                        dimensions={config.hierarchy[dimension]}
                        values={values}
                        metadata={metadata}
                        onChange={(dims, vals, metadata) => {
                            const newDimFilters = dims.reduce((acc, value, index) => {
                                return {...acc, [value]: vals[index]};
                            }, {});

                            onUpdate(dimension, newDimFilters, metadata);
                        }}
                        onQuery={(dim, queryString, dimsToIgnore, page = 0) =>
                            dispatchDimensionSearch(dim, queryString, page, dimsToIgnore)}
                        fullWidth
                    />
                </DimensionBox>

            );
        } else if (dimension) {
            return (
                <DimensionAutoCompleteBox
                    disabled={disabled}
                    dimension={dimension}
                    onChange={(dimValues) => {
                        // FIXME #1028: Standardize API.
                        const newValues = dimValues.map((dim) => dim?.id || dim);
                        const newMetadata = dimValues.reduce((acc, value) => {
                            return {...acc, [value.id]: value};
                        }, {});

                        onUpdate(dimension,
                            {
                                [dimension]: newValues,
                            }, newMetadata);
                    }}
                    onQuery={(queryString, page = 0) =>
                        dispatchDimensionSearch(dimension, queryString, page, [dimension])}
                    metadata={metadata}
                    values={dimensionFilters[dimension] || []}
                    fullWidth
                />
            );
        } else {
            return (
                <Autocomplete
                    datacy='dimension_picker'
                    options={getAvailableOptions()}
                    getOptionLabel={(option) => config.i18n.dimension[option] || option}
                    size='small'
                    disableClearable
                    disablePortal
                    fullWidth
                    renderInput={(params) =>
                        <TextField {...params} label={config.i18n.selector.dimension} />}
                    onChange={(ev, value) => onUpdate(value)}
                />
            );
        }
    }

    return (
        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: 0.5}}>
            <Box sx={{display: 'flex', flexDirection: 'column', flex: 1, gap: 1}}>
                {getComponentToRender()}
            </Box>
            <DeleteIconButton
                disabled={disabled}
                color='grey'
                variant='outlined'
                onClick={() => onDelete(dimension, dimension in config.hierarchy ? config.hierarchy[dimension] : [])}
            />
        </Box>
    );
};

DimensionPicker.propTypes = {
    dimension: PropTypes.string,
    dimensionFilters: PropTypes.object,
    metadata: PropTypes.object,
    filters: PropTypes.object,
    disabled: PropTypes.bool,
    onUpdate: PropTypes.func,
    onDelete: PropTypes.func,
};

export default DimensionPicker;
