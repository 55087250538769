import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import PropTypes from 'prop-types';
import {useContext} from 'react';
import {AppContext} from '../App';

const DiscardDialog = ({open, onDiscard, onCancel}) => {
    const {config} = useContext(AppContext);

    return (
        <Dialog
            maxWidth='xs'
            open={open}
        >
            <DialogTitle>{config.i18n.dialog.discard_changes_title}</DialogTitle>
            <DialogContent dividers>
                {config.i18n.dialog.discard_changes}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onCancel()}>
                    {config.i18n.button.cancel}
                </Button>
                <Button
                    onClick={() => onDiscard()}
                >
                    {config.i18n.button.discard}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

DiscardDialog.propTypes = {
    open: PropTypes.bool,
    onDiscard: PropTypes.func,
    onCancel: PropTypes.func,
};

export default DiscardDialog;
