import dayjs from 'dayjs';
import DimensionBox from '../DimensionBox';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import DatePickerTextField from '../field/DatePickerTextField';
import React, {useContext, useState} from 'react';
import {AppContext} from '../../App';
import SelectField from '../field/SelectField';
import {Button, Checkbox, FormControlLabel, Grid} from '@mui/material';
import PropTypes from 'prop-types';


const DateSelector = ({disabled, date, defaultDate, dateAgg, annualize, onUpdate}) => {
    const {config} = useContext(AppContext);

    const [openStart, setOpenStart] = useState(false);
    const [openEnd, setOpenEnd] = useState(false);

    const minDate = dayjs(date[0]);
    const maxDate = dayjs(date[1]);

    const globalMin = dayjs(config.time.global.min);
    const globalMax = dayjs(config.time.global.max);

    const referenceMin = dayjs(config.time.reference.min);
    const referenceMax = dayjs(config.time.reference.max);

    let defaultMin = null;
    let defaultMax = null;
    if (defaultDate?.length) {
        defaultMin = dayjs(defaultDate[0]);
        defaultMax = dayjs(defaultDate[1]);
    }

    return (
        <DimensionBox
            title={config.i18n.customization_bar.date}
            internalSx={{px: 1, py: 0.5, flexWrap: 'nowrap'}}
        >
            <Grid container spacing={1} rowSpacing={2}>
                <Grid item xs={6}>
                    <DatePicker
                        datacy="startDate_selector"
                        open={openStart}
                        onOpen={() => setOpenStart(true)}
                        onClose={() => setOpenStart(false)}
                        label={config.i18n.dimension.DATE_FROM}
                        disabled={disabled}
                        disableFuture
                        minDate={globalMin}
                        maxDate={maxDate || globalMax}
                        value={minDate}
                        onChange={(val) => onUpdate(val, maxDate)}
                        onAccept={(val) => onUpdate(val, maxDate)}
                        slotProps={{textField: {size: 'small'}}}
                        renderInput={(params) =>
                            (
                                <DatePickerTextField
                                    {...params}
                                    onClick={(e) => setOpenStart(true)} // Click anywhere to open datepicker
                                    disableClear
                                    fullWidth
                                />
                            )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <DatePicker
                        datacy="endDate_selector"
                        open={openEnd}
                        onOpen={() => setOpenEnd(true)}
                        onClose={() => setOpenEnd(false)}
                        label={config.i18n.dimension.DATE_TO}
                        disabled={disabled}
                        disableFuture
                        minDate={minDate || globalMin}
                        maxDate={globalMax}
                        value={maxDate}
                        onChange={(val) => onUpdate(minDate, val)}
                        onAccept={(val) => onUpdate(minDate, val)}
                        slotProps={{textField: {size: 'small'}}}
                        renderInput={(params) =>
                            (
                                <DatePickerTextField
                                    {...params}
                                    onClick={(e) => setOpenEnd(true)} // Click anywhere to open datepicker
                                    disableClear
                                    fullWidth
                                />
                            )}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Button
                        datacy="allTimeDate_selector"
                        variant='contained'
                        color='grey'
                        fullWidth
                        disabled={disabled || (minDate.isSame(globalMin) && maxDate.isSame(globalMax))}
                        onClick={() => onUpdate(globalMin, globalMax)}
                    >
                        {config.i18n.selector.all_date_button}
                    </Button>
                </Grid>
                <Grid item xs={4}>
                    <Button
                        datacy="referenceDate_selector"
                        variant='contained'
                        color='grey'
                        fullWidth
                        disabled={disabled || (minDate.isSame(referenceMin) && maxDate.isSame(referenceMax))}
                        onClick={() => onUpdate(referenceMin, referenceMax)}
                    >
                        {config.i18n.selector.reference_date_button}
                    </Button>
                </Grid>
                <Grid item xs={4}>
                    <Button
                        datacy="defaultDate_selector"
                        variant='contained'
                        color='grey'
                        fullWidth
                        disabled={disabled || !defaultDate?.length ||
                            (minDate.isSame(defaultMin) && maxDate.isSame(defaultMax))}
                        onClick={() => onUpdate(defaultMin, defaultMax)}
                    >
                        {config.i18n.selector.default_date_button}
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <SelectField
                        datacy="aggDate_selector"
                        value={dateAgg?.value || ''}
                        label={config.i18n.selector.date_agg}
                        possibleValues={dateAgg?.options || []}
                        metadata={config.i18n.dimension}
                        disabled={disabled || !dateAgg}
                        onChange={(value) => dateAgg.onUpdate(value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                datacy="annualizeDate_selector"
                                checked={annualize?.value || false}
                                disabled={disabled || !annualize}
                                onChange={(event) => annualize.onUpdate(event.target.checked)}
                            />
                        }
                        label={config.i18n.selector.annualize}
                    />
                </Grid>
            </Grid>
        </DimensionBox>
    );
};

DateSelector.propTypes = {
    disabled: PropTypes.bool,
    date: PropTypes.array,
    defaultDate: PropTypes.array,
    defaultSetting: PropTypes.array,
    dateAgg: PropTypes.object,
    annualize: PropTypes.object,
    onUpdate: PropTypes.func,
};

export default DateSelector;
