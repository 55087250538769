import {getAxisTooltip, getAxisV, getYSeries} from './common';
import {getBase, getLegend} from '../common';
import {getChartOptions as getAreaChartOptions} from '../stackedArea';

export function getChartOptions(title, data, config, baseFontSize) {
    // If x type is not date, then we return an area chart, instead of stacked timeline.
    if (data.label_type.x !== 'DATE') {
        return getAreaChartOptions(title, data, config, baseFontSize, true);
    }

    return {
        ...getBase(title, baseFontSize),
        ...getAxisV(data, config, baseFontSize),
        ...getAxisTooltip(data, config, baseFontSize),
        ...getLegend(data, config, baseFontSize),
        ...getYSeries(data, 'line', baseFontSize, config, {
            areaStyle: {},
        }),
    };
}
