import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from '../App';
import AppsIcon from '@mui/icons-material/Apps';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ConstructionIcon from '@mui/icons-material/Construction';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ReceiptIcon from '@mui/icons-material/Receipt';
import Filter1Icon from '@mui/icons-material/Filter1';
import LinkIcon from '@mui/icons-material/Link';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import VerifiedIcon from '@mui/icons-material/Verified';
import SellIcon from '@mui/icons-material/Sell';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import {Avatar, Button, Dialog, DialogContent, DialogTitle, Grid, Link, Skeleton, Tooltip, Typography} from '@mui/material';
import {styled} from '@mui/system';
import {hasValue} from '../util/util';

const TryThis = () => {
    const {client, notify, config} = useContext(AppContext);
    const [topics, setTopics] = useState();
    const [dialogContent, setDialogContent] = useState();

    useEffect(() => {
        client.tryThis.tryThisGetTryThis()
            .then((data) => {
                setTopics(data);
            })
            .catch((error) => {
                notify.error(error,
                    'analysis.run',
                    `Error fetching Try This analyses.`);
            });
    }, []);

    function getTopicName(topic) {
        return config.i18n.topics[topic];
    }

    function getTopicIcon(topic) {
        const styling = {color: 'grey', width: 40, height: 40};
        switch (topic) {
            case 'CATEGORY_ANALYSES':
                return <AppsIcon sx={styling} />;
            case 'ORG_ANALYSES':
                return <AccountTreeIcon sx={styling} />;
            case 'PROJECT_ANALYSES':
                return <ConstructionIcon sx={styling} />;
            case 'OWNER_ANALYSES':
                return <ShoppingCartIcon sx={styling} />;
            case 'SUPPLIER_ANALYSES':
                return <StorefrontIcon sx={styling} />;
            case 'CONTRACT_ANALYSES':
                return <ReceiptIcon sx={styling} />;
            case 'PLANNING & CONTROL':
                return <Filter1Icon sx={styling} />;
            case 'CAPS_KPIS':
                return <LinkIcon sx={styling} />;
            case 'SYNTAX_EXAMPLES':
                return <RecordVoiceOverIcon sx={styling} />;
            case 'PROCUREMENT_EXCELLENCE':
                return <VerifiedIcon sx={styling} />;
            case 'COMMERCIAL_PROCUREMENT':
                return <SellIcon sx={styling} />;
            case 'PRIORITY_INITIATIVES':
                return <LooksOneIcon sx={styling} />;
        }
    }

    const RoundGradientCircle = styled('Box')({
        width: 100,
        height: 100,
        border: 'double 5px transparent',
        borderRadius: 50,
        backgroundImage: 'linear-gradient(white, white), radial-gradient(circle at top left, lightgrey, grey, #1976d2)',
        backgroundOrigin: 'border-box',
        backgroundClip: 'content-box, border-box',
        boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
    });


    function createButton(id, title, avatar, content) {
        return (
            <Grid item key={id} xs={12} md={6} lg={4} xl={2} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Button
                    size="large"
                    sx={{
                        borderRadius: 50,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                    TouchRippleProps={{center: true}}
                    onClick={()=>{
                        setDialogContent({title: title, avatar: avatar, content: content});
                    }}
                    disabled={Array.isArray(content) && content.every((item) => !hasValue(item))}
                >
                    <RoundGradientCircle sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        {avatar}
                    </RoundGradientCircle>
                    <Typography marginTop={3} sx={{textTransform: 'capitalize'}}>
                        {title}
                    </Typography>
                </Button>
            </Grid>
        );
    }

    let buttons = [];
    if (!topics) {
        buttons = [...Array(12)].map((x, index) => {
            createButton(
                index,
                <Skeleton animation="wave" />,
                <Skeleton variant="circular" animation="wave"><Avatar /></Skeleton>,
                <Typography noWrap variant='body1'>
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                </Typography>,
            );
        });
    } else {
        buttons = topics.map((topic) =>
            createButton(
                topic.topic_id,
                getTopicName(topic.topic_id),
                getTopicIcon(topic.topic_id),
                topic.questions.map((analysisLink) => {
                    const link = analysisLink.link;
                    if (!link) {
                        return null;
                    }
                    const question = analysisLink.question;
                    return (
                        <Tooltip followCursor placement="bottom-start" key={analysisLink.analysis_id} title={question}>
                            <Typography noWrap variant='body1'>
                                <Link data-tooltip={question} href={link} datacy="trythis_link">
                                    {question}
                                </Link>
                            </Typography>
                        </Tooltip>
                    );
                }),
            ),
        );
    }

    return (
        <>
            <Grid container datacy="trythis" rowSpacing={3} >
                {buttons}
            </Grid>
            {dialogContent ?
                <Dialog
                    open={!!dialogContent}
                    onClose={() => setDialogContent(null)}
                >
                    <DialogTitle
                        sx={{variant: 'h6', color: 'grey', display: 'flex', alignItems: 'center', gap: 1.5}}
                    >{dialogContent?.avatar} {dialogContent?.title}
                    </DialogTitle>
                    <DialogContent>
                        {dialogContent?.content}
                    </DialogContent>
                </Dialog> : null
            }
        </>
    );
};

export default TryThis;
