import React, {useCallback} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import {useOktaAuth} from '@okta/okta-react';

import OktaWidget from './OktaWidget';

const Login = () => {
    const {oktaAuth, authState} = useOktaAuth();

    const onSuccess = useCallback(async (tokens) => await oktaAuth.handleLoginRedirect(tokens), [oktaAuth]);
    const onError = useCallback((e) => console.error('Login error.', e), []);

    if (!authState) {
        return null;
    }

    return authState.isAuthenticated ?
        <Routes>
            <Route element={<Navigate to={{pathname: '/'}} />} />
        </Routes> :
        <OktaWidget
            onSuccess={onSuccess}
            onError={onError} // TODO toast message
        />;
};

export default Login;
