import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Autocomplete, Box, debounce, Drawer, Grid, InputAdornment, ListItemIcon, ListItemText, Menu, MenuItem, TextField, Toolbar} from '@mui/material';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';

import {AppContext} from '../App';
import {useUrlStore} from '../util/hooks';

import CustomizationBar from '../components/CustomizationBar';
import Slide from '../components/Slide';
import CircularLoading from '../components/loading/CircularLoading';
import {hasValue, tryGetHierarchicalDimension} from '../util/util';
import {isEmpty, omit} from 'lodash';
import {getDefaultByType, initializeParameterDefaults} from '../util/parameters';
import AdvisorContainer from '../layout/AdvisorContainer';
import {Helmet} from 'react-helmet';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import TryThis from './TryThis';
import {Search} from '@mui/icons-material';

const Ask = () => {
    const {client, config, notify} = useContext(AppContext);
    const [query, setQuery] = useState();
    const [loading, setLoading] = useState(false);
    const [queryLoading, setQueryLoading] = useState(false);
    const [request, setRequest] = useState();
    const [open, setOpen] = useState(false);
    const [isFetchingAnalysis, setIsFetchingAnalysis] = useState(false);
    const [slideContext, setSlideContext] = useState();

    const [analyses, setAnalyses] = useState();
    const [analysisStore, setAnalysisStore] = useUrlStore({
        analysis: {},
        chart: null,
        parameters: {},
        dimensions: [],
        dimParameters: {},
        customParameters: {},
    });

    const {
        analysis,
        chart: analysisChart,
        parameters: analysisParameters,
        dimensions: analysisDimensions,
        dimParameters: analysisDimParameters,
        customParameters: analysisCustomParameters,
    } = analysisStore;

    const [analysisMetadata, setAnalysisMetadata] = useState();

    const [contextMenuAnchor, setContextMenuAnchor] = useState();
    const [contextMenuDimId, setContextMenuDimId] = useState();

    const drawerWidth = 430;

    // this works because AutoComplete component is not controlled
    // (it does not uses the query value as input)
    const setQueryDebounced = debounce(setQuery, 250);

    const handleClickAway = () => {
        setAnalyses(null);
        setOpen(false);
        setLoading(false);
    };

    function fetchAndSetAnalysis(analysisId, onSuccess = (newAnalysisStore) => newAnalysisStore, keepParameters = false) {
        setIsFetchingAnalysis(true);
        client.analysis.analysisGet(analysisId)
            .then((analysis) => {
                let newAnalysisStore = {
                    ...analysisStore,
                };

                // avoid adding these param types to defaults, since they are handled differently.
                const newAnalysisParameters = initializeParameterDefaults(analysis, ['CHART', 'DIMENSION', 'HIERARCHY']);

                // if it's the first time considering the parameters, let's just initialize everything.
                // else, reuse all values except 'HIDE_TAIL' and 'LIMIT'
                //  and forget parameters not supported by the new analysis.
                if (!analysisParameters) {
                    newAnalysisStore = {
                        parameters: newAnalysisParameters,
                        dimensions: [],
                        dimParameters: {},
                        customParameters: {},
                    };

                    setAnalysisMetadata({});
                } else {
                    // forget parameters not supported by the new analysis.
                    const paramsToForget = config.ask_me.customization_parameters
                        .filter((param) => !(param in newAnalysisParameters));

                    // never consider TAIL and LIMIT last value, when setting a new analysis, unless force to keep it.
                    if (!keepParameters) {
                        paramsToForget.push('HIDE_TAIL', 'LIMIT');
                    }

                    const oldParamValues = omit(analysisParameters, paramsToForget);

                    newAnalysisStore.parameters = {
                        ...newAnalysisParameters,
                        // old parameters should have precedence over the new ones.
                        ...oldParamValues,
                    };
                }

                const newAnalysis = {
                    id: analysis.id,
                    question: analysis.question,
                    customizationParameters: analysis.customization_parameters,
                    relatedAnalyses: analysis.related_analyses,
                };

                newAnalysisStore.analysis = newAnalysis;

                // never consider the chart when changing analysis, unless force to keep it.
                if (!keepParameters) {
                    newAnalysisStore.chart = getDefaultByType(newAnalysis.customizationParameters, 'CHART');
                }

                newAnalysisStore = onSuccess(newAnalysisStore);
                setAnalysisStore(newAnalysisStore);
                setIsFetchingAnalysis(false);
            })
            .catch((error) => {
                setAnalysisMetadata(null);
                setIsFetchingAnalysis(false);
                notify.error(error, 'analysis.fetch', 'Error getting analysis.');
            });
    }

    function closeContextualMenu() {
        setContextMenuDimId(null);
        setContextMenuAnchor(null);
    }

    function drill(relatedAnalysis, dimensionId) {
        fetchAndSetAnalysis(relatedAnalysis.analysis_id, (newAnalysisStore) => {
            const dimensionToFilter = relatedAnalysis.filter_by;

            const newAnalysisDimParameters = newAnalysisStore.dimParameters;
            const oldDimParameter = newAnalysisDimParameters[dimensionToFilter] || [];
            const transformedAnalysisStore = {
                ...newAnalysisStore,
                dimParameters: {
                    ...newAnalysisDimParameters,
                    [dimensionToFilter]: [...oldDimParameter, dimensionId],
                },
            };

            // to make sure we display the entire hierarchical visual filter.
            const newAnalysisDimensions = newAnalysisStore.dimensions;
            const dimension = tryGetHierarchicalDimension(config, dimensionToFilter);
            if (!newAnalysisDimensions.includes(dimension)) {
                transformedAnalysisStore.dimensions =
                    [...newAnalysisDimensions, dimension];
            }

            return transformedAnalysisStore;
        },
        // Usually, chart type, hide tail, and ignore nulls are reset. Force them to not be reset.
        true);
    }

    // useCallback avoids having a new function declaration, since it's memoized on the first declaration.
    // This way these callbacks, aren't redeclare, which avoids re-rendering of the Chart component.
    const onDrillDown = useCallback((dimId) => {
        if (analysis?.relatedAnalyses?.down) {
            drill(analysis.relatedAnalyses.down, dimId);
        }
    }, [analysis]);

    const onChartContextMenu = useCallback((dimId, mouseX, mouseY) => {
        setContextMenuAnchor({
            left: mouseX,
            top: mouseY,
        });
        setContextMenuDimId(dimId);
    }, [analysis]);

    useEffect(() => {
        if (!query) {
            setAnalyses(null);
            setLoading(false);
        } else {
            setLoading(true);

            // cancel previous request
            if (request) {
                request.cancel();
            }

            const requestPremise = client.analysis.analysisSearch(query, 0, 5);

            setRequest(requestPremise);

            requestPremise
                .then((data) => {
                    setAnalyses(data.response);
                    setOpen(true);
                    setLoading(false);
                })
                .catch((error) => {
                    // ignore cancelation errors
                    if (error?.name === 'CancelError') {
                        return;
                    }

                    setAnalyses(null);
                    setOpen(false);
                    setLoading(false);
                    notify.error(error, 'analysis.search', 'Error querying analyses.');
                });
        }
    }, [query]);

    useEffect(() => {
        if (analysis?.id) {
            fetchAndSetAnalysis(analysisStore.analysis.id, (newAnalysisStore) => {
                // fill metadata.
                if (isEmpty(newAnalysisStore.dimParameters)) {
                    setAnalysisMetadata({});
                } else {
                    const ids = Object.values(newAnalysisStore.dimParameters).flatMap((el) => el);
                    client.dimension.dimensionMetadata(ids)
                        .then(setAnalysisMetadata)
                        .catch((error) => {
                            setAnalysisMetadata(null);
                            notify.error(error, 'analysis.fetch', 'Error getting analysis.');
                        });
                }

                // enforce setting the URL chart.
                return {
                    ...newAnalysisStore,
                    chart: newAnalysisStore.chart,
                };
            });
        }
    }, [analysisStore?.analysis?.id]);

    useEffect(() => {
        if (analysis?.id && analysisChart &&
            analysisParameters && analysisDimParameters && analysisCustomParameters) {
            setQueryLoading(true);
            setOpen(false);

            client.query.queryRun(analysis.id,
                {
                    parameters: analysisParameters,
                    dimension_parameters: analysisDimParameters,
                    custom_parameters: analysisCustomParameters,
                })
                .then((data) => {
                    setQueryLoading(false);

                    setSlideContext({
                        analysisId: analysis.id,
                        question: analysis.question,
                        chart: analysisChart,
                        data: data,
                    });

                    setAnalysisMetadata({
                        ...analysisMetadata,
                        ...data.metadata,
                    });
                })
                .catch((error) => {
                    setQueryLoading(false);
                    notify.error(error,
                        'analysis.run',
                        `Error fetching analysis (${analysis.id}, ${analysis.question}) result.`);
                });
        } else {
            setSlideContext(null);
        }
    }, [analysisStore.analysis, analysisStore.parameters, analysisStore.dimParameters, analysisStore.customParameters]);

    function isTryThis() {
        // HACK: we check if there is no analysis id
        // if there is no analysis id, we are in the Try This page
        return !analysis?.id;
    }

    return (
        <>
            <Helmet>
                <title>{config.i18n.page.main} - {analysis?.question || config.i18n.page.ask_me}</title>
            </Helmet>
            <AdvisorContainer>
                <Box sx={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sx={{display: 'flex', flexDirection: 'row', alignItems: 'stretch', ...(isTryThis() && {mt: 23})}}>
                            <ClickAwayListener onClickAway={handleClickAway}>
                                <Autocomplete
                                    disabled={isFetchingAnalysis || queryLoading}
                                    autoHighlight
                                    freeSolo
                                    value={analysis?.question || ''}
                                    options={analyses || []}
                                    getOptionLabel={(option) => {
                                        // if the selected option has question, let's show it.
                                        // else if the user pressed enter without any options, a string will be passed.
                                        //      So let's keep showing the user input.
                                        // else just clear it.
                                        if (option['question']) {
                                            return option.question;
                                        } else if (typeof option === 'string') {
                                            return option;
                                        } else {
                                            return '';
                                        }
                                    }}
                                    // needed to make autoHighlight work.
                                    open={open}
                                    filterOptions={(x) => x}
                                    renderInput={(params) =>
                                        (
                                            <TextField
                                                {...params}
                                                datacy='ask_me_input'
                                                placeholder={config.i18n.ask_me.question_tip}
                                                autoFocus
                                                InputProps={{
                                                    ...params.InputProps,
                                                    startAdornment: (
                                                        <InputAdornment sx={{ml: 1}}>
                                                            <Search />
                                                        </InputAdornment>),
                                                    endAdornment: (
                                                        <>
                                                            {loading ? <CircularLoading size={25} /> : null}
                                                            {params.InputProps.endAdornment}
                                                        </>
                                                    ),
                                                }}
                                            />
                                        )
                                    }
                                    onInputChange={(ev, value) => {
                                        // only issue a query if the user changes manually the input.
                                        if (ev?.type === 'change') {
                                            setQueryDebounced(value);
                                        }
                                    }}
                                    onChange={(ev, value) => {
                                        // ignore setting an analysis when the value is empty
                                        // or it isn't one of the avaliable options.
                                        if (!hasValue(value) || typeof value === 'string') {
                                            return;
                                        }

                                        setOpen(false);
                                        fetchAndSetAnalysis(value.id);
                                    }}
                                    sx={{flex: 1}}
                                />
                            </ClickAwayListener>
                        </Grid>
                        {
                            (slideContext || queryLoading) ? (
                                <Grid item xs={12}>
                                    <Slide
                                        context={slideContext}
                                        loading={queryLoading}
                                        onAnalysisLinkClick={(analysisId) => {
                                            setOpen(false);
                                            fetchAndSetAnalysis(analysisId);
                                        }}
                                        disableDrillDown={!analysis?.relatedAnalyses?.down &&
                                            isEmpty(analysis?.relatedAnalyses?.across)}
                                        disableDrillAcross={!analysis?.relatedAnalyses?.down &&
                                            isEmpty(analysis?.relatedAnalyses?.across)}
                                        onDrillDown={onDrillDown}
                                        onChartContextMenu={onChartContextMenu}
                                    />
                                </Grid>
                            ) : null
                        }
                        {
                            isTryThis() &&
                            <Grid item marginTop={3}>
                                <TryThis />
                            </Grid>
                        }
                    </Grid>
                </Box>

                {
                    analysis?.relatedAnalyses ?
                        <Menu
                            open={Boolean(contextMenuDimId) && Boolean(contextMenuAnchor)}
                            onClose={closeContextualMenu}
                            anchorReference='anchorPosition'
                            anchorPosition={contextMenuAnchor}
                        >
                            {
                                analysis?.relatedAnalyses?.down ?
                                    <MenuItem onClick={() => {
                                        drill(analysis.relatedAnalyses.down, contextMenuDimId);
                                        closeContextualMenu();
                                    }}
                                    >
                                        <ListItemIcon>
                                            <TrendingDownIcon fontSize='small' />
                                        </ListItemIcon>
                                        <ListItemText>{config.i18n.dimension[analysis.relatedAnalyses.down.label]}</ListItemText>
                                    </MenuItem> : null
                            }

                            {
                                analysis?.relatedAnalyses?.across.map((targetAnalysis) => {
                                    return (
                                        <MenuItem
                                            key={targetAnalysis.analysis_id}
                                            onClick={() => {
                                                drill(targetAnalysis, contextMenuDimId);
                                                closeContextualMenu();
                                            }}
                                        >
                                            <ListItemIcon>
                                                <TrendingFlatIcon fontSize='small' />
                                            </ListItemIcon>
                                            <ListItemText>{config.i18n.dimension[targetAnalysis.label]}</ListItemText>
                                        </MenuItem>
                                    );
                                })
                            }
                        </Menu> : null
                }

            </AdvisorContainer>
            {
                analysis && analysisChart && analysisParameters && analysisDimensions && analysisDimParameters &&
                analysisCustomParameters && analysisMetadata ?
                    <Drawer
                        variant='permanent'
                        hideBackdrop
                        anchor='right'
                        sx={{
                            'width': drawerWidth,
                            'flexShrink': 0,
                            '& .MuiDrawer-paper': {
                                width: drawerWidth,
                                boxSizing: 'border-box',
                            },
                        }}
                        open
                        transitionDuration={300}
                    >
                        <Toolbar />
                        <Box sx={{overflow: 'auto', px: 2, pt: 4, pb: 2}}>
                            <CustomizationBar
                                disabled={queryLoading || isFetchingAnalysis}
                                customizationParameters={analysis.customizationParameters}
                                analysisChart={analysisChart}
                                analysisParameters={analysisParameters}
                                analysisDimensions={analysisDimensions}
                                analysisDimParameters={analysisDimParameters}
                                analysisCustomParameters={analysisCustomParameters}
                                analysisMetadata={analysisMetadata}

                                onChartUpdate={(chart) => {
                                    setAnalysisStore({
                                        ...analysisStore,
                                        chart: chart,
                                    });

                                    setSlideContext({...slideContext, chart: chart});
                                }}

                                onParameterUpdate={(type, val) =>
                                    setAnalysisStore({
                                        ...analysisStore,
                                        parameters: {...analysisParameters, [type]: val},
                                    })
                                }

                                onDimensionUpdate={(dimensions, dimParameters, metadata = null) => {
                                    const newAnalysisStore = {
                                        ...analysisStore,
                                        dimensions: dimensions,
                                    };

                                    if (!isEmpty(dimParameters)) {
                                        newAnalysisStore.dimParameters = {
                                            ...analysisDimParameters,
                                            ...dimParameters,
                                        };
                                    }


                                    if (!isEmpty(metadata)) {
                                        setAnalysisMetadata({
                                            ...analysisMetadata,
                                            ...metadata,
                                        });
                                    }

                                    setAnalysisStore(newAnalysisStore);
                                }}

                                onCustomParameterUpdate={(newCustomParams) =>
                                    setAnalysisStore({
                                        ...analysisStore,
                                        customParameters: {...analysisCustomParameters, ...newCustomParams},
                                    })
                                }
                            />
                        </Box>
                    </Drawer> : null
            }
        </>
    );
};

export default Ask;
