import {Dialog, DialogContent} from '@mui/material';
import React from 'react';
import CircularLoading from './CircularLoading';
import PropTypes from 'prop-types';

const FullscreenLoading = ({loading, label}) => {
    return (
        <Dialog
            open={loading}
            maxWidth='sm'
            disableEscapeKeyDown
        >
            <DialogContent dividers>
                <CircularLoading label={label} />
            </DialogContent>
        </Dialog>
    );
};

FullscreenLoading.propTypes = {
    loading: PropTypes.bool,
    label: PropTypes.string,
};


export default FullscreenLoading;
